import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const EnterpriseMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  navCustomerNo: {
    bcKey: 'NAV_Customer_No',
    type: 'string',
    required: false,
  },
  shipToCode: {
    bcKey: 'Ship_to_Code',
    type: 'string',
    required: false,
  },
  shipToName: {
    bcKey: 'Ship_to_Name',
    type: 'string',
    required: false,
  },
  shipToPostCode: {
    bcKey: 'Ship_to_Post_Code',
    type: 'string',
    required: false,
  },
  customerName: {
    bcKey: 'CustomerName',
    type: 'string',
    required: false,
  },
  defaultContractType: {
    bcKey: 'Default_Contract_Type',
    type: 'string',
    required: false,
  },
  salesDocWoLineDescrSource: {
    bcKey: 'Sales_Doc_WO_Line_Descr_Source',
    type: 'string',
    required: false,
  },
  salesDocRrDescrSource: {
    bcKey: 'Sales_Doc_RR_Descr_Source',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Enterprise = ODataObjectMap<typeof EnterpriseMap>;

const EnterpriseSchema = generateGraphQlSchema('Enterprise', EnterpriseMap);

const EnterpriseQuery = generateGraphQlQuery('Enterprise', EnterpriseMap);

export { EnterpriseSchema, EnterpriseQuery, type Enterprise, EnterpriseMap };
