import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PersonnelGroupMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type PersonnelGroup = ODataObjectMap<typeof PersonnelGroupMap>;

const PersonnelGroupSchema = generateGraphQlSchema(
  'personnelGroup',
  PersonnelGroupMap
);

const PersonnelGroupQuery = generateGraphQlQuery(
  'personnelGroup',
  PersonnelGroupMap
);

export {
  PersonnelGroupSchema,
  PersonnelGroupQuery,
  type PersonnelGroup,
  PersonnelGroupMap,
};
