//ET-DONE date totalcount
type Break = {
  absenceCode?: string;
  absenceNo: string;
  absenceType: string;
  auxiliaryIndex1?: string;
  auxiliaryIndex2?: Date;
  auxiliaryIndex3?: number;
  auxiliaryIndex4?: number;
  date: Date;
  employeeGroupCode: string;
  employeeNo: string;
  hours: number;
  resourceNo: string;
  scheduledEndDateTime: Date;
  scheduledStartDateTime: Date;
  status: string;
};

const BreakSchema = `#graphql
  type Break {
    absenceCode: String
    absenceNo: String
    absenceType: String
    auxiliaryIndex1: String
    auxiliaryIndex2: Date
    auxiliaryIndex3: Float
    auxiliaryIndex4: Float
    date: Date
    employeeGroupCode: String
    employeeNo: String
    hours: Float
    resourceNo: String
    scheduledEndDateTime: Date
    scheduledStartDateTime: Date
    status: String
  }
`;

const BreakQuery = `#graphql
  query allBreaks($filter: String) {
    breaks(filter: $filter) {
      items {
        absenceCode
        absenceNo
        absenceType
        auxiliaryIndex1
        auxiliaryIndex2
        auxiliaryIndex3
        auxiliaryIndex4
        date
        employeeGroupCode
        employeeNo
        hours
        resourceNo
        scheduledEndDateTime
        scheduledStartDateTime
        status
      }
      totalCount
    }
  }
`;

export { BreakSchema, BreakQuery, type Break };
