import { generateGraphQlQuery } from '../utils';
import { WorkOrder, WorkOrderMap, WorkOrderSchema } from './work-order';

const PlannedWorkOrderMap = WorkOrderMap;

type PlannedWorkOrder = WorkOrder;

const PlannedWorkOrderSchema = WorkOrderSchema;

const PlannedWorkOrderQuery = generateGraphQlQuery(
  'plannedWorkOrder',
  PlannedWorkOrderMap
);

export {
  PlannedWorkOrderSchema,
  type PlannedWorkOrder,
  PlannedWorkOrderQuery,
  PlannedWorkOrderMap,
};
