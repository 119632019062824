import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const SkillMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Skill = ODataObjectMap<typeof SkillMap>;

const SkillSchema = generateGraphQlSchema('Skill', SkillMap);

const SkillQuery = generateGraphQlQuery('Skill', SkillMap);

export { SkillSchema, SkillQuery, type Skill, SkillMap };
