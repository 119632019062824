import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const OrderTypeMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  requireRwoSignature: {
    bcKey: 'Require_RWO_Signature',
    type: 'boolean',
    required: false,
  },
  maxNotificationCount: {
    bcKey: 'Max_Notification_Count',
    type: 'number',
    required: false,
  },
  allowNoEquipmentWoHeader: {
    bcKey: 'Allow_No_Equipment_WO_Header',
    type: 'boolean',
    required: false,
  },
  allowNoEquipmentWoLine: {
    bcKey: 'Allow_No_Equipment_WO_Line',
    type: 'boolean',
    required: false,
  },
} as const satisfies ODataMap;

type OrderType = ODataObjectMap<typeof OrderTypeMap>;

const OrderTypeSchema = generateGraphQlSchema('orderType', OrderTypeMap);
const OrderTypeQuery = generateGraphQlQuery('orderType', OrderTypeMap);

export { OrderTypeSchema, type OrderType, OrderTypeQuery, OrderTypeMap };
