//ET-DONE // don't exist endpoint for this in query.cs
type Dictionary = {
  [key: string]: number;
};

type ItemAvailability = {
  itemNo: string;
  inventory: Dictionary;
};

const ItemAvailabilitySchema = `#graphql
  type ItemAvailability {
    itemNo: String
    inventory: Dictionary
  }
`;

const ItemAvailabilityQuery = `#graphql
query allItemAvailabilities(
  $no: String
) {
  itemAvailability(
    no: $no
  ) {
    items {
      itemNo
      inventory 
    }
  }
}
`;

export {
  ItemAvailabilitySchema,
  type ItemAvailability,
  type Dictionary,
  ItemAvailabilityQuery,
};
