import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ProblemCodeMap = {
  problemCode: {
    bcKey: 'Problem_Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  requiredSkillCode: {
    bcKey: 'Required_Skill_Code',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  overridePersonnelGroup: {
    bcKey: 'Override_Personnel_Group',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ProblemCode = ODataObjectMap<typeof ProblemCodeMap>;

const ProblemCodeSchema = generateGraphQlSchema('ProblemCode', ProblemCodeMap);

const ProblemCodeQuery = generateGraphQlQuery('problemCode', ProblemCodeMap);

export {
  ProblemCodeSchema,
  ProblemCodeQuery,
  type ProblemCode,
  ProblemCodeMap,
};
