import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PersonnelMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  personnelType: {
    bcKey: 'Personnel_Type',
    type: 'string',
    required: true,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  searchName: {
    bcKey: 'Search_Name',
    type: 'string',
    required: false,
  },
  name2: {
    bcKey: 'Name_2',
    type: 'string',
    required: false,
  },
  address: {
    bcKey: 'Address',
    type: 'string',
    required: false,
  },
  address2: {
    bcKey: 'Address_2',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  referenceNo: {
    bcKey: 'Reference_No',
    type: 'string',
    required: false,
  },
  title: {
    bcKey: 'Title',
    type: 'string',
    required: false,
  },
  education: {
    bcKey: 'Education',
    type: 'string',
    required: false,
  },
  miscellaneous: {
    bcKey: 'Miscellaneous',
    type: 'string',
    required: false,
  },
  employmentDate: {
    bcKey: 'Employment_Date',
    type: 'string',
    required: false,
  },
  jobTitle: {
    bcKey: 'Job_Title',
    type: 'string',
    required: false,
  },
  phoneNo: {
    bcKey: 'Phone_No',
    type: 'string',
    required: false,
  },
  eMail: {
    bcKey: 'E_Mail',
    type: 'string',
    required: false,
  },
  unitOfMeasureCode: {
    bcKey: 'Unit_of_Measure_Code',
    type: 'string',
    required: false,
  },
  indirectCostPercent: {
    bcKey: 'Indirect_Cost_Percent',
    type: 'number',
    required: false,
  },
  directUnitCost: {
    bcKey: 'Direct_Unit_Cost',
    type: 'number',
    required: false,
  },
  unitCost: {
    bcKey: 'Unit_Cost',
    type: 'number',
    required: false,
  },
  profitPercent: {
    bcKey: 'Profit_Percent',
    type: 'number',
    required: false,
  },
  priceProfitCalculation: {
    bcKey: 'Price_Profit_Calculation',
    type: 'string',
    required: false,
  },
  unitPrice: {
    bcKey: 'Unit_Price',
    type: 'number',
    required: false,
  },
  vendorNo: {
    bcKey: 'Vendor_No',
    type: 'string',
    required: false,
  },
  lastDateModified: {
    bcKey: 'Last_Date_Modified',
    type: 'string',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
  blocked: {
    bcKey: 'Blocked',
    type: 'boolean',
    required: false,
  },
  capacity: {
    bcKey: 'Capacity',
    type: 'number',
    required: false,
  },
  postCode: {
    bcKey: 'Post_Code',
    type: 'string',
    required: false,
  },
  county: {
    bcKey: 'County',
    type: 'string',
    required: false,
  },
  automaticExtTexts: {
    bcKey: 'Automatic_Ext_Texts',
    type: 'boolean',
    required: false,
  },
  countryCode: {
    bcKey: 'Country_Code',
    type: 'string',
    required: false,
  },
  ordersExist: {
    bcKey: 'Orders_Exist',
    type: 'boolean',
    required: false,
  },
  orderLinesExist: {
    bcKey: 'Order_Lines_Exist',
    type: 'boolean',
    required: false,
  },
  releasedWorkOrders: {
    bcKey: 'Released_Work_Orders',
    type: 'number',
    required: false,
  },
  plannedWorkOrders: {
    bcKey: 'Planned_Work_Orders',
    type: 'number',
    required: false,
  },
  finishedWorkOrders: {
    bcKey: 'Finished_Work_Orders',
    type: 'number',
    required: false,
  },
  assignedDateMeterLines: {
    bcKey: 'Assigned_Date_Meter_Lines',
    type: 'number',
    required: false,
  },
  assignedDateMeters: {
    bcKey: 'Assigned_Date_Meters',
    type: 'number',
    required: false,
  },
  finishedWorkOrderLines: {
    bcKey: 'Finished_Work_Order_Lines',
    type: 'number',
    required: false,
  },
  plannedWorkOrderLines: {
    bcKey: 'Planned_Work_Order_Lines',
    type: 'number',
    required: false,
  },
  releasedWorkOrderLines: {
    bcKey: 'Released_Work_OrderLines',
    type: 'number',
    required: false,
  },
  poEstimatedTime: {
    bcKey: 'PO_Estimated_Time',
    type: 'number',
    required: false,
  },
  noOrders: {
    bcKey: 'No_Orders',
    type: 'number',
    required: false,
  },
  roEstimatedTime: {
    bcKey: 'RO_Estimated_Time',
    type: 'number',
    required: false,
  },
  supervisorNoOrders: {
    bcKey: 'Supervisor_No_Orders',
    type: 'number',
    required: false,
  },
  postingCode: {
    bcKey: 'Posting_Code',
    type: 'string',
    required: false,
  },
  certifications: {
    bcKey: 'Certifications',
    type: 'number',
    required: false,
  },
  skills: {
    bcKey: 'Skills',
    type: 'number',
    required: false,
  },
  resourceNo: {
    bcKey: 'Resource_No',
    type: 'string',
    required: false,
  },
  annualCost: {
    bcKey: 'Annual_Cost',
    type: 'number',
    required: false,
  },
  burdenMultiplier: {
    bcKey: 'Burden_Multiplier',
    type: 'number',
    required: false,
  },
  burdenedAnnualCost: {
    bcKey: 'Burdened_Annual_Cost',
    type: 'number',
    required: false,
  },
  hoursAvailableAnnually: {
    bcKey: 'Hours_Available_Annually',
    type: 'number',
    required: false,
  },
  perHourAnnualCost: {
    bcKey: 'Per_Hour_Annual_Cost',
    type: 'number',
    required: false,
  },
  perHourCostOverride: {
    bcKey: 'Per_Hour_Cost_Override',
    type: 'number',
    required: false,
  },
  firstFinishedWorkDate: {
    bcKey: 'First_Finished_Work_Date',
    type: 'date',
    required: false,
  },
  lastFinishedWorkDate: {
    bcKey: 'Last_Finished_Work_Date',
    type: 'date',
    required: false,
  },
  noFinishedWork: {
    bcKey: 'No_Finished_Work',
    type: 'number',
    required: false,
  },
  finishedOrderCosts: {
    bcKey: 'Finished_Order_Costs',
    type: 'number',
    required: false,
  },
  plannedOrderCosts: {
    bcKey: 'Planned_Order_Costs',
    type: 'number',
    required: false,
  },
  releasedOrderCosts: {
    bcKey: 'Released_Order_Costs',
    type: 'number',
    required: false,
  },
  noOrderLines: {
    bcKey: 'No_Order_Lines',
    type: 'number',
    required: false,
  },
  dailyAvailableHours: {
    bcKey: 'Daily_Available_Hours',
    type: 'number',
    required: false,
  },
  poLineEstTime: {
    bcKey: 'PO_Line_Est_Time',
    type: 'number',
    required: false,
  },
  roLineEstTime: {
    bcKey: 'RO_Line_Est_Time',
    type: 'number',
    required: false,
  },
  qtyOnWorkOrder: {
    bcKey: 'Qty_on_Work_Order',
    type: 'number',
    required: false,
  },
  technicianCapacity: {
    bcKey: 'Technician_Capacity',
    type: 'number',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  requestResponseAllFacility: {
    bcKey: 'Request_Response_all_Facility',
    type: 'boolean',
    required: false,
  },
  reminderThresholdHrs: {
    bcKey: 'Reminder_Threshold_hrs',
    type: 'number',
    required: false,
  },
  thresholdAssignRequestHrs: {
    bcKey: 'Threshold_Assign_Request_hrs',
    type: 'number',
    required: false,
  },
  assignRequestStart: {
    bcKey: 'Assign_Request_Start',
    type: 'date',
    required: false,
  },
  escalation1Personnel: {
    bcKey: 'Escalation_1_Personnel',
    type: 'string',
    required: false,
  },
  escalation1ThresholdHrs: {
    bcKey: 'Escalation_1_Threshold_hrs',
    type: 'number',
    required: false,
  },
  shiftStatusLastModified: {
    bcKey: 'Shift_Status_Last_Modified',
    type: 'date',
    required: false,
  },
  shiftStatusLastModifiedBy: {
    bcKey: 'Shift_Status_Last_Modified_By',
    type: 'string',
    required: false,
  },
  billingType: {
    bcKey: 'Billing_Type',
    type: 'string',
    required: false,
  },
  billingNo: {
    bcKey: 'Billing_No',
    type: 'string',
    required: false,
  },
  overrideUnitPrice: {
    bcKey: 'Override_Unit_Price',
    type: 'boolean',
    required: false,
  },
  useEstimatedQtyForSales: {
    bcKey: 'Use_Estimated_Qty_for_Sales',
    type: 'boolean',
    required: false,
  },
  escalation1Message: {
    bcKey: 'Escalation_1_Message',
    type: 'string',
    required: false,
  },
  unionNo: {
    bcKey: 'Union_No',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  supervisorCode: {
    bcKey: 'Supervisor_Code',
    type: 'string',
    required: false,
  },
  sortSequence: {
    bcKey: 'Sort_Sequence',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  donX0027TShowOnWhiteboard: {
    bcKey: 'Don_x0027_t_Show_on_Whiteboard',
    type: 'boolean',
    required: false,
  },
  company: {
    bcKey: 'Company',
    type: 'string',
    required: false,
  },
  shiftStatus: {
    bcKey: 'Shift_Status',
    type: 'string',
    required: false,
  },
  totalAssignedRequest: {
    bcKey: 'Total_Assigned_Request',
    type: 'number',
    required: false,
  },
  noOfAssignInProgressReq: {
    bcKey: 'No_of_Assign_In_Progress_Req',
    type: 'number',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  noSeries: {
    bcKey: 'No_Series',
    type: 'string',
    required: false,
  },
  defaultLocationCode: {
    bcKey: 'Default_Location_Code',
    type: 'string',
    required: false,
  },
  defaultBinCode: {
    bcKey: 'Default_Bin_Code',
    type: 'string',
    required: false,
  },
  defaultPayToVendor: {
    bcKey: 'Default_Pay_to_Vendor',
    type: 'string',
    required: false,
  },
  employeeNo: {
    bcKey: 'Employee_No',
    type: 'string',
    required: false,
  },
  documentTypeFilter: {
    bcKey: 'Document_Type_Filter',
    type: 'string',
    required: false,
  },
  regionFilter: {
    bcKey: 'Region_Filter',
    type: 'string',
    required: false,
  },
  facilityFilter: {
    bcKey: 'Facility_Filter',
    type: 'string',
    required: false,
  },
  dateFilter: {
    bcKey: 'Date_Filter',
    type: 'string',
    required: false,
  },
  stepStatusFilter: {
    bcKey: 'Step_Status_Filter',
    type: 'string',
    required: false,
  },
  datetimeFilter: {
    bcKey: 'Datetime_Filter',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Personnel = ODataObjectMap<typeof PersonnelMap>;

const PersonnelSchema = generateGraphQlSchema('personnel', PersonnelMap);

const PersonnelQuery = generateGraphQlQuery('personnel', PersonnelMap);

export { PersonnelSchema, type Personnel, PersonnelQuery, PersonnelMap };
