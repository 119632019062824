import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const WorkOrderTemplateMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  maintType: {
    bcKey: 'Maint_Type',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  reasonCode: {
    bcKey: 'Reason_Code',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type WorkOrderTemplate = ODataObjectMap<typeof WorkOrderTemplateMap>;

const WorkOrderTemplateSchema = generateGraphQlSchema(
  'WorkOrderTemplate',
  WorkOrderTemplateMap
);

const WorkOrderTemplateQuery = generateGraphQlQuery(
  'WorkOrderTemplate',
  WorkOrderTemplateMap
);

export {
  WorkOrderTemplateSchema,
  WorkOrderTemplateQuery,
  type WorkOrderTemplate,
  WorkOrderTemplateMap,
};
