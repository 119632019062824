import { Injectable } from '@angular/core';
import { GraphQLParams, config } from '@tag/graphql';
import { TAGSetup } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  tagSetups: { items: TAGSetup[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class TagSetupGQL extends Query<Response, GraphQLParams<'tagSetup'>> {
  document = gql(config.tagSetup.query);
}
