import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const JobPlanningLineMap = {
  jobNo: {
    bcKey: 'JobNo',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'JobTaskNo',
    type: 'string',
    required: false,
  },
  lineNo: {
    bcKey: 'LineNo',
    type: 'number',
    required: false,
  },
  lineType: {
    bcKey: 'LineType',
    type: 'string',
    required: false,
  },
  usageLink: {
    bcKey: 'UsageLink',
    type: 'boolean',
    required: false,
  },
  planningDate: {
    bcKey: 'PlanningDate',
    type: 'string',
    required: false,
  },
  plannedDeliveryDate: {
    bcKey: 'PlannedDeliveryDate',
    type: 'string',
    required: false,
  },
  currencyDate: {
    bcKey: 'CurrencyDate',
    type: 'string',
    required: false,
  },
  documentNo: {
    bcKey: 'DocumentNo',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  genBusPostingGroup: {
    bcKey: 'GenBusPostingGroup',
    type: 'string',
    required: false,
  },
  genProdPostingGroup: {
    bcKey: 'GenProdPostingGroup',
    type: 'string',
    required: false,
  },
  variantCode: {
    bcKey: 'VariantCode',
    type: 'string',
    required: false,
  },
  locationCode: {
    bcKey: 'LocationCode',
    type: 'string',
    required: false,
  },
  workTypeCode: {
    bcKey: 'WorkTypeCode',
    type: 'string',
    required: false,
  },
  unitOfMeasureCode: {
    bcKey: 'UnitOfMeasureCode',
    type: 'string',
    required: false,
  },
  reserve: {
    bcKey: 'Reserve',
    type: 'string',
    required: false,
  },
  quantity: {
    bcKey: 'Quantity',
    type: 'number',
    required: false,
  },
  reservedQuantity: {
    bcKey: 'ReservedQuantity',
    type: 'number',
    required: false,
  },
  quantityBase: {
    bcKey: 'QuantityBase',
    type: 'number',
    required: false,
  },
  remainingQty: {
    bcKey: 'RemainingQty',
    type: 'number',
    required: false,
  },
  directUnitCostLcy: {
    bcKey: 'DirectUnitCostLCY',
    type: 'number',
    required: false,
  },
  unitCost: {
    bcKey: 'UnitCost',
    type: 'number',
    required: false,
  },
  unitCostLcy: {
    bcKey: 'UnitCostLCY',
    type: 'number',
    required: false,
  },
  totalCost: {
    bcKey: 'TotalCost',
    type: 'number',
    required: false,
  },
  remainingTotalCost: {
    bcKey: 'RemainingTotalCost',
    type: 'number',
    required: false,
  },
  totalCostLcy: {
    bcKey: 'TotalCostLCY',
    type: 'number',
    required: false,
  },
  remainingTotalCostLcy: {
    bcKey: 'RemainingTotalCostLCY',
    type: 'number',
    required: false,
  },
  unitPrice: {
    bcKey: 'UnitPrice',
    type: 'number',
    required: false,
  },
  unitPriceLcy: {
    bcKey: 'UnitPriceLCY',
    type: 'number',
    required: false,
  },
  lineAmount: {
    bcKey: 'LineAmount',
    type: 'number',
    required: false,
  },
  remainingLineAmount: {
    bcKey: 'RemainingLineAmount',
    type: 'number',
    required: false,
  },
  lineAmountLcy: {
    bcKey: 'LineAmountLCY',
    type: 'number',
    required: false,
  },
  remainingLineAmountLcy: {
    bcKey: 'RemainingLineAmountLCY',
    type: 'number',
    required: false,
  },
  lineDiscountAmount: {
    bcKey: 'LineDiscountAmount',
    type: 'number',
    required: false,
  },
  lineDiscount: {
    bcKey: 'LineDiscount',
    type: 'number',
    required: false,
  },
  totalPrice: {
    bcKey: 'TotalPrice',
    type: 'number',
    required: false,
  },
  totalPriceLcy: {
    bcKey: 'TotalPriceLCY',
    type: 'number',
    required: false,
  },
  qtyPosted: {
    bcKey: 'QtyPosted',
    type: 'number',
    required: false,
  },
  qtyToTransferToJournal: {
    bcKey: 'QtyToTransferToJournal',
    type: 'number',
    required: false,
  },
  postedTotalCost: {
    bcKey: 'PostedTotalCost',
    type: 'number',
    required: false,
  },
  postedTotalCostLcy: {
    bcKey: 'PostedTotalCostLCY',
    type: 'number',
    required: false,
  },
  postedLineAmount: {
    bcKey: 'PostedLineAmount',
    type: 'number',
    required: false,
  },
  postedLineAmountLcy: {
    bcKey: 'PostedLineAmountLCY',
    type: 'number',
    required: false,
  },
  qtyTransferredToInvoice: {
    bcKey: 'QtyTransferredToInvoice',
    type: 'number',
    required: false,
  },
  qtyToTransferToInvoice: {
    bcKey: 'QtyToTransferToInvoice',
    type: 'number',
    required: false,
  },
  qtyInvoiced: {
    bcKey: 'QtyInvoiced',
    type: 'number',
    required: false,
  },
  qtyToInvoice: {
    bcKey: 'QtyToInvoice',
    type: 'number',
    required: false,
  },
  invoicedAmountLcy: {
    bcKey: 'InvoicedAmountLCY',
    type: 'number',
    required: false,
  },
  invoicedCostAmountLcy: {
    bcKey: 'InvoicedCostAmountLCY',
    type: 'number',
    required: false,
  },
  userId: {
    bcKey: 'UserID',
    type: 'string',
    required: false,
  },
  serialNo: {
    bcKey: 'SerialNo',
    type: 'string',
    required: false,
  },
  lotNo: {
    bcKey: 'LotNo',
    type: 'string',
    required: false,
  },
  jobContractEntryNo: {
    bcKey: 'JobContractEntryNo',
    type: 'number',
    required: false,
  },
  ledgerEntryType: {
    bcKey: 'LedgerEntryType',
    type: 'string',
    required: false,
  },
  ledgerEntryNo: {
    bcKey: 'LedgerEntryNo',
    type: 'number',
    required: false,
  },
  systemCreatedEntry: {
    bcKey: 'SystemCreatedEntry',
    type: 'boolean',
    required: false,
  },
  overdue: {
    bcKey: 'Overdue',
    type: 'boolean',
    required: false,
  },
  percentDone: {
    bcKey: 'PercentDone',
    type: 'number',
    required: false,
  },
  duration: {
    bcKey: 'Duration',
    type: 'number',
    required: false,
  },
  rollup: {
    bcKey: 'Rollup',
    type: 'boolean',
    required: false,
  },
  color: {
    bcKey: 'Color',
    type: 'string',
    required: false,
  },
  showInTimeline: {
    bcKey: 'ShowInTimeline',
    type: 'boolean',
    required: false,
  },
  parentPlanningLineNo: {
    bcKey: 'ParentPlanningLineNo',
    type: 'number',
    required: false,
  },
  manuallyScheduled: {
    bcKey: 'ManuallyScheduled',
    type: 'boolean',
    required: false,
  },
  constraintType: {
    bcKey: 'ConstraintType',
    type: 'string',
    required: false,
  },
  contraintDate: {
    bcKey: 'ContraintDate',
    type: 'string',
    required: false,
  },
  tagRelatedRequirementType: {
    bcKey: 'TAG_Related_Requirement_Type',
    type: 'string',
    required: false,
  },
  tagRelatedRequirementNo: {
    bcKey: 'TAG_Related_Requirement_No',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type JobPlanningLine = ODataObjectMap<typeof JobPlanningLineMap>;

const JobPlanningLineSchema = generateGraphQlSchema(
  'JobPlanningLine',
  JobPlanningLineMap
);

const JobPlanningLineQuery = generateGraphQlQuery(
  'JobPlanningLine',
  JobPlanningLineMap
);

export {
  JobPlanningLineSchema,
  type JobPlanningLine,
  JobPlanningLineQuery,
  JobPlanningLineMap,
};
