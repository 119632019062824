import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const CommentMap = {
  tableName: {
    required: false,
    bcKey: 'Table_Name',
    type: 'string',
  },
  no: {
    required: false,
    bcKey: 'No',
    type: 'string',
  },
  lineNo: {
    required: false,
    bcKey: 'Line_No',
    type: 'number',
  },
  documentType: {
    required: false,
    bcKey: 'Document_Type',
    type: 'string',
  },
  date: {
    required: false,
    bcKey: 'Date',
    type: 'string',
  },
  description: {
    required: false,
    bcKey: 'Description',
    type: 'string',
  },
  printOnWorkOrder: {
    required: false,
    bcKey: 'Print_on_Work_Order',
    type: 'boolean',
  },
  printBeginningDate: {
    required: false,
    bcKey: 'Print_Beginning_Date',
    type: 'string',
  },
  printEndingDate: {
    required: false,
    bcKey: 'Print_Ending_Date',
    type: 'string',
  },
  createdBy: {
    required: false,
    bcKey: 'Created_By',
    type: 'string',
  },
  createdDateTime: {
    required: false,
    bcKey: 'Created_Date_Time',
    type: 'string',
  },
  modifiedBy: {
    required: false,
    bcKey: 'Modified_By',
    type: 'string',
  },
  modifiedDateTime: {
    required: false,
    bcKey: 'Modified_Date_Time',
    type: 'string',
  },
  printForTechnicianCode: {
    required: false,
    bcKey: 'Print_for_Technician_Code',
    type: 'string',
  },
  code: {
    required: false,
    bcKey: 'Code',
    type: 'string',
  },
  stepNo: {
    required: false,
    bcKey: 'Step_No',
    type: 'string',
  },
  equipId: {
    required: false,
    bcKey: 'Equip_ID',
    type: 'string',
  },
  estimatedTime: {
    required: false,
    bcKey: 'Estimated_Time',
    type: 'number',
  },
  personnelGroup: {
    required: false,
    bcKey: 'Personnel_Group',
    type: 'string',
  },
  technicianCode: {
    required: false,
    bcKey: 'Technician_Code',
    type: 'string',
  },
  webServiceUser: {
    required: false,
    bcKey: 'Web_Service_User',
    type: 'string',
  },
  actualTime: {
    required: false,
    bcKey: 'Actual_Time',
    type: 'number',
  },
  finished: {
    required: false,
    bcKey: 'Finished',
    type: 'boolean',
  },
  convertToWoLine: {
    required: false,
    bcKey: 'Convert_to_WO_Line',
    type: 'boolean',
  },
} as const satisfies ODataMap;

type CommentSheet = ODataObjectMap<typeof CommentMap>;

const CommentSheetSchema = generateGraphQlSchema('Comment', CommentMap);

const CommentSheetQuery = generateGraphQlQuery('Comment', CommentMap);

export { CommentSheetSchema, CommentSheetQuery, type CommentSheet, CommentMap };
