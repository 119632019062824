import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const TimesheetMap = {
  sourceWorkOrderNo: {
    bcKey: 'Source_Work_Order_No',
    type: 'string',
    required: true,
  },
  sourceWorkOrderLine: {
    bcKey: 'Source_Work_Order_Line',
    type: 'number',
    required: true,
  },
  timeSheetLineNo: {
    bcKey: 'Time_Sheet_Line_No',
    type: 'number',
    required: true,
  },
  consumptionWoNo: {
    bcKey: 'Consumption_WO_No',
    type: 'string',
    required: false,
  },
  consumptionWoLine: {
    bcKey: 'Consumption_WO_Line',
    type: 'number',
    required: false,
  },
  workProcedureStep: {
    bcKey: 'Work_Procedure_Step',
    type: 'string',
    required: false,
  },
  include: {
    bcKey: 'Include',
    type: 'boolean',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  estimatedTime: {
    bcKey: 'Estimated_Time',
    type: 'number',
    required: false,
  },
  actualTime: {
    bcKey: 'Actual_Time',
    type: 'number',
    required: false,
  },
  startingDatetime: {
    bcKey: 'Starting_Datetime',
    type: 'date',
    required: false,
  },
  endingDatetime: {
    bcKey: 'Ending_Datetime',
    type: 'date',
    required: false,
  },
  recordedTimeQty: {
    bcKey: 'Recorded_Time_Qty',
    type: 'number',
    required: false,
  },
  postedTimeQty: {
    bcKey: 'Posted_Time_Qty',
    type: 'number',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
  woLineTaskDone: {
    bcKey: 'WO_Line_Task_Done',
    type: 'boolean',
    required: false,
  },
  reviewComment: {
    bcKey: 'Review_Comment',
    type: 'string',
    required: false,
  },
  pendingApproval: {
    bcKey: 'Pending_Approval',
    type: 'boolean',
    required: false,
  },
  approvalStatus: {
    bcKey: 'Approval_Status',
    type: 'string',
    required: true,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'Job_Task_No',
    type: 'string',
    required: false,
  },
  jobDescription: {
    bcKey: 'Job_Description',
    type: 'string',
    required: false,
  },
  jobTaskDescription: {
    bcKey: 'Job_Task_Description',
    type: 'string',
    required: false,
  },
  technicianName: {
    bcKey: 'Technician_Name',
    type: 'string',
    required: false,
  },
  rateType: {
    bcKey: 'Rate_Type',
    type: 'string',
    required: false,
  },
  unionNo: {
    bcKey: 'Union_No',
    type: 'string',
    required: false,
  },
  timeCode: {
    bcKey: 'Time_Code',
    type: 'string',
    required: false,
  },
  timeType: {
    bcKey: 'Time_Type',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  lastModifiedBy: {
    bcKey: 'Last_Modified_By',
    type: 'string',
    required: false,
  },
  workTypeCode: {
    bcKey: 'Work_Type_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Timesheet = ODataObjectMap<typeof TimesheetMap>;

const TimesheetSchema = generateGraphQlSchema('Timesheet', TimesheetMap);

const TimesheetQuery = generateGraphQlQuery('Timesheet', TimesheetMap);

export { TimesheetSchema, type Timesheet, TimesheetQuery, TimesheetMap };
