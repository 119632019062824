import { BCODataObjectMap, ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const AttachmentMap = {
  no: {
    required: true,
    bcKey: 'No',
    type: 'string',
  },
  noLineNo: {
    required: true,
    bcKey: 'No_Line_No',
    type: 'number',
  },
  lineIncrement: {
    required: true,
    bcKey: 'Line_Increment',
    type: 'number',
  },
  sourceType: {
    required: false,
    bcKey: 'Source_Type',
    type: 'string', // enum Asset,Date Meter Policy,Template,Work Procedure,Request,Work Order,Region,Facility,Area,Line,Main,Primary,Secondary,PurchaseOrder,Expense
  },
  isUrl: {
    required: false,
    bcKey: 'Is_URL',
    type: 'boolean',
  },
  fileName: {
    required: false,
    bcKey: 'File_Name',
    type: 'string',
  },
  createdBy: {
    required: false,
    bcKey: 'Created_By',
    type: 'string',
  },
  creationTime: {
    required: false,
    bcKey: 'Creation_Time',
    type: 'string',
  },
  description: {
    required: false,
    bcKey: 'Description',
    type: 'string',
  },
  format: {
    required: false,
    bcKey: 'Format',
    type: 'string',
  },
  cardPicture: {
    required: false,
    bcKey: 'Card_Picture',
    type: 'boolean',
  },
  model: {
    required: false,
    bcKey: 'Model',
    type: 'boolean',
  },
  advancedAttachmentType: {
    required: false,
    bcKey: 'Advanced_Attachment_Type',
    type: 'string',
  },
  webServiceUser: {
    required: false,
    bcKey: 'Web_Service_User',
    type: 'string',
  },
  printOnWorkOrder: {
    required: false,
    bcKey: 'Print_on_Work_Order',
    type: 'boolean',
  },
  blurHash: {
    required: false,
    bcKey: 'Blur_Hash',
    type: 'string',
  },
  filePath: {
    required: false,
    bcKey: '',
    type: 'string',
  },
} as const satisfies ODataMap;

type Attachment = ODataObjectMap<typeof AttachmentMap>;
type ExternalAttachment = BCODataObjectMap<typeof AttachmentMap>;

const AttachmentSchema = generateGraphQlSchema('Attachment', AttachmentMap);

const AttachmentQuery = generateGraphQlQuery('Attachment', AttachmentMap);

export {
  AttachmentSchema,
  AttachmentQuery,
  type Attachment,
  AttachmentMap,
  type ExternalAttachment,
};
