//ET-DONE
type Employee = {
  auxiliaryIndex1: string;
  blocked: boolean;
  employeeGroupCode: string;
  employeeNo: string;
  name: string;
  regionCode: string;
  resourceName: string;
  resourceNo: string;
  timeZone: string;
};

const EmployeeSchema = `#graphql
  type Employee {
    auxiliaryIndex1: String
    blocked: Boolean
    employeeGroupCode: String
    employeeNo: String
    name: String
    regionCode: String
    resourceName: String
    resourceNo: String
    timeZone: String
  }
`;

const EmployeeQuery = `#graphql
query allEmployees($filter: String) {
  employees(filter: $filter) {
    items {
      auxiliaryIndex1
      blocked
      employeeGroupCode
      employeeNo
      name
      regionCode
      resourceName
      resourceNo
      timeZone
    }
    totalCount
  }
}
`;

export { EmployeeSchema, EmployeeQuery, type Employee };
