import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const SecondaryCodeMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type SecondaryCode = ODataObjectMap<typeof SecondaryCodeMap>;

const SecondaryCodeSchema = generateGraphQlSchema(
  'SecondaryCode',
  SecondaryCodeMap
);

const SecondaryCodeQuery = generateGraphQlQuery(
  'SecondaryCode',
  SecondaryCodeMap
);

export {
  SecondaryCodeSchema,
  SecondaryCodeQuery,
  type SecondaryCode,
  SecondaryCodeMap,
};
