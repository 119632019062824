import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const ReasonCodeMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ReasonCode = ODataObjectMap<typeof ReasonCodeMap>;
const ReasonCodeSchema = generateGraphQlSchema('ReasonCode', ReasonCodeMap);

const ReasonCodeQuery = generateGraphQlQuery('ReasonCode', ReasonCodeMap);

export { ReasonCodeSchema, ReasonCodeQuery, type ReasonCode, ReasonCodeMap };
