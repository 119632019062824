import gql from 'graphql-tag';
import * as Schemas from './schemas';
import type * as Types from './schemas';

export function getQueryName(key: keyof typeof GQL_CONFIG) {
  return (
    GQL_CONFIG[key].query
      .split('{')[1]
      ?.split('(')[0]
      // @ts-ignore
      ?.replaceAll(' ', '')
      ?.replaceAll('\n', '') as string
  );
}

export type GqlConfig = {
  [key: string]: {
    schema: string;
    query: string;
    type: any;
    map: any | null;
    odata: string;
    fields?: string[];
    security?:
      | {
          filters: {
            tokenKey: string;
            tokenOverride: string;
            type: 'array' | 'string';
            field: string;
          }[];
        }
      | any;
  };
};

const GQL_CONFIG = {
  /* Time and Attendance */
  break: {
    schema: Schemas.BreakSchema,
    query: Schemas.BreakQuery,
    type: {} as Types.Break,
    map: null,
    odata: 'TAEmployeeAbsenceSched',
  },
  crewSchedule: {
    schema: Schemas.CrewScheduleSchema,
    query: Schemas.CrewScheduleQuery,
    type: {} as Types.CrewSchedule,
    map: null,
    odata: 'TAGCrewSchedule',
  },
  crew: {
    schema: Schemas.CrewSchema,
    query: Schemas.CrewQuery,
    type: {} as Types.Crew,
    map: null,
    odata: 'TAGCrew',
  },
  employeeScheduleBreak: {
    schema: Schemas.EmployeeScheduleBreakSchema,
    query: Schemas.EmployeeScheduleBreakQuery,
    type: {} as Types.EmployeeScheduleBreak,
    map: null,
    odata: 'TAEmployeeScheduleBreak',
  },
  employeeSchedule: {
    schema: Schemas.EmployeeGroupScheduleSchema,
    query: Schemas.EmployeeGroupScheduleQuery,
    type: {} as Types.EmployeeGroupSchedule,
    map: null,
    odata: 'TAEmployeeSchedule',
  },
  employee: {
    schema: Schemas.EmployeeSchema,
    query: Schemas.EmployeeQuery,
    type: {} as Types.Employee,
    map: null,
    odata: 'TAEmployee',
  },
  holiday: {
    schema: Schemas.HolidaySchema,
    query: Schemas.HolidayQuery,
    type: {} as Types.Holiday,
    map: null,
    odata: 'TAHoliday',
  },
  overtimeCode: {
    schema: Schemas.OvertimeCodeSchema,
    query: Schemas.OvertimeCodeQuery,
    type: {} as Types.OvertimeCode,
    map: null,
    odata: 'TAOverTimeCodes',
  },
  timesheetHeader: {
    schema: Schemas.TimesheetHeaderSchema,
    query: Schemas.TimesheetHeaderQuery,
    type: {} as Types.TimesheetHeader,
    map: null,
    odata: 'TATimesheetHeader',
  },
  timesheetJournal: {
    schema: Schemas.TimesheetJournalSchema,
    query: Schemas.TimesheetJournalQuery,
    type: {} as Types.TimesheetJournal,
    map: null,
    odata: 'TATimesheetHeader',
  },
  timezoneOffset: {
    schema: Schemas.TimezoneOffsetSchema,
    query: Schemas.TimezoneOffsetQuery,
    type: {} as Types.TimezoneOffset,
    map: null,
    odata: 'TATimeZoneOffset',
  },

  /* Default CMMS */
  area: {
    schema: Schemas.AreaSchema,
    query: Schemas.AreaQuery,
    type: {} as Types.Area,
    map: Schemas.AreaMap,
    odata: 'AreaList',
  },
  attachmentDocStor: {
    schema: Schemas.AttachmentDocStashSchema,
    query: Schemas.AttachmentDocStashQuery,
    type: {} as Types.AttachmentDocStash,
    map: null,
    odata: '',
  },
  attachment: {
    schema: Schemas.AttachmentSchema,
    query: Schemas.AttachmentQuery,
    type: {} as Types.Attachment,
    map: Schemas.AttachmentMap,
    odata: 'WebAttachmentsList',
  },
  binContent: {
    schema: Schemas.BinContentSchema,
    query: Schemas.BinContentQuery,
    type: {} as Types.BinContent,
    map: Schemas.BinMap,
    odata: 'TAGBinContents',
  },
  certification: {
    schema: Schemas.CertificationSchema,
    query: Schemas.CertificationQuery,
    type: {} as Types.Certification,
    map: Schemas.CertificationMap,
    odata: 'Certifications',
  },
  commentSheet: {
    schema: Schemas.CommentSheetSchema,
    query: Schemas.CommentSheetQuery,
    type: {} as Types.CommentSheet,
    map: Schemas.CommentMap,
    odata: 'WebTAGCommentSheet',
  },
  company: {
    schema: Schemas.CompanySchema,
    query: Schemas.CompanyQuery,
    type: {} as Types.Company,
    map: Schemas.CompanyMap,
    odata: '',
  },
  consumable: {
    schema: Schemas.ConsumableSchema,
    query: Schemas.ConsumableQuery,
    type: {} as Types.Consumable,
    map: Schemas.ConsumableMap,
    odata: 'WebConsumableList',
  },
  contact: {
    schema: Schemas.ContactSchema,
    query: Schemas.ContactQuery,
    type: {} as Types.Contact,
    map: Schemas.ContactMap,
    odata: 'ContactWS',
  },
  criticality: {
    schema: Schemas.CriticalitySchema,
    query: Schemas.CriticalityQuery,
    type: {} as Types.Criticality,
    map: Schemas.CriticalityMap,
    odata: 'TAGCriticality',
  },
  dateMeterPolicy: {
    schema: Schemas.DateMeterPolicySchema,
    query: Schemas.DateMeterPolicyQuery,
    type: {} as Types.DateMeterPolicy,
    map: Schemas.DateMeterPolicyMap,
    odata: 'TAG_DMP_Header_WS',
  },
  dimension: {
    schema: Schemas.DimensionSchema,
    query: Schemas.DimensionQuery,
    type: {} as Types.Dimension,
    map: Schemas.DimensionMap,
    odata: 'TAGDimensionValueList',
  },
  documentTracking: {
    schema: Schemas.DocumentTrackingSchema,
    query: Schemas.DocumentTrackingQuery,
    type: {} as Types.DocumentTracking,
    map: Schemas.DocumentTrackingMap,
    odata: 'WebDocumentTracking',
  },
  enterprise: {
    schema: Schemas.EnterpriseSchema,
    query: Schemas.EnterpriseQuery,
    type: {} as Types.Enterprise,
    map: Schemas.EnterpriseMap,
    odata: 'EnterpriseList',
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Code',
        },
      ],
    },
  },
  equipmentFailureCode: {
    schema: Schemas.EquipmentFailureCodeSchema,
    query: Schemas.EquipmentFailureCodeQuery,
    type: {} as Types.EquipmentFailureCode,
    map: Schemas.EquipmentFailureCodeSchemaMap,
    odata: 'FailureCodeByEquip',
  },
  equipmentGroup: {
    schema: Schemas.EquipmentGroupSchema,
    query: Schemas.EquipmentGroupQuery,
    type: {} as Types.EquipmentGroup,
    map: Schemas.EquipmentGroupMap,
    odata: 'Equipmentgroup',
  },
  equipmentSubGroup: {
    schema: Schemas.EquipmentSubGroupSchema,
    query: Schemas.EquipmentSubGroupQuery,
    type: {} as Types.EquipmentSubGroup,
    map: Schemas.EquipmentSubGroupMap,
    odata: 'TAGEquipmentSubgroup',
  },
  equipmentTree: {
    schema: Schemas.EquipmentTreeSchema,
    query: Schemas.EquipmentTreeQuery,
    type: {} as Types.Equipment,
    map: Schemas.EquipmentTreeMap,
    odata: 'TAGEquipment',
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Facility',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  equipment: {
    schema: Schemas.EquipmentSchema,
    query: Schemas.EquipmentQuery,
    type: {} as Types.Equipment,
    map: Schemas.EquipmentMap,
    odata: 'TAGEquipment',
    // fields: Schemas.EquipmentFields,
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Facility',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  expenseBatch: {
    schema: Schemas.ExpenseBatchSchema,
    query: Schemas.ExpenseBatchQuery,
    type: {} as Types.ExpenseBatch,
    map: Schemas.ExpenseBatchMap,
    odata: 'TAGBatch',
  },
  expense: {
    schema: Schemas.ExpenseSchema,
    query: Schemas.ExpenseQuery,
    type: {} as Types.Expense,
    map: Schemas.ExpenseMap,
    odata: 'TAGExpense',
  },
  facility: {
    schema: Schemas.FacilitySchema,
    query: Schemas.FacilityQuery,
    type: {} as Types.Facility,
    map: Schemas.FacilityMap,
    odata: 'TP_FacilitiesWS',
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Code',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  faultEntry: {
    schema: Schemas.FaultEntrySchema,
    query: Schemas.FaultEntryQuery,
    type: {} as Types.FaultEntry,
    map: Schemas.FaultEntryMap,
    odata: 'TAGFaultEntryLines',
  },
  workOrderFeedbackHeader: {
    schema: Schemas.WorkOrderFeedbackHeaderSchema,
    query: Schemas.WorkOrderFeedbackHeaderQuery,
    type: {} as Types.WorkOrderFeedbackHeader,
    map: Schemas.WorkOrderFeedbackHeaderMap,
    odata: 'WebWorkOrderHeaderFeedback',
  },
  finishedWorkOrderLine: {
    schema: Schemas.FinishedWorkOrderLineSchema,
    query: Schemas.FinishedWorkOrderLineQuery,
    type: {} as Types.FinishedWorkOrderLine,
    map: Schemas.FinishedWorkOrderLineMap,
    odata: 'TAGFinishedWorkOrderLine',
  },
  finishedWorkOrder: {
    schema: Schemas.FinishedWorkOrderSchema,
    query: Schemas.FinishedWorkOrderQuery,
    type: {} as Types.FinishedWorkOrder,
    map: Schemas.FinishedWorkOrderMap,
    odata: 'TAGFinishedWorkOrder',
  },
  formData: {
    schema: Schemas.FormDataSchema,
    query: Schemas.FormDataQuery,
    type: {} as Types.FormData,
    map: null,
    odata: '',
  },
  form: {
    schema: Schemas.FormSchema,
    query: Schemas.FormQuery,
    type: {} as Types.Form,
    map: null,
    odata: '',
  },
  formStatus: {
    schema: Schemas.FormStatusSchema,
    query: Schemas.FormStatusQuery,
    type: {} as Types.FormStatus,
    map: null,
    odata: '',
  },
  glAccount: {
    schema: Schemas.glAccountSchema,
    query: Schemas.glAccountQuery,
    map: Schemas.glAccountMap,
    type: {} as Types.GLAccount,
    odata: 'TAGGLAccounts',
  },
  itemAvailability: {
    schema: Schemas.ItemAvailabilitySchema,
    query: Schemas.ItemAvailabilityQuery,
    type: {} as Types.ItemAvailability,
    map: null,
    odata: '',
  },
  itemByBarcode: {
    schema: Schemas.ItemByBarcodeSchema,
    query: Schemas.ItemByBarcodeQuery,
    type: {} as Types.ItemByBarcode,
    map: null,
    odata: '',
  },
  item: {
    schema: Schemas.ItemSchema,
    query: Schemas.ItemQuery,
    type: {} as Types.Item,
    map: Schemas.ItemMap,
    odata: 'WebItemList',
  },
  itemIdentifier: {
    schema: Schemas.ItemIdentifierSchema,
    query: Schemas.ItemIdentifierQuery,
    map: Schemas.ItemIdentifierMap,
    type: {} as Types.ItemIdentifier,
    odata: 'TAGItemIdentifier',
  },
  jobDependency: {
    schema: Schemas.JobDependencySchema,
    query: Schemas.JobDependencyQuery,
    map: Schemas.JobDependencyMap,
    type: {} as Types.JobDependency,
    odata: 'TPRJobTaskDependency',
  },
  jobPlanningLine: {
    schema: Schemas.JobPlanningLineSchema,
    query: Schemas.JobPlanningLineQuery,
    map: Schemas.JobPlanningLineMap,
    type: {} as Types.JobPlanningLine,
    odata: 'TPRJobPlanningLines',
  },
  jobTask: {
    schema: Schemas.JobTaskLineSchema,
    query: Schemas.JobTaskLineQuery,
    type: {} as Types.JobTaskLine,
    map: Schemas.JobTaskMap,
    odata: 'TPRJobTasks',
  },
  job: {
    schema: Schemas.JobSchema,
    query: Schemas.JobQuery,
    map: Schemas.JobMap,
    type: {} as Types.Job,
    odata: 'TPRJobs',
  },
  line: {
    schema: Schemas.LineSchema,
    query: Schemas.LineQuery,
    map: Schemas.LineMap,
    type: {} as Types.Line,
    odata: 'LinesList',
  },
  location: {
    schema: Schemas.LocationSchema,
    query: Schemas.LocationQuery,
    type: {} as Types.Location,
    map: Schemas.LocationMap,
    odata: 'WebLocationList',
  },
  mainCode: {
    schema: Schemas.MainCodeSchema,
    query: Schemas.MainCodeQuery,
    map: Schemas.MainCodeMap,
    type: {} as Types.MainCode,
    odata: 'Main_Codes_WS',
  },
  maintenanceType: {
    schema: Schemas.MaintenanceTypeSchema,
    query: Schemas.MaintenanceTypeQuery,
    type: {} as Types.MaintenanceType,
    map: Schemas.MaintenanceTypeMap,
    odata: 'MaintenanceTypeList',
  },
  meterReading: {
    schema: Schemas.MeterReadingSchema,
    query: Schemas.MeterReadingQuery,
    type: {} as Types.MeterReading,
    map: Schemas.MeterReadingMap,
    odata: 'WebMeterHistory',
  },
  noSerie: {
    schema: Schemas.NoSerieSchema,
    query: Schemas.NoSerieQuery,
    type: {} as Types.NoSerie,
    map: Schemas.NoSerieMap,
    odata: 'TAGNoSeries',
  },
  orderType: {
    schema: Schemas.OrderTypeSchema,
    query: Schemas.OrderTypeQuery,
    type: {} as Types.OrderType,
    map: Schemas.OrderTypeMap,
    odata: 'OrderTypeList',
  },
  outsideServiceProvider: {
    schema: Schemas.OutsideServiceProviderSchema,
    query: Schemas.OutsideServiceProviderQuery,
    type: {} as Types.OutsideServiceProvider,
    map: Schemas.OutsideServiceProviderMap,
    odata: 'OSPList',
  },
  part: {
    schema: Schemas.PartSchema,
    query: Schemas.PartQuery,
    type: {} as Types.Part,
    map: Schemas.PartMap,
    odata: 'WebPartList',
  },
  personnel: {
    schema: Schemas.PersonnelSchema,
    query: Schemas.PersonnelQuery,
    type: {} as Types.Personnel,
    map: Schemas.PersonnelMap,
    odata: 'TAGPersonnel',
  },
  personnelGroup: {
    schema: Schemas.PersonnelGroupSchema,
    query: Schemas.PersonnelGroupQuery,
    type: {} as Types.PersonnelGroup,
    map: Schemas.PersonnelGroupMap,
    odata: 'TAGPersonnelGroupWS',
  },
  plannedWorkOrderLine: {
    schema: Schemas.PlannedWorkOrderLineSchema,
    query: Schemas.PlannedWorkOrderLineQuery,
    type: {} as Types.PlannedWorkOrderLine,
    map: Schemas.PlannedWorkOrderLineMap,
    odata: 'TAGWorkOrderLine',
  },
  plannedWorkOrder: {
    schema: Schemas.PlannedWorkOrderSchema,
    query: Schemas.PlannedWorkOrderQuery,
    type: {} as Types.PlannedWorkOrder,
    map: Schemas.PlannedWorkOrderMap,
    odata: 'TAGWorkOrder',
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Facility',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  postedTimesheet: {
    schema: Schemas.PostedTimesheetSchema,
    query: Schemas.PostedTimesheetQuery,
    type: {} as Types.PostedTimesheet,
    map: Schemas.PostedTimesheetMap,
    odata: 'TAGPostedTimeSheetLines',
  },
  primaryCode: {
    schema: Schemas.PrimaryCodeSchema,
    query: Schemas.PrimaryCodeQuery,
    type: {} as Types.PrimaryCode,
    map: Schemas.PrimaryCodeMap,
    odata: 'Primary_Code_WS',
  },
  priorityRank: {
    schema: Schemas.PriorityRankSchema,
    query: Schemas.PriorityRankQuery,
    type: {} as Types.PriorityRank,
    map: Schemas.PriorityRankMap,
    odata: 'TAGPriorityRank',
  },
  problemCode: {
    schema: Schemas.ProblemCodeSchema,
    query: Schemas.ProblemCodeQuery,
    type: {} as Types.ProblemCode,
    map: Schemas.ProblemCodeMap,
    odata: 'ProblemCodeList',
  },
  purchaseComment: {
    schema: Schemas.PurchaseCommentSchema,
    query: Schemas.PurchaseCommentQuery,
    type: {} as Types.PurchaseComment,
    map: Schemas.PurchaseCommentMap,
    odata: 'TAGPurchaseComments',
  },
  purchaseInvoiceLine: {
    schema: Schemas.PurchaseInvoiceLineSchema,
    query: Schemas.PurchaseInvoiceLineQuery,
    type: {} as Types.PurchaseInvoiceLine,
    map: null,
    odata: 'PurchaseInvoiceLine',
  },
  purchaseInvoice: {
    schema: Schemas.PurchaseInvoiceSchema,
    query: Schemas.PurchaseInvoiceQuery,
    type: {} as Types.PurchaseInvoice,
    map: null,
    odata: 'PurchaseInvoice',
  },
  purchaseOrderLine: {
    schema: Schemas.PurchaseOrderLineSchema,
    query: Schemas.PurchaseOrderLineQuery,
    type: {} as Types.PurchaseOrderLine,
    map: Schemas.PurchaseOrderLineMap,
    odata: 'TAGPurchaseLines',
  },
  purchaseOrder: {
    schema: Schemas.PurchaseOrderSchema,
    query: Schemas.PurchaseOrderQuery,
    type: {} as Types.PurchaseOrder,
    map: Schemas.PurchaseOrderMap,
    odata: 'TAGPurchaseOrderList',
  },
  rateType: {
    schema: Schemas.RateTypeSchema,
    query: Schemas.RateTypeQuery,
    type: {} as Types.RateType,
    map: Schemas.RateTypeMap,
    odata: 'TAGRateType',
  },
  reasonCode: {
    schema: Schemas.ReasonCodeSchema,
    query: Schemas.ReasonCodeQuery,
    type: {} as Types.ReasonCode,
    map: Schemas.ReasonCodeMap,
    odata: 'TAGReasonCodes',
  },
  resource: {
    schema: Schemas.ResourceSchema,
    query: Schemas.ResourceQuery,
    type: {} as Types.Resource,
    map: Schemas.ResourceMap,
    odata: 'TAGResource',
  },
  resourceCertification: {
    schema: Schemas.ResourceCertificationSchema,
    query: Schemas.ResourceCertificationQuery,
    type: {} as Types.ResourceCertification,
    map: Schemas.ResourceCertificationMap,
    odata: 'TAGResourceCertification',
  },
  region: {
    schema: Schemas.RegionSchema,
    query: Schemas.RegionQuery,
    type: {} as Types.Region,
    odata: 'RegionsList',
    map: Schemas.RegionMap,
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Code',
        },
      ],
    },
  },
  request: {
    schema: Schemas.RequestSchema,
    query: Schemas.RequestQuery,
    type: {} as Types.Request,
    odata: 'TAGRequest',
    map: Schemas.RequestMap,
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Facility',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  relatedRequirement: {
    schema: Schemas.RelatedRequirementSchema,
    query: Schemas.RelatedRequirementQuery,
    type: {} as Types.RelatedRequirement,
    map: Schemas.RequirementMap,
    odata: 'WebExpectedRelatedReq',
  },
  secondaryCode: {
    schema: Schemas.SecondaryCodeSchema,
    query: Schemas.SecondaryCodeQuery,
    type: {} as Types.SecondaryCode,
    map: Schemas.SecondaryCodeMap,
    odata: 'Secondary_Code',
  },
  skill: {
    schema: Schemas.SkillSchema,
    query: Schemas.SkillQuery,
    type: {} as Types.Skill,
    map: Schemas.SkillMap,
    odata: 'Skills',
  },
  statusCode: {
    schema: Schemas.StatusCodeSchema,
    query: Schemas.StatusCodeQuery,
    type: {} as Types.StatusCode,
    map: Schemas.StatusCodeMap,
    odata: 'StatusCodeLookup',
  },
  tagSetup: {
    schema: Schemas.TAGSetupSchema,
    query: Schemas.TAGSetupQuery,
    type: {} as Types.TAGSetup,
    map: Schemas.TagSetupMap,
    odata: 'TAGSetup',
  },
  tool: {
    schema: Schemas.ToolSchema,
    query: Schemas.ToolQuery,
    type: {} as Types.Tool,
    map: Schemas.ToolMap,
    odata: 'WebToolList',
  },
  timesheet: {
    schema: Schemas.TimesheetSchema,
    query: Schemas.TimesheetQuery,
    type: {} as Types.Timesheet,
    map: Schemas.TimesheetMap,
    odata: 'TagWEBtimesheet',
  },
  union: {
    schema: Schemas.UnionSchema,
    query: Schemas.UnionQuery,
    map: Schemas.UnionMap,
    type: {} as Types.Union,
    odata: 'TAGUnion',
  },
  unitOfMeasure: {
    schema: Schemas.UnitOfMeasureSchema,
    query: Schemas.UnitOfMeasureQuery,
    map: Schemas.UnitOfMeasureMap,
    type: {} as Types.UnitOfMeasure,
    odata: 'TAGUnitofMeasure',
  },
  universalDocumentNo: {
    schema: Schemas.UniversalDocumentNoSchema,
    query: Schemas.UniversalDocumentNoQuery,
    type: {} as Types.UniversalDocumentNo,
    map: Schemas.UniversalDocumentNoMap,
    odata: 'TAGUDNWO',
  },
  vendorConsumption: {
    schema: Schemas.VendorConsumptionSchema,
    query: Schemas.VendorConsumptionQuery,
    type: {} as Types.VendorConsumption,
    map: null,
    odata: 'TAGVendorConsumptions',
  },
  vendor: {
    schema: Schemas.VendorSchema,
    query: Schemas.VendorQuery,
    map: Schemas.VendorMap,
    type: {} as Types.Vendor,
    odata: 'TAGVendor',
  },
  workCode: {
    schema: Schemas.WorkCodeSchema,
    query: Schemas.WorkCodeQuery,
    map: Schemas.WorkCodeMap,
    type: {} as Types.WorkCode,
    odata: 'WorkCodeList',
  },
  workOrderLine: {
    schema: Schemas.WorkOrderLineSchema,
    query: Schemas.WorkOrderLineQuery,
    map: Schemas.WorkOrderLineMap,
    type: {} as Types.WorkOrderLine,
    odata: 'TAGWorkOrderLine',
  },
  workOrder: {
    schema: Schemas.WorkOrderSchema,
    query: Schemas.WorkOrderQuery,
    type: {} as Types.WorkOrder,
    odata: 'TAGWorkOrder',
    map: Schemas.WorkOrderMap,
    fields: Schemas.WorkOrderFields,
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Facility',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  allWorkOrder: {
    schema: Schemas.WorkOrderSchema,
    query: Schemas.AllWorkOrderQuery,
    type: {} as Types.WorkOrder,
    odata: 'TAGWorkOrder',
    map: Schemas.WorkOrderMap,
    fields: Schemas.WorkOrderFields,
    security: {
      filters: [
        {
          tokenKey: 'defaultFilterFacilityList',
          tokenOverride: 'viewFacilitiesOverride',
          type: 'array',
          field: 'Facility',
        },
        {
          tokenKey: 'defaultFilterRegionList',
          tokenOverride: 'viewRegionsOverride',
          type: 'array',
          field: 'Region',
        },
        {
          tokenKey: 'defaultFilterEnterpriseList',
          tokenOverride: 'viewEnterpriseOverride',
          type: 'array',
          field: 'Enterprise',
        },
      ],
    },
  },
  workOrderTemplate: {
    schema: Schemas.WorkOrderTemplateSchema,
    query: Schemas.WorkOrderTemplateQuery,
    type: {} as Types.WorkOrderTemplate,
    map: Schemas.WorkOrderTemplateMap,
    odata: 'WebTemplateList',
  },
  workProcedure: {
    schema: Schemas.WorkProcedureSchema,
    query: Schemas.WorkProcedureQuery,
    type: {} as Types.WorkProcedure,
    map: Schemas.WorkProcedureMap,
    odata: 'TAGWorkProcedure',
  },
} as const satisfies GqlConfig;

// Remove all the #graphql tags
const schema = `${Object.keys(GQL_CONFIG)
  .map((key) => {
    const keyconfig = key as keyof typeof GQL_CONFIG;

    const type = GQL_CONFIG[key as keyof typeof GQL_CONFIG].schema
      .split('type ')[1]
      .split(' {')[0];

    const paginatedType = `type Paginated${type} {
      items: [${type}],
      totalCount: Float
    }`;

    return GQL_CONFIG[keyconfig].schema + `\n` + paginatedType;
  })
  .join('\n')
  .replace(/#graphql/g, '')}`;

const query = `${Object.keys(GQL_CONFIG)
  .map((key: any) => {
    const type = GQL_CONFIG[key as keyof typeof GQL_CONFIG].schema
      .split('type ')[1]
      .split(' {')[0];
    const query = getQueryName(key as keyof typeof GQL_CONFIG);
    return `${query}(filter:String,dynamicFilter:Object,top:Int,skip:Int,search:String,orderBy:String,desc:Boolean,no:String,environmentId:String,type:String,lineNo:Float,facility:String): Paginated${type}`;
  })
  .join('\n')}`;

// const p = path.resolve(__dirname, 'generated/schema.graphql');
// fs.writeFileSync(p + '/schema.graphql', schema);

export const typeDefs = gql`
  scalar Date
  scalar Dictionary
  scalar Object

  type Query {
    ${query}
  }

  ${schema}
`;

export type Config = typeof GQL_CONFIG;
export type DataType = keyof Config;
export default GQL_CONFIG;
