import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const BinMap = {
  binCode: {
    required: false,
    bcKey: 'Bin_Code',
    type: 'string',
  },
  itemNo: {
    required: false,
    bcKey: 'Item_No',
    type: 'string',
  },
  unitOfMeasureCode: {
    required: false,
    bcKey: 'Unit_Of_Measure_Code',
    type: 'string',
  },
  locationCode: {
    required: false,
    bcKey: 'Location_Code',
    type: 'string',
  },
  variantCode: {
    required: false,
    bcKey: 'Variant_Code',
    type: 'string',
  },
  default: {
    required: false,
    bcKey: 'Default',
    type: 'boolean',
  },
  dedicated: {
    required: false,
    bcKey: 'Dedicated',
    type: 'boolean',
  },
  warehouseClassCode: {
    required: false,
    bcKey: 'Warehouse_Class_Code',
    type: 'string',
  },
  binTypeCode: {
    required: false,
    bcKey: 'Bin_Type_Code',
    type: 'string',
  },
  binRanking: {
    required: false,
    bcKey: 'Bin_Ranking',
    type: 'number',
  },
  blockMovement: {
    required: false,
    bcKey: 'Block_Movement',
    type: 'string',
  },
  minQty: {
    required: false,
    bcKey: 'Min_Qty',
    type: 'number',
  },
  maxQty: {
    required: false,
    bcKey: 'Max_Qty',
    type: 'number',
  },
  fixed: {
    required: false,
    bcKey: 'Fixed',
    type: 'boolean',
  },
  quantity: {
    required: false,
    bcKey: 'Quantity',
    type: 'number',
  },
  quantityBase: {
    required: false,
    bcKey: 'Quantity_Base',
    type: 'number',
  },
} as const satisfies ODataMap;
type BinContent = ODataObjectMap<typeof BinMap>;

const BinContentSchema = generateGraphQlSchema('BinContent', BinMap);

const BinContentQuery = generateGraphQlQuery('BinContent', BinMap);

export { BinContentSchema, BinContentQuery, type BinContent, BinMap };
