import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const UnitOfMeasureMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type UnitOfMeasure = ODataObjectMap<typeof UnitOfMeasureMap>;

const UnitOfMeasureSchema = generateGraphQlSchema(
  'UnitOfMeasure',
  UnitOfMeasureMap
);

const UnitOfMeasureQuery = generateGraphQlQuery(
  'UnitOfMeasure',
  UnitOfMeasureMap
);

export {
  UnitOfMeasureSchema,
  UnitOfMeasureQuery,
  type UnitOfMeasure,
  UnitOfMeasureMap,
};
