import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const EquipmentFailureCodeSchemaMap = {
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  failureCode: {
    bcKey: 'Failure_Code',
    type: 'string',
    required: false,
  },
  failureCodeDescription: {
    bcKey: 'Failure_Code_Description',
    type: 'string',
    required: false,
  },
  failurePriority: {
    bcKey: 'Failure_Priority',
    type: 'string',
    required: false,
  },
  assesmentType: {
    bcKey: 'Assesment_Type',
    type: 'string',
    required: false,
  },
  specificValue: {
    bcKey: 'Specific_Value',
    type: 'string',
    required: false,
  },
  valueMinEntry: {
    bcKey: 'Value_Min_Entry',
    type: 'number',
    required: false,
  },
  passLowRange: {
    bcKey: 'Pass_Low_Range',
    type: 'number',
    required: false,
  },
  passHighRange: {
    bcKey: 'Pass_High_Range',
    type: 'number',
    required: false,
  },
  valueMaxEntry: {
    bcKey: 'Value_Max_Entry',
    type: 'number',
    required: false,
  },
  passValue: {
    bcKey: 'Pass_Value',
    type: 'string',
    required: false,
  },
  assessmentDetails: {
    bcKey: 'Assessment_Details',
    type: 'string',
    required: false,
  },
  templateId: {
    bcKey: 'Template_ID',
    type: 'string',
    required: false,
  },
  templateDescription: {
    bcKey: 'Template_Description',
    type: 'string',
    required: false,
  },
  autoGenerateWorkOrder: {
    bcKey: 'Auto_Generate_Work_Order',
    type: 'boolean',
    required: false,
  },
  workOrderType: {
    bcKey: 'Work_Order_Type',
    type: 'string',
    required: false,
  },
  outageCaused: {
    bcKey: 'Outage_Caused',
    type: 'boolean',
    required: false,
  },
  autoResolve: {
    bcKey: 'Auto_Resolve',
    type: 'boolean',
    required: false,
  },
  workOrderPriority: {
    bcKey: 'Work_Order_Priority',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type EquipmentFailureCode = ODataObjectMap<
  typeof EquipmentFailureCodeSchemaMap
>;

const EquipmentFailureCodeSchema = generateGraphQlSchema(
  'EquipmentFailureCode',
  EquipmentFailureCodeSchemaMap
);

const EquipmentFailureCodeQuery = generateGraphQlQuery(
  'EquipmentFailureCode',
  EquipmentFailureCodeSchemaMap
);

export {
  EquipmentFailureCodeSchema,
  EquipmentFailureCodeQuery,
  type EquipmentFailureCode,
  EquipmentFailureCodeSchemaMap,
};
