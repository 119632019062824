import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const glAccountMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  incomeBalance: {
    bcKey: 'Income_Balance',
    type: 'string',
    required: false,
  },
  accountCategory: {
    bcKey: 'Account_Category',
    type: 'string',
    required: false,
  },
  accountType: {
    bcKey: 'Account_Type',
    type: 'string',
    required: false,
  },
  genPostingType: {
    bcKey: 'Gen_Posting_Type',
    type: 'string',
    required: false,
  },
  genBusPostingGroup: {
    bcKey: 'Gen_Bus_Posting_Group',
    type: 'string',
    required: false,
  },
  genProdPostingGroup: {
    bcKey: 'Gen_Prod_Posting_Group',
    type: 'string',
    required: false,
  },
  vatBusPostingGroup: {
    bcKey: 'VAT_Bus_Posting_Group',
    type: 'string',
    required: false,
  },
  vatProdPostingGroup: {
    bcKey: 'VAT_Prod_Posting_Group',
    type: 'string',
    required: false,
  },
  directPosting: {
    bcKey: 'Direct_Posting',
    type: 'boolean',
    required: false,
  },
  reconciliationAccount: {
    bcKey: 'Reconciliation_Account',
    type: 'boolean',
    required: false,
  },
  defaultDeferralTemplateCode: {
    bcKey: 'Default_Deferral_Template_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type GLAccount = ODataObjectMap<typeof glAccountMap>;

const glAccountSchema = generateGraphQlSchema('GLAccount', glAccountMap);

const glAccountQuery = generateGraphQlQuery('GLAccount', glAccountMap);

export { glAccountSchema, glAccountQuery, type GLAccount, glAccountMap };
