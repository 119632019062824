import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ResourceMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  baseUnitOfMeasure: {
    bcKey: 'Base_Unit_of_Measure',
    type: 'string',
    required: false,
  },
  resourceGroupNo: {
    bcKey: 'Resource_Group_No',
    type: 'string',
    required: false,
  },
  directUnitCost: {
    bcKey: 'Direct_Unit_Cost',
    type: 'number',
    required: false,
  },
  indirectCostPercent: {
    bcKey: 'Indirect_Cost_Percent',
    type: 'number',
    required: false,
  },
  unitCost: {
    bcKey: 'Unit_Cost',
    type: 'number',
    required: false,
  },
  priceProfitCalculation: {
    bcKey: 'Price_Profit_Calculation',
    type: 'string',
    required: false,
  },
  profitPercent: {
    bcKey: 'Profit_Percent',
    type: 'number',
    required: false,
  },
  unitPrice: {
    bcKey: 'Unit_Price',
    type: 'number',
    required: false,
  },
  genProdPostingGroup: {
    bcKey: 'Gen_Prod_Posting_Group',
    type: 'string',
    required: false,
  },
  vatProdPostingGroup: {
    bcKey: 'VAT_Prod_Posting_Group',
    type: 'string',
    required: false,
  },
  privacyBlocked: {
    bcKey: 'Privacy_Blocked',
    type: 'boolean',
    required: false,
  },
  searchName: {
    bcKey: 'Search_Name',
    type: 'string',
    required: false,
  },
  defaultDeferralTemplateCode: {
    bcKey: 'Default_Deferral_Template_Code',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  tagGenericResource: {
    bcKey: 'TAG_Generic_Resource',
    type: 'boolean',
    required: false,
  },
  tagPersonnelGroup: {
    bcKey: 'TAG_Personnel_Group',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Resource = ODataObjectMap<typeof ResourceMap>;

const ResourceSchema = generateGraphQlSchema('Resource', ResourceMap);

const ResourceQuery = generateGraphQlQuery('Resource', ResourceMap);

export { ResourceSchema, type Resource, ResourceQuery, ResourceMap };
