import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const WorkOrderLineMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  workOrderNo: {
    bcKey: 'Work_Order_No',
    type: 'string',
    required: true,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: true,
  },
  operationSequence: {
    bcKey: 'Operation_Sequence',
    type: 'number',
    required: false,
  },
  workProcedureStep: {
    bcKey: 'Work_Procedure_Step',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  orderDate: {
    bcKey: 'Order_Date',
    type: 'date',
    required: false,
  },
  startingDatetime: {
    bcKey: 'Starting_Datetime',
    type: 'date',
    required: false,
  },
  endingDatetime: {
    bcKey: 'Ending_Datetime',
    type: 'date',
    required: false,
  },
  finished: {
    bcKey: 'Finished',
    type: 'boolean',
    required: false,
  },
  dateFilter: {
    bcKey: 'Date_Filter',
    type: 'string',
    required: false,
  },
  dueByDate: {
    bcKey: 'Due_By_Date',
    type: 'date',
    required: false,
  },
  maintBom: {
    bcKey: 'Maint_BOM',
    type: 'boolean',
    required: false,
  },
  maintPostingGroup: {
    bcKey: 'Maint_Posting_Group',
    type: 'string',
    required: false,
  },
  duration: {
    bcKey: 'Duration',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  documentTrackingNo: {
    bcKey: 'Document_Tracking_No',
    type: 'string',
    required: false,
  },
  documentTrackingLineNo: {
    bcKey: 'Document_Tracking_Line_No',
    type: 'number',
    required: false,
  },
  maintRoutingVersionCode: {
    bcKey: 'Maint_Routing_Version_Code',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  actualTime: {
    bcKey: 'Actual_Time',
    type: 'number',
    required: true,
  },
  standardTime: {
    bcKey: 'Standard_Time',
    type: 'number',
    required: false,
  },
  estimatedTime: {
    bcKey: 'Estimated_Time',
    type: 'number',
    required: true,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  technicianName: {
    bcKey: 'Technician_Name',
    type: 'string',
    required: false,
  },
  maintBomNo: {
    bcKey: 'Maint_BOM_No',
    type: 'string',
    required: false,
  },
  lineSource: {
    bcKey: 'Line_Source',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  creationDate: {
    bcKey: 'Creation_Date',
    type: 'string',
    required: false,
  },
  lastModifiedBy: {
    bcKey: 'Last_Modified_By',
    type: 'string',
    required: false,
  },
  lastModified: {
    bcKey: 'Last_Modified',
    type: 'date',
    required: false,
  },
  closedBy: {
    bcKey: 'Closed_By',
    type: 'string',
    required: false,
  },
  closedDate: {
    bcKey: 'Closed_Date',
    type: 'string',
    required: false,
  },
  unitCost: {
    bcKey: 'Unit_Cost',
    type: 'number',
    required: false,
  },
  estimatedCost: {
    bcKey: 'Estimated_Cost',
    type: 'number',
    required: false,
  },
  actualCost: {
    bcKey: 'Actual_Cost',
    type: 'number',
    required: false,
  },
  approvalPending: {
    bcKey: 'Approval_Pending',
    type: 'boolean',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  resultsInputRequired: {
    bcKey: 'Results_Input_Required',
    type: 'boolean',
    required: false,
  },
  resultsInputCompleted: {
    bcKey: 'Results_Input_Completed',
    type: 'boolean',
    required: false,
  },
  resultDatetime: {
    bcKey: 'Result_Datetime',
    type: 'date',
    required: false,
  },
  resultDate: {
    bcKey: 'Result_Date',
    type: 'string',
    required: false,
  },
  resultTime: {
    bcKey: 'Result_Time',
    type: 'string',
    required: false,
  },
  inputValidationRequired: {
    bcKey: 'Input_Validation_Required',
    type: 'boolean',
    required: false,
  },
  resultType: {
    bcKey: 'Result_Type',
    type: 'string',
    required: false,
  },
  symbolAllowed: {
    bcKey: 'Symbol_Allowed',
    type: 'string',
    required: false,
  },
  numericAcceptableLow: {
    bcKey: 'Numeric_Acceptable_Low',
    type: 'number',
    required: false,
  },
  numericAcceptableHigh: {
    bcKey: 'Numeric_Acceptable_High',
    type: 'number',
    required: false,
  },
  numericValidation1Low: {
    bcKey: 'Numeric_Validation_1_Low',
    type: 'number',
    required: false,
  },
  numericValidation1High: {
    bcKey: 'Numeric_Validation_1_High',
    type: 'number',
    required: false,
  },
  numericValidation1Message: {
    bcKey: 'Numeric_Validation_1_Message',
    type: 'string',
    required: false,
  },
  numericValidation2Low: {
    bcKey: 'Numeric_Validation_2_Low',
    type: 'number',
    required: false,
  },
  numericValidation2High: {
    bcKey: 'Numeric_Validation_2_High',
    type: 'number',
    required: false,
  },
  numericValidation2Message: {
    bcKey: 'Numeric_Validation_2_Message',
    type: 'string',
    required: false,
  },
  specificValueRequired: {
    bcKey: 'Specific_Value_Required',
    type: 'string',
    required: false,
  },
  resultsValue: {
    bcKey: 'Results_Value',
    type: 'string',
    required: false,
  },
  resultsAccepted: {
    bcKey: 'Results_Accepted',
    type: 'boolean',
    required: false,
  },
  currentReading: {
    bcKey: 'Current_Reading',
    type: 'number',
    required: false,
  },
  rolloverAllowed: {
    bcKey: 'Rollover_Allowed',
    type: 'boolean',
    required: false,
  },
  rolloverPoint: {
    bcKey: 'Rollover_Point',
    type: 'number',
    required: false,
  },
  priorReading: {
    bcKey: 'Prior_Reading',
    type: 'number',
    required: false,
  },
  unitOfMeasure: {
    bcKey: 'Unit_of_Measure',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  line: {
    bcKey: 'Line',
    type: 'string',
    required: false,
  },
  masterEquipId: {
    bcKey: 'Master_Equip_ID',
    type: 'string',
    required: false,
  },
  sortMaster: {
    bcKey: 'Sort_Master',
    type: 'string',
    required: false,
  },
  binaryNegativeValue: {
    bcKey: 'Binary_Negative_Value',
    type: 'string',
    required: false,
  },
  binaryPositiveValue: {
    bcKey: 'Binary_Positive_Value',
    type: 'string',
    required: false,
  },
  feedbackExist: {
    bcKey: 'Feedback_Exist',
    type: 'string',
    required: false,
  },
  include: {
    bcKey: 'Include',
    type: 'boolean',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  requestedServiceDate: {
    bcKey: 'Requested_Service_Date',
    type: 'date',
    required: false,
  },
  expirationDate: {
    bcKey: 'Expiration_Date',
    type: 'date',
    required: false,
  },
  lineStatus: {
    bcKey: 'Line_Status',
    type: 'string',
    required: false,
  },
  standardCost: {
    bcKey: 'Standard_Cost',
    type: 'number',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  capitalExpenditure: {
    bcKey: 'Capital_Expenditure',
    type: 'boolean',
    required: false,
  },
  requirementsPlanned: {
    bcKey: 'Requirements_Planned',
    type: 'number',
    required: false,
  },
  qualificationsPlanned: {
    bcKey: 'Qualifications_Planned',
    type: 'number',
    required: false,
  },
  attachments: {
    bcKey: 'Attachments',
    type: 'number',
    required: false,
  },
  feedback: {
    bcKey: 'Feedback',
    type: 'number',
    required: false,
  },
  requirementsReleased: {
    bcKey: 'Requirements_Released',
    type: 'number',
    required: false,
  },
  qualificationsReleased: {
    bcKey: 'Qualifications_Released',
    type: 'number',
    required: false,
  },
  meterReadings: {
    bcKey: 'Meter_Readings',
    type: 'number',
    required: false,
  },
  testResults: {
    bcKey: 'Test_Results',
    type: 'number',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
  standingOrder: {
    bcKey: 'Standing_Order',
    type: 'boolean',
    required: false,
  },
  sortSequence: {
    bcKey: 'Sort_Sequence',
    type: 'string',
    required: false,
  },
  companyIdNo: {
    bcKey: 'Company_ID_No',
    type: 'string',
    required: false,
  },
  meterJournal: {
    bcKey: 'Meter_Journal',
    type: 'boolean',
    required: false,
  },
  resultTestDescription: {
    bcKey: 'Result_Test_Description',
    type: 'string',
    required: false,
  },
  tagWebRecordLink: {
    bcKey: 'TAG_Web_Record_Link',
    type: 'string',
    required: false,
  },
  doNotShowInDispatch: {
    bcKey: 'Do_Not_Show_in_Dispatch',
    type: 'boolean',
    required: false,
  },
  faultLogNo: {
    bcKey: 'Fault_Log_No',
    type: 'string',
    required: false,
  },
  faultResolved: {
    bcKey: 'Fault_Resolved',
    type: 'boolean',
    required: false,
  },
  failureCode: {
    bcKey: 'Failure_Code',
    type: 'string',
    required: false,
  },
  dimensionSetId: {
    bcKey: 'Dimension_Set_ID',
    type: 'number',
    required: false,
  },
  indentation: {
    bcKey: 'Indentation',
    type: 'number',
    required: false,
  },
  linkedToWorkOrderNo: {
    bcKey: 'Linked_to_Work_Order_No',
    type: 'string',
    required: false,
  },
  linkedToWorkOrderLineNo: {
    bcKey: 'Linked_to_Work_Order_Line_No',
    type: 'number',
    required: false,
  },
  totalActualTime: {
    bcKey: 'Total_Actual_Time',
    type: 'string',
    required: false,
  },
  fromLineNo: {
    bcKey: 'From_Line_No',
    type: 'number',
    required: false,
  },
  fromWorkProcedureStep: {
    bcKey: 'From_Work_Procedure_Step',
    type: 'string',
    required: false,
  },
  unfinishedLog: {
    bcKey: 'Unfinished_Log',
    type: 'boolean',
    required: false,
  },
  faultActualTime: {
    bcKey: 'Fault_Actual_Time',
    type: 'number',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  priorityRank: {
    bcKey: 'Priority_Rank',
    type: 'string',
    required: false,
  },
  billingType: {
    bcKey: 'Billing_Type',
    type: 'string',
    required: false,
  },
  billingNo: {
    bcKey: 'Billing_No',
    type: 'string',
    required: false,
  },
  billingUnitPrice: {
    bcKey: 'Billing_Unit_Price',
    type: 'number',
    required: false,
  },
  billingLineSource: {
    bcKey: 'Billing_Line_Source',
    type: 'string',
    required: false,
  },
  billingStatus: {
    bcKey: 'Billing_Status',
    type: 'string',
    required: false,
  },
  wpQuantity: {
    bcKey: 'WP_Quantity',
    type: 'number',
    required: false,
  },
  estimatedBillingPrice: {
    bcKey: 'Estimated_Billing_Price',
    type: 'number',
    required: false,
  },
  actualBillingPrice: {
    bcKey: 'Actual_Billing_Price',
    type: 'number',
    required: false,
  },
  equipSerialNo: {
    bcKey: 'Equip_Serial_No',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  equipmentSubgroup: {
    bcKey: 'Equipment_Subgroup',
    type: 'string',
    required: false,
  },
  subSteps: {
    bcKey: 'Sub_Steps',
    type: 'number',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'Job_Task_No',
    type: 'string',
    required: false,
  },
  jobLineType: {
    bcKey: 'Job_Line_Type',
    type: 'string',
    required: false,
  },
  jobStartTime: {
    bcKey: 'Job_Start_Time',
    type: 'string',
    required: false,
  },
  jobDescription: {
    bcKey: 'Job_Description',
    type: 'string',
    required: false,
  },
  jobTaskDescription: {
    bcKey: 'Job_Task_Description',
    type: 'string',
    required: false,
  },
  location: {
    bcKey: 'Location',
    type: 'string',
    required: false,
  },
  webServiceUser: {
    bcKey: 'Web_Service_User',
    type: 'string',
    required: false,
  },
  equipMain: {
    bcKey: 'Equip_Main',
    type: 'string',
    required: false,
  },
  equipPrimary: {
    bcKey: 'Equip_Primary',
    type: 'string',
    required: false,
  },
  equipSecondary: {
    bcKey: 'Equip_Secondary',
    type: 'string',
    required: false,
  },
  lockUnitPrice: {
    bcKey: 'Lock_Unit_Price',
    type: 'boolean',
    required: false,
  },
  woUnionNo: {
    bcKey: 'WO_Union_No',
    type: 'string',
    required: false,
  },
  unionNoUsed: {
    bcKey: 'Union_No_Used',
    type: 'string',
    required: false,
  },
  unionNo: {
    bcKey: 'Union_No',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  rateType: {
    bcKey: 'Rate_Type',
    type: 'string',
    required: false,
  },
  unionPremium: {
    bcKey: 'Union_Premium',
    type: 'number',
    required: false,
  },
  headerDescription: {
    bcKey: 'Header_Description',
    type: 'string',
    required: false,
  },
  headerTechnician: {
    bcKey: 'Header_Technician',
    type: 'string',
    required: false,
  },
  originalRoLine: {
    bcKey: 'Original_RO_Line',
    type: 'number',
    required: false,
  },
  originalRoNo: {
    bcKey: 'Original_RO_No',
    type: 'string',
    required: false,
  },
  packageCost: {
    bcKey: 'Package_Cost',
    type: 'number',
    required: false,
  },
  estimatedPackageCost: {
    bcKey: 'Estimated_Package_Cost',
    type: 'number',
    required: false,
  },
  actualPackageCost: {
    bcKey: 'Actual_Package_Cost',
    type: 'number',
    required: false,
  },
  projectContractNo: {
    bcKey: 'Project_Contract_No',
    type: 'string',
    required: false,
  },
  actualBillingPriceHeader: {
    bcKey: 'Actual_Billing_Price_Header',
    type: 'number',
    required: false,
  },
  actualBillingPriceLineRr: {
    bcKey: 'Actual_Billing_Price_Line_RR',
    type: 'number',
    required: false,
  },
  actualBillingPriceHeaderRr: {
    bcKey: 'Actual_Billing_Price_Header_RR',
    type: 'number',
    required: false,
  },
  postedTimeQty: {
    bcKey: 'Posted_Time_Qty',
    type: 'number',
    required: false,
  },
  recordedTimeQty: {
    bcKey: 'Recorded_Time_Qty',
    type: 'number',
    required: false,
  },
  pendingRelease: {
    bcKey: 'Pending_Release',
    type: 'boolean',
    required: false,
  },
  shortcutDimension3Code: {
    bcKey: 'Shortcut_Dimension_3_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension4Code: {
    bcKey: 'Shortcut_Dimension_4_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension5Code: {
    bcKey: 'Shortcut_Dimension_5_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension6Code: {
    bcKey: 'Shortcut_Dimension_6_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension7Code: {
    bcKey: 'Shortcut_Dimension_7_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension8Code: {
    bcKey: 'Shortcut_Dimension_8_Code',
    type: 'string',
    required: false,
  },
  maintType: {
    bcKey: 'Maint_Type',
    type: 'string',
    required: false,
  },
  requestNo: {
    bcKey: 'Request_No',
    type: 'string',
    required: false,
  },
  crewCode: {
    bcKey: 'Crew_Code',
    type: 'string',
    required: false,
  },
  plannedTechnicianCount: {
    bcKey: 'Planned_Technician_Count',
    type: 'number',
    required: false,
  },
  recordedTechnicianCount: {
    bcKey: 'Recorded_Technician_Count',
    type: 'number',
    required: false,
  },
  postedTechnicianCount: {
    bcKey: 'Posted_Technician_Count',
    type: 'number',
    required: false,
  },
  managedByTagProject: {
    bcKey: 'Managed_by_TAG_Project',
    type: 'boolean',
    required: false,
  },
  startingDate: {
    bcKey: 'Starting_Date',
    type: 'string',
    required: false,
  },
  startingTime: {
    bcKey: 'Starting_Time',
    type: 'string',
    required: false,
  },
  endingDate: {
    bcKey: 'Ending_Date',
    type: 'string',
    required: false,
  },
  endingTime: {
    bcKey: 'Ending_Time',
    type: 'string',
    required: false,
  },
  ftcStartTime: {
    bcKey: 'FTC_start_Time',
    type: 'string',
    required: false,
  },
  ftcStartDay: {
    bcKey: 'FTC_start_day',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type WorkOrderLine = ODataObjectMap<typeof WorkOrderLineMap>;

const WorkOrderLineSchema = generateGraphQlSchema(
  'workOrderLine',
  WorkOrderLineMap
);

const WorkOrderLineQuery = generateGraphQlQuery(
  'workOrderLine',
  WorkOrderLineMap
);
export {
  WorkOrderLineSchema,
  type WorkOrderLine,
  WorkOrderLineQuery,
  WorkOrderLineMap,
};
