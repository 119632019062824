import { ODataMap, ODataObjectMap } from '../types';
import { generateFields, generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const EquipmentMap = {
  id: {
    bcKey: 'ID',
    type: 'string',
    required: true,
  },
  sortMaster: {
    bcKey: 'Sort_Master',
    type: 'string',
    required: false,
  },
  masterId: {
    bcKey: 'Master_ID',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  level: {
    bcKey: 'Level',
    type: 'number',
    required: false,
  },
  companyIdNo: {
    bcKey: 'Company_ID_No',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  equipmentSubgroup: {
    bcKey: 'Equipment_Subgroup',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  line: {
    bcKey: 'Line',
    type: 'string',
    required: false,
  },
  lineDescription: {
    bcKey: 'Line_Description',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  main: {
    bcKey: 'Main',
    type: 'string',
    required: false,
  },
  primary: {
    bcKey: 'Primary',
    type: 'string',
    required: false,
  },
  secondary: {
    bcKey: 'Secondary',
    type: 'string',
    required: false,
  },
  modelNumber: {
    bcKey: 'Model_Number',
    type: 'string',
    required: false,
  },
  equipSerialNo: {
    bcKey: 'Equip_Serial_No',
    type: 'string',
    required: false,
  },
  installDate: {
    bcKey: 'Install_Date',
    type: 'string',
    required: false,
  },
  purchasedFromOsp: {
    bcKey: 'Purchased_From_OSP',
    type: 'string',
    required: false,
  },
  purchasedFromVendor: {
    bcKey: 'Purchased_from_Vendor',
    type: 'string',
    required: false,
  },
  dateOfPurchase: {
    bcKey: 'Date_of_Purchase',
    type: 'string',
    required: false,
  },
  warranty: {
    bcKey: 'Warranty',
    type: 'boolean',
    required: false,
  },
  warrantyExpirationDate: {
    bcKey: 'Warranty_Expiration_Date',
    type: 'string',
    required: false,
  },
  manufacturer: {
    bcKey: 'Manufacturer',
    type: 'string',
    required: false,
  },
  dutyType: {
    bcKey: 'Duty_Type',
    type: 'string',
    required: false,
  },
  dutyTypeStatus: {
    bcKey: 'Duty_Type_Status',
    type: 'string',
    required: false,
  },
  pendingApproval: {
    bcKey: 'Pending_Approval',
    type: 'boolean',
    required: false,
  },
  xCoordinates: {
    bcKey: 'X_Coordinates',
    type: 'string',
    required: false,
  },
  yCoordinates: {
    bcKey: 'Y_Coordinates',
    type: 'string',
    required: false,
  },
  zCoordinates: {
    bcKey: 'Z_Coordinates',
    type: 'string',
    required: false,
  },
  lastModified: {
    bcKey: 'Last_Modified',
    type: 'date',
    required: false,
  },
  criticality: {
    bcKey: 'Criticality',
    type: 'string',
    required: false,
  },
  meterType: {
    bcKey: 'Meter_Type',
    type: 'string',
    required: false,
  },
  dateOfCurrentMeter: {
    bcKey: 'Date_of_Current_Meter',
    type: 'string',
    required: false,
  },
  currentMeter: {
    bcKey: 'Current_Meter',
    type: 'number',
    required: false,
  },
  geoFencingDistance: {
    bcKey: 'GeoFencing_Distance',
    type: 'number',
    required: false,
  },
  referenceNo: {
    bcKey: 'Reference_No',
    type: 'string',
    required: false,
  },
  areaDescription: {
    bcKey: 'Area_Description',
    type: 'string',
    required: false,
  },
  enterpriseDescription: {
    bcKey: 'Enterprise_Description',
    type: 'string',
    required: false,
  },
  dateMeterPolicies: {
    bcKey: 'Date_Meter_Policies',
    type: 'number',
    required: false,
  },
  hierarchyParentDescription: {
    bcKey: 'Hierarchy_Parent_Description',
    type: 'string',
    required: false,
  },
  assetIdentifier: {
    bcKey: 'Asset_Identifier',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Equipment = ODataObjectMap<typeof EquipmentMap>;

const EquipmentFields = generateFields(EquipmentMap);

const EquipmentSchema = generateGraphQlSchema('Equipment', EquipmentMap);

const EquipmentQuery = generateGraphQlQuery('Equipment', EquipmentMap);

export { EquipmentSchema, type Equipment, EquipmentQuery, EquipmentMap, EquipmentFields };
