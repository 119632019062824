import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PriorityRankMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  priorityScore: {
    bcKey: 'Priority_Score',
    type: 'number',
    required: false,
  },
  style: {
    bcKey: 'Style',
    type: 'string',
    required: false,
  },
  styleDescription: {
    bcKey: 'Style_Description',
    type: 'string',
    required: false,
  },
  dueByDateOverride: {
    bcKey: 'Due_By_Date_Override',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type PriorityRank = ODataObjectMap<typeof PriorityRankMap>;

const PriorityRankSchema = generateGraphQlSchema(
  'PriorityRank',
  PriorityRankMap
);
const PriorityRankQuery = generateGraphQlQuery('priorityRank', PriorityRankMap);

export {
  PriorityRankSchema,
  type PriorityRank,
  PriorityRankQuery,
  PriorityRankMap,
};
