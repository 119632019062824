//ET-DONE date totalcount
type EmployeeScheduleBreak = {
  auxiliaryIndex1: string;
  auxiliaryIndex2: string;
  breakCode: string;
  calendarCode: string;
  calendarStartDate: Date;
  durationMinutes: number;
  employeeGroupCode: string;
  startBreakTime: string;
  weekNo: number;
  weeklyCalendarCode: string;
  weeklyCandarStartDate: Date;
};

const EmployeeScheduleBreakSchema = `#graphql
  type EmployeeScheduleBreak {
    auxiliaryIndex1: String
    auxiliaryIndex2: String
    breakCode: String
    calendarCode: String
    calendarStartDate: Date
    durationMinutes: Float
    employeeGroupCode: String
    startBreakTime: String
    weekNo: Float
    weeklyCalendarCode: String
    weeklyCandarStartDate: Date
  }
`;

const EmployeeScheduleBreakQuery = `#graphql
  query allEmployeeGroupSchedBreaks($filter: String) {
    employeeGroupSchedBreaks(filter: $filter) {
      items {
        auxiliaryIndex1
        auxiliaryIndex2
        breakCode
        calendarCode
        calendarStartDate
        durationMinutes
        employeeGroupCode
        startBreakTime
        weekNo
        weeklyCalendarCode
        weeklyCandarStartDate
      }
      totalCount
    }
  }
`;

export {
  EmployeeScheduleBreakSchema,
  EmployeeScheduleBreakQuery,
  type EmployeeScheduleBreak,
};
