import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { GeolocationService } from './geolocation.service';
import { switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private geolocation: GeolocationService
  ) {}

  get<T>(
    partial: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          }
        | undefined;
    }
  ) {
    return this.httpClient.get<T>(`${environment.baseWS}${partial}`, options);
  }

  post<T>(partial: string, body?: any) {
    return this.geolocation.getUserCoordinates().pipe(
      switchMap((coordinates) => {
        let headers = new HttpHeaders();
        if (coordinates) {
          headers = headers.set(
            'x-tracing-header',
            `x=${coordinates?.x};y=${coordinates?.y};z=${coordinates?.z}`
          );
        }
        return this.httpClient.post<T>(
          `${environment.baseWS}${partial}`,
          body,
          {
            headers,
          }
        );
      })
    );
  }

  delete<T>(partial: string) {
    return this.httpClient.delete<T>(`${environment.baseWS}${partial}`);
  }
}
