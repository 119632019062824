import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const StatusCodeMap = {
  statusCode: {
    bcKey: 'Status_Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  example: {
    bcKey: 'Example',
    type: 'number',
    required: false,
  },
  styleCode: {
    bcKey: 'Style_Code',
    type: 'string',
    required: false,
  },
  standard: {
    bcKey: 'Standard',
    type: 'string',
    required: false,
  },
  standardAccent: {
    bcKey: 'Standard_Accent',
    type: 'string',
    required: false,
  },
  strong: {
    bcKey: 'Strong',
    type: 'string',
    required: false,
  },
  strongAccent: {
    bcKey: 'Strong_Accent',
    type: 'string',
    required: false,
  },
  attention: {
    bcKey: 'Attention',
    type: 'string',
    required: false,
  },
  attentionAccent: {
    bcKey: 'Attention_Accent',
    type: 'string',
    required: false,
  },
  favorable: {
    bcKey: 'Favorable',
    type: 'string',
    required: false,
  },
  unfavourable: {
    bcKey: 'Unfavourable',
    type: 'string',
    required: false,
  },
  ambiguous: {
    bcKey: 'Ambiguous',
    type: 'string',
    required: false,
  },
  subordinate: {
    bcKey: 'Subordinate',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  postRwo: {
    bcKey: 'Post_RWO',
    type: 'boolean',
    required: false,
  },
  overrideOnComplete: {
    bcKey: 'Override_on_Complete',
    type: 'boolean',
    required: false,
  },
  requestResponse: {
    bcKey: 'Request_Response',
    type: 'string',
    required: false,
  },
  sendNotification: {
    bcKey: 'Send_Notification',
    type: 'boolean',
    required: false,
  },
} as const satisfies ODataMap;
type StatusCode = ODataObjectMap<typeof StatusCodeMap>;
const StatusCodeSchema = generateGraphQlSchema('StatusCode', StatusCodeMap);
const StatusCodeQuery = generateGraphQlQuery('StatusCode', StatusCodeMap);

export { StatusCodeSchema, type StatusCode, StatusCodeQuery, StatusCodeMap };
