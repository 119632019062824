import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const MainCodeMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type MainCode = ODataObjectMap<typeof MainCodeMap>;

const MainCodeSchema = generateGraphQlSchema('MainCode', MainCodeMap);

const MainCodeQuery = generateGraphQlQuery('MainCode', MainCodeMap);

export { MainCodeSchema, MainCodeQuery, type MainCode, MainCodeMap };
