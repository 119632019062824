//ET-DONE date  totalcount
type OvertimeCode = {
  code: string;
  description: string;
  tagUnionNo: string;
  personnelGroup: string;
  tagUnionRateType: string;
  otPaymentOption: string;
  tsTimeEntryType: string;
  multiplier: number;
};

const OvertimeCodeSchema = `#graphql
  type OvertimeCode {
    code: String
    description: String
    tagUnionNo: String
    personnelGroup: String
    tagUnionRateType: String
    otPaymentOption: String
    tsTimeEntryType: String
    multiplier: Float
  }
`;

const OvertimeCodeQuery = `#graphql
query allOvertimeCodes($filter: String) {
  overtimeCodes(filter: $filter) {
    items {
      code
      description
      tagUnionNo
      personnelGroup
      tagUnionRateType
      otPaymentOption
      tsTimeEntryType
      multiplier
    }
    totalCount
  }
}
`;

export { OvertimeCodeSchema, type OvertimeCode, OvertimeCodeQuery };
