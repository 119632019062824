import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const EquipmentSubGroupMap = {
  equipGroupCode: {
    bcKey: 'Equip_Group_Code',
    type: 'string',
    required: false,
  },
  equipSubgroupCode: {
    bcKey: 'Equip_Subgroup_Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type EquipmentSubGroup = ODataObjectMap<typeof EquipmentSubGroupMap>;

const EquipmentSubGroupSchema = generateGraphQlSchema(
  'EquipmentSubGroup',
  EquipmentSubGroupMap
);

const EquipmentSubGroupQuery = generateGraphQlQuery(
  'EquipmentSubGroup',
  EquipmentSubGroupMap
);

export {
  EquipmentSubGroupSchema,
  EquipmentSubGroupQuery,
  type EquipmentSubGroup,
  EquipmentSubGroupMap,
};
