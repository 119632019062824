import { BCODataObjectMap, ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ExpenseMap = {
  batchName: {
    bcKey: 'Batch_Name',
    type: 'string',
    required: true,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: true,
  },
  postingDate: {
    bcKey: 'Posting_Date',
    type: 'string',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  supervisorCode: {
    bcKey: 'Supervisor_Code',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  actionMessage: {
    bcKey: 'Action_Message',
    type: 'string',
    required: false,
  },
  orderDate: {
    bcKey: 'Order_Date',
    type: 'string',
    required: false,
  },
  documentDate: {
    bcKey: 'Document_Date',
    type: 'string',
    required: false,
  },
  documentNo: {
    bcKey: 'Document_No',
    type: 'string',
    required: false,
  },
  entryType: {
    bcKey: 'Entry_Type',
    type: 'string',
    required: false,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  vendorNo: {
    bcKey: 'Vendor_No',
    type: 'string',
    required: false,
  },
  vendorName: {
    bcKey: 'Vendor_Name',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  quantity: {
    bcKey: 'Quantity',
    type: 'number',
    required: false,
  },
  unitAmount: {
    bcKey: 'Unit_Amount',
    type: 'number',
    required: false,
  },
  amount: {
    bcKey: 'Amount',
    type: 'number',
    required: false,
  },
  currencyCode: {
    bcKey: 'Currency_Code',
    type: 'string',
    required: false,
  },
  taxLiable: {
    bcKey: 'Tax_Liable',
    type: 'boolean',
    required: false,
  },
  taxAreaCode: {
    bcKey: 'Tax_Area_Code',
    type: 'string',
    required: false,
  },
  taxGroupCode: {
    bcKey: 'Tax_Group_Code',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  workOrderNo: {
    bcKey: 'Work_Order_No',
    type: 'string',
    required: false,
  },
  workOrderLineNo: {
    bcKey: 'Work_Order_Line_No',
    type: 'number',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  shortcutDimCode3: {
    bcKey: 'ShortcutDimCode3',
    type: 'string',
    required: false,
  },
  shortcutDimCode4: {
    bcKey: 'ShortcutDimCode4',
    type: 'string',
    required: false,
  },
  shortcutDimCode5: {
    bcKey: 'ShortcutDimCode5',
    type: 'string',
    required: false,
  },
  shortcutDimCode6: {
    bcKey: 'ShortcutDimCode6',
    type: 'string',
    required: false,
  },
  shortcutDimCode7: {
    bcKey: 'ShortcutDimCode7',
    type: 'string',
    required: false,
  },
  shortcutDimCode8: {
    bcKey: 'ShortcutDimCode8',
    type: 'string',
    required: false,
  },
  numberOfJournalRecords: {
    bcKey: 'NumberOfJournalRecords',
    type: 'number',
    required: false,
  },
  totalAmount: {
    bcKey: 'TotalAmount',
    type: 'number',
    required: false,
  },
} as const satisfies ODataMap;

type Expense = ODataObjectMap<typeof ExpenseMap>;
type BCExpense = BCODataObjectMap<typeof ExpenseMap>;

const ExpenseSchema = generateGraphQlSchema('Expense', ExpenseMap);

const ExpenseQuery = generateGraphQlQuery('Expense', ExpenseMap);

export {
  ExpenseSchema,
  ExpenseQuery,
  type Expense,
  type BCExpense,
  ExpenseMap,
};
