// ET-DONE date parameter
import { Form } from './form';

type FormData = {
  approved: boolean;
  createdAt: string;
  data: any;
  documentId: string;
  form: Form;
  id: string;
  metadata: any;
  schema: any;
  type: string;
  status: 'approved' | 'rejected' | 'draft' | 'pending'
  updatedAt: string;
  createdById: string;
};

const FormDataSchema = `#graphql
  type Form {
    createdAt: String
    createdById: String
    customerId: String
    description: String
    id: Float
    name: String
    type: String
    updatedAt: String
  }

  type FormData {
    approved: Boolean
    createdAt: String
    data: String
    documentId: String
    form: Form
    id: Float
    metadata: String
    schema: String
    type: String
    updatedAt: String
  }
`;

const FormDataQuery = `#graphql
query allFormsData(
  $documentId: String
  $filter: String
  $top: Int
  $skip: Int
  $orderBy: String
  $desc: Boolean) {
  formsData(
    documentId: $documentId
    filter: $filter
    top: $top
    skip: $skip
    orderBy: $orderBy
    desc: $desc) {
    approved
    createdAt
    data
    documentId
    form {
      createdAt
      createdById
      customerId
      description
      id
      name
      type
      updatedAt
    }
    id
    metadata
    schema
    type
    updatedAt
  }
}
`;

export { FormDataSchema, type FormData, FormDataQuery };
