//ET-DONE date totalcount
type TimesheetJournal = {
  employeeNo: string;
  description: string;
  quantity: number;
  timeEntryType: string;
  timeEntryTypeCode: string;
  timeSheetHeaderNo: string;
  employeeAbsenceNo: string;
  startingDateTime: Date;
  startingDate: string;
  endingDateTime: Date;
  vacationPeriodFrom: Date;
  vacationPeriodTo: Date;
  tagPersonnelNo: string;
  tagUnionNo: string;
  tagUnionRateType: string;
  jobNo: string;
  jobTaskNo: string;
  tagWorkOrderNo: string;
  tagWorkOrderLineNo: string;
  postingDate: Date;
};

const TimesheetJournalSchema = `#graphql
  type TimesheetJournal {
    employeeNo: String
    description: String
    quantity: Float
    timeEntryType: String
    timeEntryTypeCode: String
    timeSheetHeaderNo: String
    employeeAbsenceNo: String
    startingDateTime: Date
    startingDate: String
    endingDateTime: Date
    vacationPeriodFrom: Date
    vacationPeriodTo: Date
    tagPersonnelNo: String
    tagUnionNo: String
    tagUnionRateType: String
    jobNo: String
    jobTaskNo: String
    tagWorkOrderNo: String
    tagWorkOrderLineNo: String
    postingDate: Date
  }
`;

const TimesheetJournalQuery = `#graphql
query allTimesheetJournals($filter: String) {
  timesheetJournals(filter: $filter) {
    items {
      employeeNo
      description
      quantity
      timeEntryType
      timeEntryTypeCode
      timeSheetHeaderNo
      employeeAbsenceNo
      startingDateTime
      startingDate
      endingDateTime
      vacationPeriodFrom
      vacationPeriodTo
      tagPersonnelNo
      tagUnionNo
      tagUnionRateType
      jobNo
      jobTaskNo
      tagWorkOrderNo
      tagWorkOrderLineNo
      postingDate
    }
    totalCount
  }
}
`;

export { TimesheetJournalSchema, TimesheetJournalQuery, type TimesheetJournal };
