import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PurchaseCommentMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  documentLineNo: {
    bcKey: 'Document_Line_No',
    type: 'number',
    required: false,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: true,
  },
  date: {
    bcKey: 'Date',
    type: 'date',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'string',
    required: false,
  },
  webServiceUser: {
    bcKey: 'Web_Service_User',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type PurchaseComment = ODataObjectMap<typeof PurchaseCommentMap>;

const PurchaseCommentSchema = generateGraphQlSchema(
  'PurchaseComment',
  PurchaseCommentMap
);

const PurchaseCommentQuery = generateGraphQlQuery(
  'purchaseComment',
  PurchaseCommentMap
);

export {
  PurchaseCommentSchema,
  type PurchaseComment,
  PurchaseCommentQuery,
  PurchaseCommentMap,
};
