import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PrimaryCodeMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type PrimaryCode = ODataObjectMap<typeof PrimaryCodeMap>;

const PrimaryCodeSchema = generateGraphQlSchema('PrimaryCode', PrimaryCodeMap);

const PrimaryCodeQuery = generateGraphQlQuery('primaryCode', PrimaryCodeMap);

export {
  PrimaryCodeSchema,
  PrimaryCodeQuery,
  type PrimaryCode,
  PrimaryCodeMap,
};
