//ET-DONE date totalcount
type CrewSchedule = {
  crewCode: string;
  endDatetime: Date;
  entryNo: number;
  personnelType: string;
  personnel: string;
  startDatetime: Date;
};

const CrewScheduleSchema = `#graphql
  type CrewSchedule {
    crewCode: String
    endDatetime: Date
    entryNo: String
    personnelType: String
    personnel: String
    startDatetime: Date
  }
`;

const CrewScheduleQuery = `#graphql
  query allCrewSchedule($filter: String) {
    crewSchedule(filter: $filter) {
      items {
        crewCode
        endDatetime
        entryNo
        personnel
        personnelType
        startDatetime
      }
      totalCount
    }
  }
`;

export { CrewScheduleSchema, CrewScheduleQuery, type CrewSchedule };
