import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const MaintenanceTypeMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  warrantyCustomerNo: {
    bcKey: 'Warranty_Customer_No',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type MaintenanceType = ODataObjectMap<typeof MaintenanceTypeMap>;

const MaintenanceTypeSchema = generateGraphQlSchema(
  'MaintenanceType',
  MaintenanceTypeMap
);
const MaintenanceTypeQuery = generateGraphQlQuery(
  'MaintenanceType',
  MaintenanceTypeMap
);

export {
  MaintenanceTypeSchema,
  MaintenanceTypeQuery,
  type MaintenanceType,
  MaintenanceTypeMap,
};
