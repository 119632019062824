import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const WorkProcedureMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  masterCard: {
    bcKey: 'Master_Card',
    type: 'boolean',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  standardTime: {
    bcKey: 'Standard_Time',
    type: 'number',
    required: false,
  },
  estimatedStepTime: {
    bcKey: 'Estimated_Step_Time',
    type: 'number',
    required: false,
  },
  subStepsExist: {
    bcKey: 'Sub_Steps_Exist',
    type: 'boolean',
    required: false,
  },
  expandSubstepsToWoLine: {
    bcKey: 'Expand_Substeps_to_WO_Line',
    type: 'boolean',
    required: false,
  },
  inputType: {
    bcKey: 'Input_Type',
    type: 'string',
    required: false,
  },
  testDescription: {
    bcKey: 'Test_Description',
    type: 'string',
    required: false,
  },
  noPriorResultsToPrint: {
    bcKey: 'No_Prior_Results_to_Print',
    type: 'number',
    required: false,
  },
  resultsInputRequired: {
    bcKey: 'Results_Input_Required',
    type: 'boolean',
    required: false,
  },
  validationRequired: {
    bcKey: 'Validation_Required',
    type: 'boolean',
    required: false,
  },
  defaultUnitOfMeasure: {
    bcKey: 'Default_Unit_of_Measure',
    type: 'string',
    required: false,
  },
  resultsDateTimeOption: {
    bcKey: 'Results_Date_Time_Option',
    type: 'string',
    required: false,
  },
  numericLessThan: {
    bcKey: 'Numeric_Less_Than',
    type: 'string',
    required: false,
  },
  numericAcceptableLow: {
    bcKey: 'Numeric_Acceptable_Low',
    type: 'number',
    required: false,
  },
  numericAcceptableHigh: {
    bcKey: 'Numeric_Acceptable_High',
    type: 'number',
    required: false,
  },
  validation1Low: {
    bcKey: 'Validation_1_Low',
    type: 'number',
    required: false,
  },
  validation1High: {
    bcKey: 'Validation_1_High',
    type: 'number',
    required: false,
  },
  validation1Message: {
    bcKey: 'Validation_1_Message',
    type: 'string',
    required: false,
  },
  validation2Low: {
    bcKey: 'Validation_2_Low',
    type: 'number',
    required: false,
  },
  validation2High: {
    bcKey: 'Validation_2_High',
    type: 'number',
    required: false,
  },
  validation2Message: {
    bcKey: 'Validation_2_Message',
    type: 'string',
    required: false,
  },
  specificValueRequired: {
    bcKey: 'Specific_Value_Required',
    type: 'string',
    required: false,
  },
  binaryPositiveValue: {
    bcKey: 'Binary_Positive_Value',
    type: 'string',
    required: false,
  },
  binaryNegativeValue: {
    bcKey: 'Binary_Negative_Value',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type WorkProcedure = ODataObjectMap<typeof WorkProcedureMap>;

const WorkProcedureSchema = generateGraphQlSchema(
  'WorkProcedure',
  WorkProcedureMap
);

const WorkProcedureQuery = generateGraphQlQuery(
  'WorkProcedure',
  WorkProcedureMap
);

export {
  WorkProcedureSchema,
  type WorkProcedure,
  WorkProcedureQuery,
  WorkProcedureMap,
};
