import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const JobTaskMap = {
  jobNo: {
    bcKey: 'JobNo',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'JobTaskNo',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  parentTaskNo: {
    bcKey: 'ParentTaskNo',
    type: 'string',
    required: false,
  },
  jobTaskType: {
    bcKey: 'JobTaskType',
    type: 'string',
    required: false,
  },
  startDate: {
    bcKey: 'StartDate',
    type: 'date',
    required: false,
  },
  endDate: {
    bcKey: 'EndDate',
    type: 'date',
    required: false,
  },
  contractTotalPrice: {
    bcKey: 'ContractTotalPrice',
    type: 'number',
    required: false,
  },
  expanded: {
    bcKey: 'Expanded',
    type: 'boolean',
    required: false,
  },
  overbookable: {
    bcKey: 'Overbookable',
    type: 'boolean',
    required: false,
  },
  managedByTagproject: {
    bcKey: 'ManagedByTAGProject',
    type: 'boolean',
    required: false,
  },
  percentDone: {
    bcKey: 'PercentDone',
    type: 'number',
    required: false,
  },
  duration: {
    bcKey: 'Duration',
    type: 'number',
    required: false,
  },
  rollup: {
    bcKey: 'Rollup',
    type: 'boolean',
    required: false,
  },
  parentIndex: {
    bcKey: 'ParentIndex',
    type: 'number',
    required: false,
  },
  taskPredecessor: {
    bcKey: 'TaskPredecessor',
    type: 'string',
    required: false,
  },
  tagdirection: {
    bcKey: 'TAGDirection',
    type: 'string',
    required: false,
  },
  tageffort: {
    bcKey: 'TAGEffort',
    type: 'number',
    required: false,
  },
  tageffortUnit: {
    bcKey: 'TAGEffortUnit',
    type: 'string',
    required: false,
  },
  tageffortDriven: {
    bcKey: 'TAGEffortDriven',
    type: 'boolean',
    required: false,
  },
  tagschedulingMode: {
    bcKey: 'TAGSchedulingMode',
    type: 'string',
    required: false,
  },
  tagtaskPosition: {
    bcKey: 'TAGTaskPosition',
    type: 'number',
    required: false,
  },
  tagrequestId: {
    bcKey: 'TAGRequestID',
    type: 'string',
    required: false,
  },
  tagtemporaryId: {
    bcKey: 'TAGTemporaryID',
    type: 'string',
    required: false,
  },
  tagjobTaskTempNo: {
    bcKey: 'TAGJobTaskTempNo',
    type: 'string',
    required: false,
  },
  planningDateFilter: {
    bcKey: 'Planning_Date_Filter',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type JobTaskLine = ODataObjectMap<typeof JobTaskMap>;

const JobTaskLineSchema = generateGraphQlSchema('JobTaskLine', JobTaskMap);

const JobTaskLineQuery = generateGraphQlQuery('JobTaskLine', JobTaskMap);

export { JobTaskLineSchema, type JobTaskLine, JobTaskLineQuery, JobTaskMap };
