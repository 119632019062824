import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const DateMeterPolicyMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  dateMeterType: {
    bcKey: 'Date_Meter_Type',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  workOrderDescription: {
    bcKey: 'Work_Order_Description',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  referenceNo: {
    bcKey: 'Reference_No',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  facilityDescription: {
    bcKey: 'Facility_Description',
    type: 'string',
    required: false,
  },
  equipSerialNo: {
    bcKey: 'Equip_Serial_No',
    type: 'string',
    required: false,
  },
  facilityContact: {
    bcKey: 'Facility_Contact',
    type: 'string',
    required: false,
  },
  creationDate: {
    bcKey: 'Creation_Date',
    type: 'string',
    required: false,
  },
  lastModifiedDate: {
    bcKey: 'Last_Modified_Date',
    type: 'string',
    required: false,
  },
  lastModifiedBy: {
    bcKey: 'Last_Modified_By',
    type: 'string',
    required: false,
  },
  equipmentLevel: {
    bcKey: 'Equipment_Level',
    type: 'number',
    required: false,
  },
  xCoordinates: {
    bcKey: 'X_Coordinates',
    type: 'string',
    required: false,
  },
  yCoordinates: {
    bcKey: 'Y_Coordinates',
    type: 'string',
    required: false,
  },
  zCoordinates: {
    bcKey: 'Z_Coordinates',
    type: 'string',
    required: false,
  },
  excludeFromEvaluation: {
    bcKey: 'Exclude_from_Evaluation',
    type: 'boolean',
    required: false,
  },
  policyGroupMember: {
    bcKey: 'Policy_Group_Member',
    type: 'boolean',
    required: false,
  },
  ospNo: {
    bcKey: 'OSP_No',
    type: 'string',
    required: false,
  },
  reasonCode: {
    bcKey: 'Reason_Code',
    type: 'string',
    required: false,
  },
  capitalExpenditure: {
    bcKey: 'Capital_Expenditure',
    type: 'boolean',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  templateNo: {
    bcKey: 'Template_No',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  line: {
    bcKey: 'Line',
    type: 'string',
    required: false,
  },
  noSeries: {
    bcKey: 'No_Series',
    type: 'string',
    required: false,
  },
  dateOccurrenceInterval: {
    bcKey: 'Date_Occurrence_Interval',
    type: 'string',
    required: false,
  },
  nextServiceDate: {
    bcKey: 'Next_Service_Date',
    type: 'string',
    required: false,
  },
  supervisorCode: {
    bcKey: 'Supervisor_Code',
    type: 'string',
    required: false,
  },
  activateDate: {
    bcKey: 'Activate_Date',
    type: 'boolean',
    required: false,
  },
  meterOccurrenceInterval: {
    bcKey: 'Meter_Occurrence_Interval',
    type: 'number',
    required: false,
  },
  nextMeterService: {
    bcKey: 'Next_Meter_Service',
    type: 'number',
    required: false,
  },
  activateMeter: {
    bcKey: 'Activate_Meter',
    type: 'boolean',
    required: false,
  },
  likelyDueDate: {
    bcKey: 'Likely_Due_Date',
    type: 'string',
    required: false,
  },
  overrideAveragePerDay: {
    bcKey: 'Override_Average_Per_Day',
    type: 'number',
    required: false,
  },
  requirements: {
    bcKey: 'Requirements',
    type: 'number',
    required: false,
  },
  attachments: {
    bcKey: 'Attachments',
    type: 'number',
    required: false,
  },
  qualifications: {
    bcKey: 'Qualifications',
    type: 'number',
    required: false,
  },
  existsInWorksheet: {
    bcKey: 'Exists_in_Worksheet',
    type: 'boolean',
    required: false,
  },
  plannedWorkOrders: {
    bcKey: 'Planned_Work_Orders',
    type: 'number',
    required: false,
  },
  releasedWorkOrders: {
    bcKey: 'Released_Work_Orders',
    type: 'number',
    required: false,
  },
  finishedWorkOrders: {
    bcKey: 'Finished_Work_Orders',
    type: 'number',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  releasedOrderOlderThanDays: {
    bcKey: 'Released_Order_Older_Than_Days',
    type: 'number',
    required: false,
  },
  plannedOrderOlderThanDays: {
    bcKey: 'Planned_Order_Older_Than_Days',
    type: 'number',
    required: false,
  },
  earliestWorkOrder: {
    bcKey: 'Earliest_Work_Order',
    type: 'string',
    required: false,
  },
  latestWorkOrder: {
    bcKey: 'Latest_Work_Order',
    type: 'string',
    required: false,
  },
  blocked: {
    bcKey: 'Blocked',
    type: 'boolean',
    required: false,
  },
  openWorkOrders: {
    bcKey: 'Open_Work_Orders',
    type: 'number',
    required: false,
  },
  deletedWorkOrders: {
    bcKey: 'Deleted_Work_Orders',
    type: 'number',
    required: false,
  },
  completedWorkOrders: {
    bcKey: 'Completed_Work_Orders',
    type: 'number',
    required: false,
  },
  dueByLeadTime: {
    bcKey: 'Due_By_Lead_time',
    type: 'string',
    required: false,
  },
  expireLeadTime: {
    bcKey: 'Expire_Lead_Time',
    type: 'string',
    required: false,
  },
  startLeadTime: {
    bcKey: 'Start_Lead_Time',
    type: 'string',
    required: false,
  },
  rsdLeadTime: {
    bcKey: 'RSD_Lead_Time',
    type: 'string',
    required: false,
  },
  useNextServiceDate: {
    bcKey: 'Use_Next_Service_Date',
    type: 'boolean',
    required: false,
  },
  enterpriseDescription: {
    bcKey: 'Enterprise_Description',
    type: 'string',
    required: false,
  },
  regionDescription: {
    bcKey: 'Region_Description',
    type: 'string',
    required: false,
  },
  areaDescription: {
    bcKey: 'Area_Description',
    type: 'string',
    required: false,
  },
  lineDescription: {
    bcKey: 'Line_Description',
    type: 'string',
    required: false,
  },
  dimensionSetId: {
    bcKey: 'Dimension_Set_ID',
    type: 'number',
    required: false,
  },
  meterFixedRecurrence: {
    bcKey: 'Meter_Fixed_Recurrence',
    type: 'boolean',
    required: false,
  },
  useSpecificInterval: {
    bcKey: 'Use_Specific_Interval',
    type: 'boolean',
    required: false,
  },
  blockOnIntervalCompletion: {
    bcKey: 'Block_On_Interval_Completion',
    type: 'boolean',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  masterEquipId: {
    bcKey: 'Master_Equip_ID',
    type: 'string',
    required: false,
  },
  fixedRecurrence: {
    bcKey: 'Fixed_Recurrence',
    type: 'boolean',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  problemCode: {
    bcKey: 'Problem_Code',
    type: 'string',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  maintType: {
    bcKey: 'Maint_Type',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  lastServiceDate: {
    bcKey: 'Last_Service_Date',
    type: 'string',
    required: false,
  },
  lastServiceTime: {
    bcKey: 'Last_Service_Time',
    type: 'string',
    required: false,
  },
  meterLastAction: {
    bcKey: 'Meter_Last_Action',
    type: 'number',
    required: false,
  },
  currentMeter: {
    bcKey: 'Current_Meter',
    type: 'number',
    required: false,
  },
  currentMeterDate: {
    bcKey: 'Current_Meter_Date',
    type: 'string',
    required: false,
  },
  lastMeterDate: {
    bcKey: 'Last_Meter_Date',
    type: 'string',
    required: false,
  },
  meterType: {
    bcKey: 'Meter_Type',
    type: 'string',
    required: false,
  },
  takeOos: {
    bcKey: 'Take_OOS',
    type: 'boolean',
    required: false,
  },
  oosDays: {
    bcKey: 'OOS_Days',
    type: 'number',
    required: false,
  },
  oosHours: {
    bcKey: 'OOS_Hours',
    type: 'number',
    required: false,
  },
  oosMinutes: {
    bcKey: 'OOS_Minutes',
    type: 'number',
    required: false,
  },
  useStepTime: {
    bcKey: 'Use_Step_Time',
    type: 'boolean',
    required: false,
  },
  stepDays: {
    bcKey: 'Step_Days',
    type: 'number',
    required: false,
  },
  stepHours: {
    bcKey: 'Step_Hours',
    type: 'number',
    required: false,
  },
  stepMinutes: {
    bcKey: 'Step_Minutes',
    type: 'number',
    required: false,
  },
  totalStepTime: {
    bcKey: 'Total_Step_Time',
    type: 'number',
    required: false,
  },
  personnelCostEstimated: {
    bcKey: 'Personnel_Cost_Estimated',
    type: 'number',
    required: false,
  },
  itemCostEstimated: {
    bcKey: 'Item_Cost_Estimated',
    type: 'number',
    required: false,
  },
  toolCostEstimated: {
    bcKey: 'Tool_Cost_Estimated',
    type: 'number',
    required: false,
  },
  vendorCostEstimated: {
    bcKey: 'Vendor_Cost_Estimated',
    type: 'number',
    required: false,
  },
  otherCostEstimated: {
    bcKey: 'Other_Cost_Estimated',
    type: 'number',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
  equipmentDescription2: {
    bcKey: 'Equipment_Description_2',
    type: 'string',
    required: false,
  },
  companyIdNo: {
    bcKey: 'Company_ID_No',
    type: 'string',
    required: false,
  },
  standardTime: {
    bcKey: 'Standard_Time',
    type: 'number',
    required: false,
  },
  estimatedTime: {
    bcKey: 'Estimated_Time',
    type: 'number',
    required: false,
  },
  equipReferenceNo: {
    bcKey: 'Equip_Reference_No',
    type: 'string',
    required: false,
  },
  equipDutyType: {
    bcKey: 'Equip_Duty_Type',
    type: 'string',
    required: false,
  },
  equipDutyTypeStatus: {
    bcKey: 'Equip_Duty_Type_Status',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  equipFixedAssetNo: {
    bcKey: 'Equip_Fixed_Asset_No',
    type: 'string',
    required: false,
  },
  equipManufacturer: {
    bcKey: 'Equip_Manufacturer',
    type: 'string',
    required: false,
  },
  equipSortSequence: {
    bcKey: 'Equip_Sort_Sequence',
    type: 'string',
    required: false,
  },
  newNo: {
    bcKey: 'New_No',
    type: 'string',
    required: false,
  },
  equipTechnicianCode: {
    bcKey: 'Equip_Technician_Code',
    type: 'string',
    required: false,
  },
  equipMain: {
    bcKey: 'Equip_Main',
    type: 'string',
    required: false,
  },
  equipPrimary: {
    bcKey: 'Equip_Primary',
    type: 'string',
    required: false,
  },
  equipSecondary: {
    bcKey: 'Equip_Secondary',
    type: 'string',
    required: false,
  },
  equipmentSubgroup: {
    bcKey: 'Equipment_Subgroup',
    type: 'string',
    required: false,
  },
  autoPlanPmErrorText: {
    bcKey: 'AutoPlan_PM_Error_Text',
    type: 'string',
    required: false,
  },
  autoplanPmErrorDatetime: {
    bcKey: 'Autoplan_PM_Error_Datetime',
    type: 'string',
    required: false,
  },
  priorityRank: {
    bcKey: 'Priority_Rank',
    type: 'string',
    required: false,
  },
  dateFilter: {
    bcKey: 'Date_Filter',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;
type DateMeterPolicy = ODataObjectMap<typeof DateMeterPolicyMap>;

const DateMeterPolicySchema = generateGraphQlSchema(
  'DateMeterPolicie',
  DateMeterPolicyMap
);

const DateMeterPolicyQuery = generateGraphQlQuery(
  'DateMeterPolicie',
  DateMeterPolicyMap
);

export {
  DateMeterPolicySchema,
  DateMeterPolicyQuery,
  type DateMeterPolicy,
  DateMeterPolicyMap,
};
