import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TranslocoModule,
  translocoConfig,
} from '@ngneat/transloco';
import { Observable, catchError, map } from 'rxjs';

import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { AuthState } from '@stores-states/authentication.state';
import { ApiService } from '@services/api.service';

// Temporary until we have a better solution
export const CustomTranslations = {
  areaKey: 'areaCaption',
  detailKey: 'detailCaption',
  lineKey: 'detailCaption',
  primaryCodeKey: 'primaryCodeCaption',
  mainCodeKey: 'mainCodeCaption',
  secondaryCodeKey: 'secondaryCodeCaption',
};

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    private store: Store,
    private api: ApiService
  ) {}

  getTranslation = (lang: string): Observable<Translation> => {
    const setup = this.store.selectSnapshot(AuthState.setup);
    const customCaption = this.store.selectSnapshot(AuthState.captions)?.[lang];

    const resParser = (res: Translation) => {
      if (!setup) return res;

      Object.keys(res).forEach((key: string) => {
        const cs: any = CustomTranslations;
        if (!!cs[key]) {
          res[key] = (setup as any)[cs[key]] || res[key];
        }
      });

      return res;
    };

    if (customCaption) {
      return this.api
        .get<Translation>(customCaption, {
          headers: {
            'skip-error-handler': '1',
          },
        })
        .pipe(
          catchError(() =>
            this.http
              .get<Translation>(`/assets/i18n/${lang}.json`)
              .pipe(map(resParser))
          ),
          map(resParser)
        );
    }

    return this.http
      .get<Translation>(`/assets/i18n/${lang}.json`)
      .pipe(map(resParser));
  };
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          'en',
          'fr',
          'es',
          'zh',
          'de',
          'ar',
          'ru',
          'fi',
          'da',
          'sv',
          'pt',
        ],
        defaultLang: 'en',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
