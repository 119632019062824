import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const WorkCodeMap = {
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type WorkCode = ODataObjectMap<typeof WorkCodeMap>;

const WorkCodeSchema = generateGraphQlSchema('WorkCode', WorkCodeMap);

const WorkCodeQuery = generateGraphQlQuery('WorkCode', WorkCodeMap);

export { WorkCodeSchema, type WorkCode, WorkCodeQuery, WorkCodeMap };
