//ET-DONE date totalcount parameter
type FormStatus = {
  id: number;
  name: string;
  sortSequence: number;
};

const FormStatusSchema = `#graphql
  type FormStatus {
    id: Float
    name: String
    sortSequence: Float
  }
`;

const FormStatusQuery = `#graphql
query allFormStatuses {
  formStatuses {
    items {
      id
      name
      sortSequence
    }
    totalCount
  }
}
`;

export { FormStatusSchema, type FormStatus, FormStatusQuery };
