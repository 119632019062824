import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const LocationMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Location = ODataObjectMap<typeof LocationMap>;

const LocationSchema = generateGraphQlSchema('Location', LocationMap);

const LocationQuery = generateGraphQlQuery('Location', LocationMap);

export { LocationSchema, type Location, LocationQuery, LocationMap };
