import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const OutsideServiceProviderMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  county: {
    bcKey: 'County',
    type: 'string',
    required: false,
  },
  postCode: {
    bcKey: 'Post_Code',
    type: 'string',
    required: false,
  },
  phoneNo: {
    bcKey: 'Phone_No',
    type: 'string',
    required: false,
  },
  faxNo: {
    bcKey: 'Fax_No',
    type: 'string',
    required: false,
  },
  ourAccountNo: {
    bcKey: 'Our_Account_No',
    type: 'string',
    required: false,
  },
  eMail: {
    bcKey: 'E_Mail',
    type: 'string',
    required: false,
  },
  homePage: {
    bcKey: 'Home_Page',
    type: 'string',
    required: false,
  },
  searchName: {
    bcKey: 'Search_Name',
    type: 'string',
    required: false,
  },
  navVendorNo: {
    bcKey: 'NAV_Vendor_No',
    type: 'string',
    required: false,
  },
  chargeType: {
    bcKey: 'Charge_Type',
    type: 'string',
    required: false,
  },
  hourlyRate: {
    bcKey: 'Hourly_Rate',
    type: 'number',
    required: false,
  },
  flatRateCharge: {
    bcKey: 'Flat_Rate_Charge',
    type: 'number',
    required: false,
  },
  blocked: {
    bcKey: 'Blocked',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type OutsideServiceProvider = ODataObjectMap<typeof OutsideServiceProviderMap>;

const OutsideServiceProviderSchema = generateGraphQlSchema(
  'outsideServiceProvider',
  OutsideServiceProviderMap
);

const OutsideServiceProviderQuery = generateGraphQlQuery(
  'outsideServiceProvider',
  OutsideServiceProviderMap
);

export {
  OutsideServiceProviderQuery,
  OutsideServiceProviderSchema,
  type OutsideServiceProvider,
  OutsideServiceProviderMap,
};
