import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const RequestMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  requestType: {
    bcKey: 'Request_Type',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
  noSeries: {
    bcKey: 'No_Series',
    type: 'string',
    required: false,
  },
  wrNo: {
    bcKey: 'WR_No',
    type: 'string',
    required: false,
  },
  creationTime: {
    bcKey: 'Creation_Time',
    type: 'string',
    required: false,
  },
  equipmentSubgroup: {
    bcKey: 'Equipment_Subgroup',
    type: 'string',
    required: false,
  },
  lastViewedBy: {
    bcKey: 'Last_Viewed_By',
    type: 'string',
    required: false,
  },
  lastPrintedBy: {
    bcKey: 'Last_Printed_By',
    type: 'string',
    required: false,
  },
  equipPlannedOrders: {
    bcKey: 'Equip_Planned_Orders',
    type: 'number',
    required: false,
  },
  equipReleasedOrders: {
    bcKey: 'Equip_Released_Orders',
    type: 'number',
    required: false,
  },
  descLines: {
    bcKey: 'Desc_Lines',
    type: 'number',
    required: false,
  },
  typeFilter: {
    bcKey: 'Type_Filter',
    type: 'string',
    required: false,
  },
  dateFilter: {
    bcKey: 'Date_Filter',
    type: 'string',
    required: false,
  },
  userFilter: {
    bcKey: 'User_Filter',
    type: 'string',
    required: false,
  },
  regionFilter: {
    bcKey: 'Region_Filter',
    type: 'string',
    required: false,
  },
  facilityFilter: {
    bcKey: 'Facility_Filter',
    type: 'string',
    required: false,
  },
  areaFilter: {
    bcKey: 'Area_Filter',
    type: 'string',
    required: false,
  },
  lineFilter: {
    bcKey: 'Line_Filter',
    type: 'string',
    required: false,
  },
  masterId: {
    bcKey: 'Master_ID',
    type: 'string',
    required: false,
  },
  sortMaster: {
    bcKey: 'Sort_Master',
    type: 'string',
    required: false,
  },
  levelParentDescription: {
    bcKey: 'Level_Parent_Description',
    type: 'string',
    required: false,
  },
  hierarchyParentDescription: {
    bcKey: 'Hierarchy_Parent_Description',
    type: 'string',
    required: false,
  },
  enterpriseDescription: {
    bcKey: 'Enterprise_Description',
    type: 'string',
    required: false,
  },
  facilityDescription: {
    bcKey: 'Facility_Description',
    type: 'string',
    required: false,
  },
  regionDescription: {
    bcKey: 'Region_Description',
    type: 'string',
    required: false,
  },
  areaDescription: {
    bcKey: 'Area_Description',
    type: 'string',
    required: false,
  },
  technicianFilter: {
    bcKey: 'Technician_Filter',
    type: 'string',
    required: false,
  },
  statusFilter: {
    bcKey: 'Status_Filter',
    type: 'string',
    required: false,
  },
  lockedFilter: {
    bcKey: 'Locked_Filter',
    type: 'string',
    required: false,
  },
  uniqueRequest: {
    bcKey: 'Unique_Request',
    type: 'boolean',
    required: false,
  },
  durationSinceCreateHrs: {
    bcKey: 'Duration_Since_Create_hrs',
    type: 'number',
    required: false,
  },
  technicianFirstAssigned: {
    bcKey: 'Technician_First_Assigned',
    type: 'date',
    required: false,
  },
  firstTechAssignedDuration: {
    bcKey: 'First_Tech_Assigned_Duration',
    type: 'number',
    required: false,
  },
  completedDateTime: {
    bcKey: 'Completed_Date_Time',
    type: 'date',
    required: false,
  },
  technicianLastAssigned: {
    bcKey: 'Technician_Last_Assigned',
    type: 'date',
    required: false,
  },
  lastTechAssignedDuration: {
    bcKey: 'Last_Tech_Assigned_Duration',
    type: 'number',
    required: false,
  },
  escalationLevel: {
    bcKey: 'Escalation_Level',
    type: 'number',
    required: false,
  },
  sendNotification: {
    bcKey: 'Send_Notification',
    type: 'boolean',
    required: false,
  },
  escalationChangeDatetime: {
    bcKey: 'Escalation_Change_Datetime',
    type: 'date',
    required: false,
  },
  escalationMessage: {
    bcKey: 'Escalation_Message',
    type: 'string',
    required: false,
  },
  requirements: {
    bcKey: 'Requirements',
    type: 'number',
    required: false,
  },
  tally: {
    bcKey: 'Tally',
    type: 'number',
    required: false,
  },
  modifiedBy: {
    bcKey: 'Modified_By',
    type: 'string',
    required: false,
  },
  modifiedDate: {
    bcKey: 'Modified_Date',
    type: 'date',
    required: false,
  },
  linkedRequests: {
    bcKey: 'Linked_Requests',
    type: 'number',
    required: false,
  },
  oldDocumentTrackingNo: {
    bcKey: 'Old_Document_Tracking_No',
    type: 'string',
    required: false,
  },
  tagWebRecordLink: {
    bcKey: 'TAG_Web_Record_Link',
    type: 'string',
    required: false,
  },
  tagAndroidRecordLink: {
    bcKey: 'TAG_Android_Record_Link',
    type: 'string',
    required: false,
  },
  lastRepeatRun: {
    bcKey: 'Last_Repeat_Run',
    type: 'date',
    required: false,
  },
  equipMainDescription: {
    bcKey: 'Equip_Main_Description',
    type: 'string',
    required: false,
  },
  equipPriDescription: {
    bcKey: 'Equip_Pri_Description',
    type: 'string',
    required: false,
  },
  equipSecDescription: {
    bcKey: 'Equip_Sec_Description',
    type: 'string',
    required: false,
  },
  equipMainFilter: {
    bcKey: 'Equip_Main_Filter',
    type: 'string',
    required: false,
  },
  equipPrimaryFilter: {
    bcKey: 'Equip_Primary_Filter',
    type: 'string',
    required: false,
  },
  equipSecondaryFilter: {
    bcKey: 'Equip_Secondary_Filter',
    type: 'string',
    required: false,
  },
  priorityRank: {
    bcKey: 'Priority_Rank',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  line: {
    bcKey: 'Line',
    type: 'string',
    required: false,
  },
  equipMain: {
    bcKey: 'Equip_Main',
    type: 'string',
    required: false,
  },
  equipPrimary: {
    bcKey: 'Equip_Primary',
    type: 'string',
    required: false,
  },
  equipSecondary: {
    bcKey: 'Equip_Secondary',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  companyId: {
    bcKey: 'Company_ID',
    type: 'string',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
  workOrderType: {
    bcKey: 'Work_Order_Type',
    type: 'string',
    required: false,
  },
  workOrderNo: {
    bcKey: 'Work_Order_No',
    type: 'string',
    required: false,
  },
  documentTrackingNo: {
    bcKey: 'Document_Tracking_No',
    type: 'string',
    required: false,
  },
  workOrderServiceDate: {
    bcKey: 'Work_Order_Service_Date',
    type: 'date',
    required: false,
  },
  assignedTechnician: {
    bcKey: 'Assigned_Technician',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  creationDate: {
    bcKey: 'Creation_Date',
    type: 'date',
    required: false,
  },
  locked: {
    bcKey: 'Locked',
    type: 'boolean',
    required: false,
  },
  requester: {
    bcKey: 'Requester',
    type: 'string',
    required: false,
  },
  neededByDate: {
    bcKey: 'Needed_By_Date',
    type: 'date',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  reasonCode: {
    bcKey: 'Reason_Code',
    type: 'string',
    required: false,
  },
  maintType: {
    bcKey: 'Maint_Type',
    type: 'string',
    required: false,
  },
  problemCode: {
    bcKey: 'Problem_Code',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  investigationTimeSpent: {
    bcKey: 'Investigation_Time_Spent',
    type: 'number',
    required: false,
  },
  estimatedInvestigationTime: {
    bcKey: 'Estimated_Investigation_Time',
    type: 'number',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  technicianName: {
    bcKey: 'Technician_Name',
    type: 'string',
    required: false,
  },
  failureCode: {
    bcKey: 'Failure_Code',
    type: 'string',
    required: false,
  },
  webServiceUser: {
    bcKey: 'Web_Service_User',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  reviewCondition: {
    bcKey: 'Review_Condition',
    type: 'string',
    required: false,
  },
  memo: {
    bcKey: 'Memo',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  requestResponse: {
    bcKey: 'Request_Response',
    type: 'string',
    required: false,
  },
  reviewConditionUser: {
    bcKey: 'Review_Condition_User',
    type: 'string',
    required: false,
  },
  portalStatus: {
    bcKey: 'Portal_Status',
    type: 'string',
    required: false,
  },
  creationDatetime: {
    bcKey: 'Creation_Datetime',
    type: 'date',
    required: false,
  },
  criticality: {
    bcKey: 'Criticality',
    type: 'string',
    required: false,
  },
  equipmentReferenceNo: {
    bcKey: 'Equipment_Reference_No',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Request = ODataObjectMap<typeof RequestMap>;

const RequestSchema = generateGraphQlSchema('Request', RequestMap);

const RequestQuery = generateGraphQlQuery('Request', RequestMap);

export { RequestSchema, type Request, RequestQuery, RequestMap };
