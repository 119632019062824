import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const NoSerieMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  defaultNos: {
    bcKey: 'Default_Nos',
    type: 'boolean',
    required: false,
  },
  manualNos: {
    bcKey: 'Manual_Nos',
    type: 'boolean',
    required: false,
  },
  dateOrder: {
    bcKey: 'Date_Order',
    type: 'boolean',
    required: false,
  },
} as const satisfies ODataMap;

type NoSerie = ODataObjectMap<typeof NoSerieMap>;

const NoSerieSchema = generateGraphQlSchema('noSeries', NoSerieMap);

const NoSerieQuery = generateGraphQlQuery('noSeries', NoSerieMap);

export { NoSerieSchema, NoSerieQuery, type NoSerie, NoSerieMap };
