import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema } from '../utils';

const CompanyMap = {
  name: {
    bcKey: 'Name',
    type: 'string',
    required: true,
  },
  displayName: {
    bcKey: 'Display_Name',
    type: 'string',
    required: true,
  },
  evaluationCompany: {
    bcKey: 'Evaluation_Company',
    type: 'boolean',
    required: true,
  },
  enableAssistedCompanySetup: {
    bcKey: 'Enable_Assisted_Company_Setup',
    type: 'boolean',
    required: true,
  },
  setupStatus: {
    bcKey: 'Setup_Status',
    type: 'string',
    required: true,
  },
  companyCreatedDateTime: {
    bcKey: 'Company_Created_Date_Time',
    type: 'date',
    required: true,
  },
} as const satisfies ODataMap;
type Company = ODataObjectMap<typeof CompanyMap>;

const CompanySchema = generateGraphQlSchema('Company', CompanyMap);
const CompanyQuery = `#graphql
query allCompanies($filter: String, $environmentId: String) {
  companies(filter: $filter, environmentId: $environmentId) {
    items {
      name
      displayName
      evaluationCompany
      enableAssistedCompanySetup
      setupStatus
      companyCreatedDateTime
    }
    totalCount
  }
}
`;

export { CompanySchema, CompanyQuery, type Company, CompanyMap };
