import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const CertificationMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Certification = ODataObjectMap<typeof CertificationMap>;

const CertificationSchema = generateGraphQlSchema(
  'Certification',
  CertificationMap
);

const CertificationQuery = generateGraphQlQuery(
  'Certification',
  CertificationMap
);

export {
  CertificationSchema,
  CertificationQuery,
  type Certification,
  CertificationMap,
};
