import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  getUserCoordinates(): Observable<{ x: number; y: number; z: number } | null> {
    return new Observable((observer) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude, altitude } = position.coords;
            observer.next({
              x: latitude,
              y: longitude,
              z: altitude || 0,
            });
            observer.complete();
          },
          (error) => {
            console.warn('Could not get coordinates, using defaults:', error);
            observer.next(null); // Default or fallback coordinates
            observer.complete();
          },
          {
            enableHighAccuracy: true,
            timeout: 1000,
            maximumAge: 0,
          }
        );
      } else {
        console.warn(
          'Geolocation is not supported by this browser. Using default coordinates.'
        );
        observer.next(null); // Default or fallback coordinates
        observer.complete();
      }
    });
  }
}
