//ET-DONE date
type VendorConsumption = {
  actualQuantity: number;
  actualUnitCost: number;
  billingUnitPrice: number;
  binCode: string;
  contractorName: string;
  contractorNo: string;
  description: string;
  description2: string;
  documentType: string;
  entryType: string;
  equipmentId: string;
  expectedQuantity: number;
  expectedUnitCost: number;
  facility: string;
  itemQuantityAllLocations: number;
  lineNo: number;
  locationCode: string;
  no: string;
  originalWorkOrderNo: string;
  purchaseOrderLineNo: number;
  purchaseOrderNo: string;
  relatedQuantityPosted: number;
  requirementDetails: string;
  requisitionedQty: number;
  sourceLineNo: number;
  sourceNo: string;
  type: string;
  unitOfMeasure: string;
  variantCode: string;
  vendorInvoiceStatus: string;
  vendorName: string;
  vendorNo: string;
  woReferenceInfo: string;
};

const VendorConsumptionSchema = `#graphql
  type VendorConsumption {
    actualQuantity: Float
    actualUnitCost: Float
    billingUnitPrice: Float
    binCode: String
    contractorName: String
    contractorNo: String
    description: String
    description2: String
    documentType: String
    entryType: String
    equipmentId: String
    expectedQuantity: Float
    expectedUnitCost: Float
    facility: String
    itemQuantityAllLocations: Float
    lineNo: Float
    locationCode: String
    no: String
    originalWorkOrderNo: String
    purchaseOrderLineNo: Float
    purchaseOrderNo: String
    relatedQuantityPosted: Float
    requirementDetails: String
    requisitionedQty: Float
    sourceLineNo: Float
    sourceNo: String
    type: String
    unitOfMeasure: String
    variantCode: String
    vendorInvoiceStatus: String
    vendorName: String
    vendorNo: String
    woReferenceInfo: String
  }`;

const VendorConsumptionQuery = `#graphql
  query allVendorConsumptions(
  
  $filter: String
  $skip: Int
  $top: Int
  $orderBy: String
  $desc: Boolean
) {
  vendorConsumptions(
    
    filter: $filter
    skip: $skip
    top: $top
    orderBy: $orderBy
    desc: $desc
  ) {
    items {
      actualQuantity
      actualUnitCost
      billingUnitPrice
      binCode
      contractorName
      contractorNo
      description
      description2
      documentType
      entryType
      equipmentId
      expectedQuantity
      expectedUnitCost
      facility
      itemQuantityAllLocations
      lineNo
      locationCode
      no
      originalWorkOrderNo
      purchaseOrderLineNo
      purchaseOrderNo
      relatedQuantityPosted
      requirementDetails
      requisitionedQty
      sourceLineNo
      sourceNo
      type
      unitOfMeasure
      variantCode
      vendorInvoiceStatus
      vendorName
      vendorNo
      woReferenceInfo
    }
    totalCount
  }
}
`;

export {
  VendorConsumptionSchema,
  type VendorConsumption,
  VendorConsumptionQuery,
};
