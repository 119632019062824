import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const MeterReadingMap = {
  documentNo: {
    bcKey: 'Document_No',
    type: 'string',
    required: false,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: false,
  },
  datetime: {
    bcKey: 'Datetime',
    type: 'date',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  workProcedure: {
    bcKey: 'Work_Procedure',
    type: 'string',
    required: false,
  },
  technician: {
    bcKey: 'Technician',
    type: 'string',
    required: false,
  },
  templateNo: {
    bcKey: 'Template_No',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  temperature: {
    bcKey: 'Temperature',
    type: 'number',
    required: false,
  },
  temperatureMeasure: {
    bcKey: 'Temperature_Measure',
    type: 'string',
    required: false,
  },
  weatherCondition: {
    bcKey: 'Weather_Condition',
    type: 'string',
    required: false,
  },
  workProcedureDescription: {
    bcKey: 'Work_Procedure_Description',
    type: 'string',
    required: false,
  },
  meterEntryType: {
    bcKey: 'Meter_Entry_Type',
    type: 'string',
    required: false,
  },
  inputValidationRequired: {
    bcKey: 'Input_Validation_Required',
    type: 'boolean',
    required: false,
  },
  testType: {
    bcKey: 'Test_Type',
    type: 'string',
    required: false,
  },
  symbolAllowed: {
    bcKey: 'Symbol_Allowed',
    type: 'string',
    required: false,
  },
  numericAcceptableLow: {
    bcKey: 'Numeric_Acceptable_Low',
    type: 'number',
    required: false,
  },
  numericAcceptableHigh: {
    bcKey: 'Numeric_Acceptable_High',
    type: 'number',
    required: false,
  },
  numericValidation1Low: {
    bcKey: 'Numeric_Validation_1_Low',
    type: 'number',
    required: false,
  },
  numericValidation1High: {
    bcKey: 'Numeric_Validation_1_High',
    type: 'number',
    required: false,
  },
  numericValidation1Message: {
    bcKey: 'Numeric_Validation_1_Message',
    type: 'string',
    required: false,
  },
  numericValidation2Low: {
    bcKey: 'Numeric_Validation_2_Low',
    type: 'number',
    required: false,
  },
  numericValidation2High: {
    bcKey: 'Numeric_Validation_2_High',
    type: 'number',
    required: false,
  },
  numericValidation2Message: {
    bcKey: 'Numeric_Validation_2_Message',
    type: 'string',
    required: false,
  },
  specificValueRequired: {
    bcKey: 'Specific_Value_Required',
    type: 'string',
    required: false,
  },
  resultsValue: {
    bcKey: 'Results_Value',
    type: 'string',
    required: false,
  },
  currentReading: {
    bcKey: 'Current_Reading',
    type: 'number',
    required: false,
  },
  priorReading: {
    bcKey: 'Prior_Reading',
    type: 'number',
    required: false,
  },
  unitOfMeasure: {
    bcKey: 'Unit_of_Measure',
    type: 'string',
    required: false,
  },
  binaryNegativeValue: {
    bcKey: 'Binary_Negative_Value',
    type: 'string',
    required: false,
  },
  binaryPositiveValue: {
    bcKey: 'Binary_Positive_Value',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  line: {
    bcKey: 'Line',
    type: 'string',
    required: false,
  },
  parentFacility: {
    bcKey: 'Parent_Facility',
    type: 'string',
    required: false,
  },
  masterEquipmentId: {
    bcKey: 'Master_Equipment_ID',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  forcedEntry: {
    bcKey: 'Forced_Entry',
    type: 'boolean',
    required: false,
  },
  forcedBy: {
    bcKey: 'Forced_By',
    type: 'string',
    required: false,
  },
  testDescription: {
    bcKey: 'Test_Description',
    type: 'string',
    required: false,
  },
  elapsedDays: {
    bcKey: 'Elapsed_Days',
    type: 'number',
    required: false,
  },
  startingDate: {
    bcKey: 'Starting_Date',
    type: 'string',
    required: false,
  },
  endingDate: {
    bcKey: 'Ending_Date',
    type: 'string',
    required: false,
  },
  reading: {
    bcKey: 'Reading',
    type: 'number',
    required: false,
  },
  apd: {
    bcKey: 'APD',
    type: 'number',
    required: false,
  },
  date: {
    bcKey: 'Date',
    type: 'date',
    required: false,
  },
  time: {
    bcKey: 'Time',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type MeterReading = ODataObjectMap<typeof MeterReadingMap>;

const MeterReadingSchema = generateGraphQlSchema(
  'meterReading',
  MeterReadingMap
);

const MeterReadingQuery = generateGraphQlQuery('meterReading', MeterReadingMap);

export {
  MeterReadingSchema,
  type MeterReading,
  MeterReadingQuery,
  MeterReadingMap,
};
