import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const RateTypeMap = {
  rateType: {
    bcKey: 'Rate_Type',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  multiplier: {
    bcKey: 'Multiplier',
    type: 'number',
    required: false,
  },
  entryType: {
    bcKey: 'Entry_Type',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;
type RateType = ODataObjectMap<typeof RateTypeMap>;

const RateTypeSchema = generateGraphQlSchema('RateType', RateTypeMap);
const RateTypeQuery = generateGraphQlQuery('RateType', RateTypeMap);

export { RateTypeSchema, type RateType, RateTypeQuery, RateTypeMap };
