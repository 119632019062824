import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const JobMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  personResponsible: {
    bcKey: 'PersonResponsible',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  blocked: {
    bcKey: 'Blocked',
    type: 'string',
    required: false,
  },
  endingDate: {
    bcKey: 'EndingDate',
    type: 'date',
    required: false,
  },
  startingDate: {
    bcKey: 'StartingDate',
    type: 'date',
    required: false,
  },
  billToCustomerNo: {
    bcKey: 'BillToCustomerNo',
    type: 'string',
    required: false,
  },
  billToName: {
    bcKey: 'BillToName',
    type: 'string',
    required: false,
  },
  projectManager: {
    bcKey: 'ProjectManager',
    type: 'string',
    required: false,
  },
  overbookable: {
    bcKey: 'Overbookable',
    type: 'boolean',
    required: false,
  },
  managedByTagproject: {
    bcKey: 'ManagedByTAGProject',
    type: 'boolean',
    required: false,
  },
  calendar: {
    bcKey: 'Calendar',
    type: 'string',
    required: false,
  },
  startDate: {
    bcKey: 'StartDate',
    type: 'date',
    required: false,
  },
  hoursPerDay: {
    bcKey: 'HoursPerDay',
    type: 'number',
    required: false,
  },
  daysPerWeek: {
    bcKey: 'DaysPerWeek',
    type: 'number',
    required: false,
  },
  daysPerMonth: {
    bcKey: 'DaysPerMonth',
    type: 'number',
    required: false,
  },
  percentDone: {
    bcKey: 'PercentDone',
    type: 'number',
    required: false,
  },
  duration: {
    bcKey: 'Duration',
    type: 'number',
    required: false,
  },
  rollup: {
    bcKey: 'Rollup',
    type: 'boolean',
    required: false,
  },
  expanded: {
    bcKey: 'Expanded',
    type: 'boolean',
    required: false,
  },
  percentCompleted: {
    bcKey: 'Percent_Completed',
    type: 'number',
    required: false,
  },
  tagPersonResponsibleName: {
    bcKey: 'TAG_Person_Responsible_Name',
    type: 'string',
    required: false,
  },
  tagJobInvoicingType: {
    bcKey: 'TAG_Job_Invoicing_Type',
    type: 'string',
    required: false,
  },
  tagJobPlanningLineType: {
    bcKey: 'TAG_Job_Planning_Line_Type',
    type: 'string',
    required: false,
  },
  tagJobJournalLineType: {
    bcKey: 'TAG_Job_Journal_Line_Type',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Job = ODataObjectMap<typeof JobMap>;

const JobSchema = generateGraphQlSchema('Job', JobMap);

const JobQuery = generateGraphQlQuery('Job', JobMap);

export { JobSchema, type Job, JobQuery, JobMap };
