import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const UniversalDocumentNoMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  documentTrackingNo: {
    bcKey: 'Document_Tracking_No',
    type: 'string',
    required: true,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  requestedServiceDate: {
    bcKey: 'Requested_Service_Date',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type UniversalDocumentNo = ODataObjectMap<typeof UniversalDocumentNoMap>;

const UniversalDocumentNoSchema = generateGraphQlSchema(
  'UniversalDocumentNo',
  UniversalDocumentNoMap
);

const UniversalDocumentNoQuery = generateGraphQlQuery(
  'UniversalDocumentNo',
  UniversalDocumentNoMap
);

export {
  UniversalDocumentNoSchema,
  type UniversalDocumentNo,
  UniversalDocumentNoQuery,
  UniversalDocumentNoMap,
};
