//ET-DONE date totalcount
type TimezoneOffset = {
  offset: number;
  startDate: Date;
  startTime: string;
  timeZoneCode: string;
};

const TimezoneOffsetSchema = `#graphql
  type TimezoneOffset {
      offset: Float
      startDate: Date
      startTime: String
      timeZoneCode: String
  }
`;

const TimezoneOffsetQuery = `#graphql
  query allTimezoneOffsets($filter: String) {
    timezoneOffsets(filter: $filter) {
      items {
        offset
        startDate
        startTime
        timeZoneCode
      }
      totalCount
    }
  }
`;

export { TimezoneOffsetSchema, TimezoneOffsetQuery, type TimezoneOffset };
