//ET-DONE date totalcount parameter
type AttachmentDocStash = {
  documentNo: string;
  documentType: string;
  fullUrl: string;
  format: string;
  lineNo: number;
  name: string;
  relativeUrl: string;
};

const AttachmentDocStashSchema = `#graphql
  type AttachmentDocStash {
    name: String
      documentNo: String
      documentType: String
      fullUrl: String
      format: String
      lineNo: Float
      relativeUrl: String
  }
`;

const AttachmentDocStashQuery = `#graphql
query allDocStorAttachments(
  $type: String
  $no: String
  $lineNo: Float
  $facility: String
  $top: Int
  $skip: Int
) {
  docStorAttachments(
    type: $type
    no: $no
    lineNo: $lineNo
    facility: $facility
    top: $top
    skip: $skip
  ) {
    items {
      documentNo
      documentType
      fullUrl
      format
      lineNo
      name
      relativeUrl
    }
    totalCount
  }
}
`;

export {
  AttachmentDocStashSchema,
  AttachmentDocStashQuery,
  type AttachmentDocStash,
};
