//ET-DONE date totalcount
type TimesheetHeader = {
  employeeNo: string;
  endDate: Date;
  no: string;
  resourceNo: string;
  startDate: Date;
  status: string;
  timeSheetPeriod: Date;
};

const TimesheetHeaderSchema = `#graphql
  type TimesheetHeader {
    employeeNo: String
    endDate: Date
    no: String
    resourceNo: String
    startDate: Date
    status: String
    timeSheetPeriod: Date
  }
`;

const TimesheetHeaderQuery = `#graphql
query allTimesheetHeaders($filter: String) {
  timesheetHeaders(filter: $filter) {
    items {
      employeeNo
      endDate
      no
      resourceNo
      startDate
      status
      timeSheetPeriod
    }
    totalCount
  }
}
`;

export { TimesheetHeaderSchema, TimesheetHeaderQuery, type TimesheetHeader };
