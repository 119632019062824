import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const UnionMap = {
  unionNo: {
    bcKey: 'Union_No',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  state1: {
    bcKey: 'State_1',
    type: 'string',
    required: false,
  },
  state2: {
    bcKey: 'State_2',
    type: 'string',
    required: false,
  },
  notes: {
    bcKey: 'Notes',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;
type Union = ODataObjectMap<typeof UnionMap>;

const UnionSchema = generateGraphQlSchema('Union', UnionMap);

const UnionQuery = generateGraphQlQuery('Union', UnionMap);

export { UnionSchema, type Union, UnionQuery, UnionMap };
