//ET-DONE date parameter
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';
import { WorkOrderLine, WorkOrderLineMap } from './work-order-line';

const PlannedWorkOrderLineMap = WorkOrderLineMap;
type PlannedWorkOrderLine = WorkOrderLine;

const PlannedWorkOrderLineSchema = generateGraphQlSchema(
  'plannedWorkOrderLine',
  PlannedWorkOrderLineMap
);

const PlannedWorkOrderLineQuery = generateGraphQlQuery(
  'plannedWorkOrderLine',
  PlannedWorkOrderLineMap
);

export {
  PlannedWorkOrderLineSchema,
  type PlannedWorkOrderLine,
  PlannedWorkOrderLineQuery,
  PlannedWorkOrderLineMap,
};
