import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ResourceCertificationMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  certCode: {
    bcKey: 'Cert_Code',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  skillCode: {
    bcKey: 'Skill_Code',
    type: 'string',
    required: false,
  },
  certificationNo: {
    bcKey: 'Certification_No',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  expirationDate: {
    bcKey: 'Expiration_Date',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ResourceCertification = ODataObjectMap<typeof ResourceCertificationMap>;

const ResourceCertificationSchema = generateGraphQlSchema(
  'ResourceCertification',
  ResourceCertificationMap
);

const ResourceCertificationQuery = generateGraphQlQuery(
  'ResourceCertification',
  ResourceCertificationMap
);

export {
  ResourceCertificationSchema,
  ResourceCertificationQuery,
  type ResourceCertification,
  ResourceCertificationMap,
};
