import { ODataMap } from '../types';

function lowerCaseFirstLetter(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function generateGraphQlSchema(type: string, map: ODataMap) {
  const schema = `#graphql
    type ${type} {
        ${Object.keys(map)
          .map((key) => `${key}: ${convertTypeToGraphQLType(map[key].type)}`)
          .join('\n')}
    }
    `;
  return schema;
}

export function generateGraphQlMutationSchema(type: string, map: ODataMap) {
  const schema = `#graphql
    mutation ${type}Input {
        ${Object.keys(map)
          .map((key) => `${key}: ${convertTypeToGraphQLType(map[key].type)}`)
          .join('\n')}
    }
    `;
  return schema;
}

export function generateFields(map: ODataMap) {
  return Object.entries(map).map(([key, value]) => {
    return value.bcKey;
  });
}

export function generateGraphQlQuery(type: string, map: ODataMap) {
  const query = `#graphql
        query all${type}s(
        $filter: String
        $dynamicFilter: Object
        $top: Int
        $skip: Int
        $orderBy: String
        $desc: Boolean
        ) {
            ${lowerCaseFirstLetter(type)}s (
                filter: $filter
                dynamicFilter: $dynamicFilter
                top: $top
                skip: $skip
                orderBy: $orderBy
                desc: $desc) {
                items {
                    ${Object.keys(map).join('\n')}
                }
                totalCount
            }
        }
    `;
  return query;
}

export function convertTypeToGraphQLType(type: string) {
  switch (type) {
    case 'string':
      return 'String';
    case 'number':
      return 'Float';
    case 'boolean':
      return 'Boolean';
    case 'date':
      return 'Date';
    case 'dictionary':
      return 'Dictionary';
    default:
      return 'String';
  }
}
