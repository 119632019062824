import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PurchaseOrderMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  buyFromVendorNo: {
    bcKey: 'Buy_from_Vendor_No',
    type: 'string',
    required: false,
  },
  orderAddressCode: {
    bcKey: 'Order_Address_Code',
    type: 'string',
    required: false,
  },
  buyFromVendorName: {
    bcKey: 'Buy_from_Vendor_Name',
    type: 'string',
    required: false,
  },
  vendorAuthorizationNo: {
    bcKey: 'Vendor_Authorization_No',
    type: 'string',
    required: false,
  },
  buyFromPostCode: {
    bcKey: 'Buy_from_Post_Code',
    type: 'string',
    required: false,
  },
  buyFromCountryRegionCode: {
    bcKey: 'Buy_from_Country_Region_Code',
    type: 'string',
    required: false,
  },
  buyFromContact: {
    bcKey: 'Buy_from_Contact',
    type: 'string',
    required: false,
  },
  payToVendorNo: {
    bcKey: 'Pay_to_Vendor_No',
    type: 'string',
    required: false,
  },
  payToName: {
    bcKey: 'Pay_to_Name',
    type: 'string',
    required: false,
  },
  payToPostCode: {
    bcKey: 'Pay_to_Post_Code',
    type: 'string',
    required: false,
  },
  payToCountryRegionCode: {
    bcKey: 'Pay_to_Country_Region_Code',
    type: 'string',
    required: false,
  },
  payToContact: {
    bcKey: 'Pay_to_Contact',
    type: 'string',
    required: false,
  },
  shipToCode: {
    bcKey: 'Ship_to_Code',
    type: 'string',
    required: false,
  },
  shipToName: {
    bcKey: 'Ship_to_Name',
    type: 'string',
    required: false,
  },
  shipToPostCode: {
    bcKey: 'Ship_to_Post_Code',
    type: 'string',
    required: false,
  },
  shipToCountryRegionCode: {
    bcKey: 'Ship_to_Country_Region_Code',
    type: 'string',
    required: false,
  },
  shipToContact: {
    bcKey: 'Ship_to_Contact',
    type: 'string',
    required: false,
  },
  postingDate: {
    bcKey: 'Posting_Date',
    type: 'date',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  locationCode: {
    bcKey: 'Location_Code',
    type: 'string',
    required: false,
  },
  purchaserCode: {
    bcKey: 'Purchaser_Code',
    type: 'string',
    required: false,
  },
  assignedUserId: {
    bcKey: 'Assigned_User_ID',
    type: 'string',
    required: false,
  },
  currencyCode: {
    bcKey: 'Currency_Code',
    type: 'string',
    required: false,
  },
  documentDate: {
    bcKey: 'Document_Date',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  paymentTermsCode: {
    bcKey: 'Payment_Terms_Code',
    type: 'string',
    required: false,
  },
  dueDate: {
    bcKey: 'Due_Date',
    type: 'string',
    required: false,
  },
  orderDate: {
    bcKey: 'Order_Date',
    type: 'date',
    required: false,
  },
  paymentDiscountPercent: {
    bcKey: 'Payment_Discount_Percent',
    type: 'number',
    required: false,
  },
  paymentMethodCode: {
    bcKey: 'Payment_Method_Code',
    type: 'string',
    required: false,
  },
  shipmentMethodCode: {
    bcKey: 'Shipment_Method_Code',
    type: 'string',
    required: false,
  },
  requestedReceiptDate: {
    bcKey: 'Requested_Receipt_Date',
    type: 'string',
    required: false,
  },
  jobQueueStatus: {
    bcKey: 'Job_Queue_Status',
    type: 'string',
    required: false,
  },
  amountReceivedNotInvoicedExclVatLcy: {
    bcKey: 'Amount_Received_Not_Invoiced_excl_VAT_LCY',
    type: 'number',
    required: false,
  },
  amountReceivedNotInvoicedLcy: {
    bcKey: 'Amount_Received_Not_Invoiced_LCY',
    type: 'number',
    required: false,
  },
  amount: {
    bcKey: 'Amount',
    type: 'number',
    required: false,
  },
  amountIncludingVat: {
    bcKey: 'Amount_Including_VAT',
    type: 'number',
    required: false,
  },
  postingDescription: {
    bcKey: 'Posting_Description',
    type: 'string',
    required: false,
  },
  yourReference: {
    bcKey: 'Your_Reference',
    type: 'string',
    required: false,
  },
  vendorOrderNo: {
    bcKey: 'Vendor_Order_No',
    type: 'string',
    required: false,
  },
  tagPersonnelGroup: {
    bcKey: 'TAG_Personnel_Group',
    type: 'string',
    required: false,
  },
  tagTechnicianCode: {
    bcKey: 'TAG_Technician_Code',
    type: 'string',
    required: false,
  },
  tagWorkOrderNo: {
    bcKey: 'TAG_Work_Order_No',
    type: 'string',
    required: false,
  },
  tagWebServiceUser: {
    bcKey: 'TAG_Web_Service_User',
    type: 'string',
    required: false,
  },
  buyFromAddress: {
    bcKey: 'Buy_from_Address',
    type: 'string',
    required: false,
  },
  buyFromAddress2: {
    bcKey: 'Buy_from_Address_2',
    type: 'string',
    required: false,
  },
  buyFromCity: {
    bcKey: 'Buy_from_City',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type PurchaseOrder = ODataObjectMap<typeof PurchaseOrderMap>;

const PurchaseOrderSchema = generateGraphQlSchema(
  'PurchaseOrder',
  PurchaseOrderMap
);

const PurchaseOrderQuery = generateGraphQlQuery(
  'purchaseOrder',
  PurchaseOrderMap
);

export {
  PurchaseOrderSchema,
  type PurchaseOrder,
  PurchaseOrderQuery,
  PurchaseOrderMap,
};
