//ET-DONE date totalcount parameter
type Form = {
  createdAt: string;
  createdById: string;
  customerId: string;
  description: string;
  formVersions: Object;
  id: number;
  name: string;
  type: string;
  updatedAt: string;

  schema?: string;
};

const FormSchema = `#graphql
  type Form {
    createdAt: String
    createdById: String
    customerId: String
    description: String
    formVersions: Object
    id: Float
    name: String
    type: String
    updatedAt: String
  }
`;

const FormQuery = `#graphql
query allForms(
  $filter: String
  $top: Int
  $skip: Int
  $orderBy: String
  $desc: Boolean,
) {
  forms(
    filter: $filter
    top: $top
    skip: $skip
    orderBy: $orderBy
    desc: $desc
  ) {
    items {
      createdAt
      createdById
      customerId
      description
      formVersions
      id
      name
      type
      updatedAt
    }
    totalCount
  }
}
`;

export { FormSchema, type Form, FormQuery };
