import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const FaultEntryMap = {
  faultReport: {
    bcKey: 'Fault_Report',
    type: 'string',
    required: false,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  failureCode: {
    bcKey: 'Failure_Code',
    type: 'string',
    required: false,
  },
  companyId: {
    bcKey: 'Company_ID',
    type: 'string',
    required: false,
  },
  faId: {
    bcKey: 'FA_ID',
    type: 'string',
    required: false,
  },
  autoGenerateWorkOrder: {
    bcKey: 'Auto_Generate_Work_Order',
    type: 'boolean',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  duration: {
    bcKey: 'Duration',
    type: 'number',
    required: false,
  },
  entryStartDatetime: {
    bcKey: 'Entry_Start_Datetime',
    type: 'date',
    required: false,
  },
  entryEndDatetime: {
    bcKey: 'Entry_End_Datetime',
    type: 'date',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  sourceNotes: {
    bcKey: 'Source_Notes',
    type: 'string',
    required: false,
  },
  templateNo: {
    bcKey: 'Template_No',
    type: 'string',
    required: false,
  },
  templateDescription: {
    bcKey: 'Template_Description',
    type: 'string',
    required: false,
  },
  primaryFault: {
    bcKey: 'Primary_Fault',
    type: 'boolean',
    required: false,
  },
  sourceDocumentNo: {
    bcKey: 'Source_Document_No',
    type: 'string',
    required: false,
  },
  sourceDocumentLineNo: {
    bcKey: 'Source_Document_Line_No',
    type: 'number',
    required: false,
  },
  sourceFeedbackLineNo: {
    bcKey: 'Source_Feedback_Line_No',
    type: 'number',
    required: false,
  },
  assessmentType: {
    bcKey: 'Assessment_Type',
    type: 'string',
    required: false,
  },
  assessmentDetails: {
    bcKey: 'Assessment_Details',
    type: 'string',
    required: false,
  },
  assessmentValue: {
    bcKey: 'Assessment_Value',
    type: 'string',
    required: false,
  },
  assessmentValuePass: {
    bcKey: 'Assessment_Value_Pass',
    type: 'boolean',
    required: false,
  },
} as const satisfies ODataMap;

type FaultEntry = ODataObjectMap<typeof FaultEntryMap>;

const FaultEntrySchema = generateGraphQlSchema('FaultEntry', FaultEntryMap);

const FaultEntryQuery = generateGraphQlQuery('FaultEntrie', FaultEntryMap);

export { FaultEntrySchema, FaultEntryQuery, type FaultEntry, FaultEntryMap };
