//ET-DONE date totalcount
type Crew = {
  area: string;
  blocked: boolean;
  code: string;
  composition: string;
  description: string;
  employeeNo: string;
  endDatetime: Date;
  facility: string;
  route: string;
  startDatetime: Date;
};

const CrewSchema = `#graphql
  type Crew {
    area: String
    blocked: Boolean
    code: String
    composition: String
    description: String
    employeeNo: String
    endDatetime: Date
    facility: String
    route: String
    startDatetime: Date
  }
`;

const CrewQuery = `#graphql
  query allCrews($filter: String) {
    crews(filter: $filter) {
      items {
        area
        blocked
        code
        composition
        description
        employeeNo
        endDatetime
        facility
        route
        startDatetime
      }
      totalCount
    }
  }
`;

export { CrewSchema, CrewQuery, type Crew };
