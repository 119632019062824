import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const DimensionMap = {
  dimensionCode: {
    bcKey: 'Dimension_Code',
    type: 'string',
    required: true,
  },
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  dimensionValueType: {
    bcKey: 'Dimension_Value_Type',
    type: 'string',
    required: false,
  },
  blocked: {
    bcKey: 'Blocked',
    type: 'boolean',
    required: false,
  },
  indentation: {
    bcKey: 'Indentation',
    type: 'number',
    required: false,
  },
} as const satisfies ODataMap;

type Dimension = ODataObjectMap<typeof DimensionMap>;

const DimensionSchema = generateGraphQlSchema('Dimension', DimensionMap);

const DimensionQuery = generateGraphQlQuery('Dimension', DimensionMap);

export { DimensionSchema, DimensionQuery, type Dimension, DimensionMap };
