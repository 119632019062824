//ET-DONE date totalcount
type Holiday = {
  auxiliaryIndex1: string;
  auxiliaryIndex2: string;
  auxiliaryIndex3: Date;
  auxiliaryIndex4: string;
  auxiliaryIndex5: Date;
  auxiliaryIndex6: number;
  employeeGroupCode: string;
  employeeNo: string;
  endDateTime: Date;
  holidayCode: string;
  holidayDescription: string;
  posted: boolean;
  resourceNo: string;
  scheduledDate: Date;
  startDateTime: Date;
};

const HolidaySchema = `#graphql
  type Holiday {
    auxiliaryIndex1: String
    auxiliaryIndex2: String
    auxiliaryIndex3: Date
    auxiliaryIndex4: String
    auxiliaryIndex5: Date
    auxiliaryIndex6: Float
    employeeGroupCode: String
    employeeNo: String
    endDateTime: Date
    holidayCode: String
    holidayDescription: String
    posted: Boolean
    resourceNo: String
    scheduledDate: Date
    startDateTime: Date
  }
`;

const HolidayQuery = `#graphql
query allHolidays($filter: String) {
  holidays(filter: $filter) {
    items {
      auxiliaryIndex1
      auxiliaryIndex2
      auxiliaryIndex3
      auxiliaryIndex4
      auxiliaryIndex5
      auxiliaryIndex6
      employeeGroupCode
      employeeNo
      endDateTime
      holidayCode
      holidayDescription
      posted
      resourceNo
      scheduledDate
      startDateTime
    }
    totalCount
  }
}
`;

export { HolidaySchema, HolidayQuery, type Holiday };
