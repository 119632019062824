import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const TagSetupMap = {
  primaryKey: {
    bcKey: 'Primary_Key',
    type: 'string',
    required: false,
  },
  dueByLeadTime: {
    bcKey: 'Due_By_Lead_time',
    type: 'string',
    required: false,
  },
  expireLeadTime: {
    bcKey: 'Expire_Lead_Time',
    type: 'string',
    required: false,
  },
  whiteboardSort: {
    bcKey: 'Whiteboard_Sort',
    type: 'string',
    required: false,
  },
  startLeadTime: {
    bcKey: 'Start_Lead_Time',
    type: 'string',
    required: false,
  },
  rsdLeadTime: {
    bcKey: 'RSD_Lead_Time',
    type: 'string',
    required: false,
  },
  defaultMaintenanceType: {
    bcKey: 'Default_Maintenance_Type',
    type: 'string',
    required: false,
  },
  timeResource: {
    bcKey: 'Time_Resource',
    type: 'string',
    required: false,
  },
  mileageResource: {
    bcKey: 'Mileage_Resource',
    type: 'string',
    required: false,
  },
  travelWorkType: {
    bcKey: 'Travel_Work_Type',
    type: 'string',
    required: false,
  },
  laborWorkType: {
    bcKey: 'Labor_Work_Type',
    type: 'string',
    required: false,
  },
  mileageWorkType: {
    bcKey: 'Mileage_Work_Type',
    type: 'string',
    required: false,
  },
  workScheduleWarnings: {
    bcKey: 'Work_Schedule_Warnings',
    type: 'boolean',
    required: false,
  },
  defaultPriority: {
    bcKey: 'Default_Priority',
    type: 'string',
    required: false,
  },
  hourlyRoundingRate: {
    bcKey: 'Hourly_Rounding_Rate',
    type: 'string',
    required: false,
  },
  shopChargeRate: {
    bcKey: 'Shop_Charge_Rate',
    type: 'number',
    required: false,
  },
  shopChargeGLAcct: {
    bcKey: 'Shop_Charge_G_L_Acct',
    type: 'string',
    required: false,
  },
  shopChargeType: {
    bcKey: 'Shop_Charge_Type',
    type: 'string',
    required: false,
  },
  useWorkSchedule: {
    bcKey: 'Use_Work_Schedule',
    type: 'boolean',
    required: false,
  },
  workScheduleLeadTime: {
    bcKey: 'Work_Schedule_Lead_Time',
    type: 'string',
    required: false,
  },
  useRegions: {
    bcKey: 'Use_Regions',
    type: 'boolean',
    required: false,
  },
  troubleshootingItemLink: {
    bcKey: 'Troubleshooting_Item_Link',
    type: 'boolean',
    required: false,
  },
  defaultStatus: {
    bcKey: 'Default_Status',
    type: 'string',
    required: false,
  },
  whiteboardPeriodStart: {
    bcKey: 'Whiteboard_Period_Start',
    type: 'string',
    required: false,
  },
  whiteboardPeriodLength: {
    bcKey: 'Whiteboard_Period_Length',
    type: 'string',
    required: false,
  },
  workScheduleCreditFormula: {
    bcKey: 'Work_Schedule_Credit_Formula',
    type: 'string',
    required: false,
  },
  commandLineApp: {
    bcKey: 'Command_Line_App',
    type: 'string',
    required: false,
  },
  imagePath: {
    bcKey: 'Image_Path',
    type: 'string',
    required: false,
  },
  drawingPath: {
    bcKey: 'Drawing_Path',
    type: 'string',
    required: false,
  },
  calcMethod: {
    bcKey: 'CalcMethod',
    type: 'string',
    required: false,
  },
  videoPath: {
    bcKey: 'Video_Path',
    type: 'string',
    required: false,
  },
  levelColor: {
    bcKey: 'Level_Color',
    type: 'number',
    required: false,
  },
  example: {
    bcKey: 'Example',
    type: 'number',
    required: false,
  },
  level1Color: {
    bcKey: 'Level_1_Color',
    type: 'number',
    required: false,
  },
  example1: {
    bcKey: 'Example_1',
    type: 'number',
    required: false,
  },
  level2Color: {
    bcKey: 'Level_2_Color',
    type: 'number',
    required: false,
  },
  example2: {
    bcKey: 'Example_2',
    type: 'number',
    required: false,
  },
  level3Color: {
    bcKey: 'Level_3_Color',
    type: 'number',
    required: false,
  },
  example3: {
    bcKey: 'Example_3',
    type: 'number',
    required: false,
  },
  journalInventoryToNavision: {
    bcKey: 'Journal_Inventory_to_Navision',
    type: 'boolean',
    required: false,
  },
  requireNavisionItems: {
    bcKey: 'Require_Navision_Items',
    type: 'boolean',
    required: false,
  },
  journalNonNavisionItems: {
    bcKey: 'Journal_Non_Navision_Items',
    type: 'boolean',
    required: false,
  },
  consumableJournalTemplate: {
    bcKey: 'Consumable_Journal_Template',
    type: 'string',
    required: false,
  },
  consumableJournalBatch: {
    bcKey: 'Consumable_Journal_Batch',
    type: 'string',
    required: false,
  },
  requireTechnicianOnLine: {
    bcKey: 'Require_Technician_on_Line',
    type: 'boolean',
    required: false,
  },
  requireTechVendorOnOrder: {
    bcKey: 'Require_Tech_Vendor_on_Order',
    type: 'boolean',
    required: false,
  },
  requireEquipmentOnOrder: {
    bcKey: 'Require_Equipment_on_Order',
    type: 'boolean',
    required: false,
  },
  approvalRequired: {
    bcKey: 'Approval_Required',
    type: 'boolean',
    required: false,
  },
  releasedOrdersExist: {
    bcKey: 'Released_Orders_Exist',
    type: 'boolean',
    required: false,
  },
  requireSupervisorOnOrder: {
    bcKey: 'Require_Supervisor_on_Order',
    type: 'boolean',
    required: false,
  },
  signature1Title: {
    bcKey: 'Signature_1_Title',
    type: 'string',
    required: false,
  },
  signature2Title: {
    bcKey: 'Signature_2_Title',
    type: 'string',
    required: false,
  },
  securityOn: {
    bcKey: 'Security_On',
    type: 'boolean',
    required: false,
  },
  personnelFacilityRequired: {
    bcKey: 'Personnel_Facility_Required',
    type: 'boolean',
    required: false,
  },
  personnelRegionRequired: {
    bcKey: 'Personnel_Region_Required',
    type: 'boolean',
    required: false,
  },
  message1: {
    bcKey: 'Message_1',
    type: 'string',
    required: false,
  },
  message2: {
    bcKey: 'Message_2',
    type: 'string',
    required: false,
  },
  printLogo: {
    bcKey: 'Print_Logo',
    type: 'string',
    required: false,
  },
  printWorkOrderTitle: {
    bcKey: 'Print_Work_Order_Title',
    type: 'string',
    required: false,
  },
  printCompanyName: {
    bcKey: 'Print_Company_Name',
    type: 'boolean',
    required: false,
  },
  requireEquipmentOnLine: {
    bcKey: 'Require_Equipment_on_Line',
    type: 'boolean',
    required: false,
  },
  autoPostJournal: {
    bcKey: 'Auto_Post_Journal',
    type: 'boolean',
    required: false,
  },
  nonItemConsJnlTemplate: {
    bcKey: 'Non_Item_Cons_Jnl_Template',
    type: 'string',
    required: false,
  },
  nonItemConsJnlBatch: {
    bcKey: 'Non_Item_Cons_Jnl_Batch',
    type: 'string',
    required: false,
  },
  defaultLaborRate: {
    bcKey: 'Default_Labor_Rate',
    type: 'number',
    required: false,
  },
  navItemJournalTemplate: {
    bcKey: 'NAV_Item_Journal_Template',
    type: 'string',
    required: false,
  },
  navItemJournalBatch: {
    bcKey: 'NAV_Item_Journal_Batch',
    type: 'string',
    required: false,
  },
  navItemAutoPost: {
    bcKey: 'NAV_Item_Auto_Post',
    type: 'boolean',
    required: false,
  },
  defaultItemCategory: {
    bcKey: 'Default_Item_Category',
    type: 'string',
    required: false,
  },
  requestNos: {
    bcKey: 'Request_Nos',
    type: 'string',
    required: false,
  },
  generalJournalTemplate: {
    bcKey: 'General_Journal_Template',
    type: 'string',
    required: false,
  },
  generalJournalBatch: {
    bcKey: 'General_Journal_Batch',
    type: 'string',
    required: false,
  },
  generalJournalAutoPost: {
    bcKey: 'General_Journal_Auto_Post',
    type: 'boolean',
    required: false,
  },
  fixedAssetJournalTemplate: {
    bcKey: 'Fixed_Asset_Journal_Template',
    type: 'string',
    required: false,
  },
  fixedAssetJournalBatch: {
    bcKey: 'Fixed_Asset_Journal_Batch',
    type: 'string',
    required: false,
  },
  otherChargesPostingCode: {
    bcKey: 'Other_Charges_Posting_Code',
    type: 'string',
    required: false,
  },
  postToGeneralJournal: {
    bcKey: 'Post_to_General_Journal',
    type: 'boolean',
    required: false,
  },
  postToFixedAssetJournal: {
    bcKey: 'Post_to_Fixed_Asset_Journal',
    type: 'boolean',
    required: false,
  },
  autoPostNonItems: {
    bcKey: 'Auto_Post_Non_Items',
    type: 'boolean',
    required: false,
  },
  postItems: {
    bcKey: 'Post_Items',
    type: 'boolean',
    required: false,
  },
  postPersonnel: {
    bcKey: 'Post_Personnel',
    type: 'boolean',
    required: false,
  },
  personnelJournalTemplate: {
    bcKey: 'Personnel_Journal_Template',
    type: 'string',
    required: false,
  },
  personnelJournalBatch: {
    bcKey: 'Personnel_Journal_Batch',
    type: 'string',
    required: false,
  },
  personnelJournalAutoPost: {
    bcKey: 'Personnel_Journal_Auto_Post',
    type: 'boolean',
    required: false,
  },
  defaultPrintWOFeedback: {
    bcKey: 'Default_Print_W_O_Feedback',
    type: 'boolean',
    required: false,
  },
  defaultPrintExcludeFinished: {
    bcKey: 'Default_Print_Exclude_Finished',
    type: 'boolean',
    required: false,
  },
  defaultPrintQualifications: {
    bcKey: 'Default_Print_Qualifications',
    type: 'boolean',
    required: false,
  },
  defaultPrintRequirements: {
    bcKey: 'Default_Print_Requirements',
    type: 'boolean',
    required: false,
  },
  defaultPrintPickingList: {
    bcKey: 'Default_Print_Picking_List',
    type: 'boolean',
    required: false,
  },
  defaultPrintPartsList: {
    bcKey: 'Default_Print_Parts_List',
    type: 'boolean',
    required: false,
  },
  defaultRequestLock: {
    bcKey: 'Default_Request_Lock',
    type: 'boolean',
    required: false,
  },
  requestFeedbackOrComments: {
    bcKey: 'Request_Feedback_or_Comments',
    type: 'string',
    required: false,
  },
  allowMeterHistoryLogging: {
    bcKey: 'Allow_Meter_History_Logging',
    type: 'boolean',
    required: false,
  },
  dateOption: {
    bcKey: 'Date_Option',
    type: 'string',
    required: false,
  },
  useWindowsLogin: {
    bcKey: 'Use_Windows_Login',
    type: 'boolean',
    required: false,
  },
  printExtendedEquipmentInfo: {
    bcKey: 'Print_Extended_Equipment_Info',
    type: 'string',
    required: false,
  },
  printSubSteps: {
    bcKey: 'Print_Sub_steps',
    type: 'boolean',
    required: false,
  },
  defaultDmpTemplate: {
    bcKey: 'Default_DMP_Template',
    type: 'string',
    required: false,
  },
  defaultPwoTemplate: {
    bcKey: 'Default_PWO_Template',
    type: 'string',
    required: false,
  },
  defaultRwoTemplate: {
    bcKey: 'Default_RWO_Template',
    type: 'string',
    required: false,
  },
  defaultSvcReqMaintType: {
    bcKey: 'Default_Svc_Req_Maint_Type',
    type: 'string',
    required: false,
  },
  defaultWrkReqMaintType: {
    bcKey: 'Default_Wrk_Req_Maint_Type',
    type: 'string',
    required: false,
  },
  defaultWrkRequestPriority: {
    bcKey: 'Default_Wrk_Request_Priority',
    type: 'string',
    required: false,
  },
  defaultWrkRequestStatus: {
    bcKey: 'Default_Wrk_Request_Status',
    type: 'string',
    required: false,
  },
  defaultOrderTypeWr: {
    bcKey: 'Default_Order_Type_WR',
    type: 'string',
    required: false,
  },
  defaultWorkCodeWr: {
    bcKey: 'Default_Work_Code_WR',
    type: 'string',
    required: false,
  },
  requireSrDescription: {
    bcKey: 'Require_SR_Description',
    type: 'boolean',
    required: false,
  },
  requireSrMaintType: {
    bcKey: 'Require_SR_Maint_Type',
    type: 'boolean',
    required: false,
  },
  requireSrEquipmentId: {
    bcKey: 'Require_SR_Equipment_ID',
    type: 'boolean',
    required: false,
  },
  requireWrDescription: {
    bcKey: 'Require_WR_Description',
    type: 'boolean',
    required: false,
  },
  requireWrMaintType: {
    bcKey: 'Require_WR_Maint_Type',
    type: 'boolean',
    required: false,
  },
  requireWrEquipmentId: {
    bcKey: 'Require_WR_Equipment_ID',
    type: 'boolean',
    required: false,
  },
  printedFormSr: {
    bcKey: 'Printed_Form_SR',
    type: 'string',
    required: false,
  },
  printedFormWr: {
    bcKey: 'Printed_Form_WR',
    type: 'string',
    required: false,
  },
  requestSignature1Title: {
    bcKey: 'Request_Signature_1_Title',
    type: 'string',
    required: false,
  },
  requestSignature2Title: {
    bcKey: 'Request_Signature_2_Title',
    type: 'string',
    required: false,
  },
  requestMessage1: {
    bcKey: 'Request_Message_1',
    type: 'string',
    required: false,
  },
  requestMessage2: {
    bcKey: 'Request_Message_2',
    type: 'string',
    required: false,
  },
  requireSrRequester: {
    bcKey: 'Require_SR_Requester',
    type: 'boolean',
    required: false,
  },
  requireWrRequester: {
    bcKey: 'Require_WR_Requester',
    type: 'boolean',
    required: false,
  },
  defaultRequisitionJournal: {
    bcKey: 'Default_Requisition_Journal',
    type: 'string',
    required: false,
  },
  integrationType: {
    bcKey: 'Integration_Type',
    type: 'string',
    required: false,
  },
  integrationVersion: {
    bcKey: 'Integration_Version',
    type: 'string',
    required: false,
  },
  allowMultiCompany: {
    bcKey: 'Allow_Multi_Company',
    type: 'boolean',
    required: false,
  },
  version: {
    bcKey: 'Version',
    type: 'string',
    required: false,
  },
  projectContractNos: {
    bcKey: 'Project_Contract_Nos',
    type: 'string',
    required: false,
  },
  inventoryPolicyNos: {
    bcKey: 'Inventory_Policy_Nos',
    type: 'string',
    required: false,
  },
  dateMeterPolicyNos: {
    bcKey: 'Date_Meter_Policy_Nos',
    type: 'string',
    required: false,
  },
  faultEntryNos: {
    bcKey: 'Fault_Entry_Nos',
    type: 'string',
    required: false,
  },
  documentTrackingNos: {
    bcKey: 'Document_Tracking_Nos',
    type: 'string',
    required: false,
  },
  technicianNos: {
    bcKey: 'Technician_Nos',
    type: 'string',
    required: false,
  },
  templateNos: {
    bcKey: 'Template_Nos',
    type: 'string',
    required: false,
  },
  plannedWoNos: {
    bcKey: 'Planned_WO_Nos',
    type: 'string',
    required: false,
  },
  releasedWoNos: {
    bcKey: 'Released_WO_Nos',
    type: 'string',
    required: false,
  },
  finishedWoNos: {
    bcKey: 'Finished_WO_Nos',
    type: 'string',
    required: false,
  },
  consumableNos: {
    bcKey: 'Consumable_Nos',
    type: 'string',
    required: false,
  },
  maintStepNos: {
    bcKey: 'Maint_Step_Nos',
    type: 'string',
    required: false,
  },
  ospNos: {
    bcKey: 'OSP_Nos',
    type: 'string',
    required: false,
  },
  toolNos: {
    bcKey: 'Tool_Nos',
    type: 'string',
    required: false,
  },
  facilityNos: {
    bcKey: 'Facility_Nos',
    type: 'string',
    required: false,
  },
  serviceQuoteNo: {
    bcKey: 'Service_Quote_No',
    type: 'string',
    required: false,
  },
  equipmentNos: {
    bcKey: 'Equipment_Nos',
    type: 'string',
    required: false,
  },
  mobileReviewRqstFilter: {
    bcKey: 'Mobile_Review_Rqst_Filter',
    type: 'string',
    required: false,
  },
  mobileTechnicianRqstFilter: {
    bcKey: 'Mobile_Technician_Rqst_Filter',
    type: 'string',
    required: false,
  },
  defaultOrderType: {
    bcKey: 'Default_Order_Type',
    type: 'string',
    required: false,
  },
  defaultWorkCode: {
    bcKey: 'Default_Work_Code',
    type: 'string',
    required: false,
  },
  useConsumeTimesheet: {
    bcKey: 'Use_Consume_Timesheet',
    type: 'boolean',
    required: false,
  },
  enableConditionMonitoring: {
    bcKey: 'Enable_Condition_Monitoring',
    type: 'boolean',
    required: false,
  },
  createWoDefaultType: {
    bcKey: 'Create_WO_Default_Type',
    type: 'string',
    required: false,
  },
  tagApiToken: {
    bcKey: 'TAG_API_Token',
    type: 'string',
    required: false,
  },
  useTX0026A: {
    bcKey: 'Use_T_x0026_A',
    type: 'boolean',
    required: false,
  },
  allowJobInvoicing: {
    bcKey: 'Allow_Job_Invoicing',
    type: 'boolean',
    required: false,
  },
  geofencingEnabled: {
    bcKey: 'Geofencing_Enabled',
    type: 'boolean',
    required: false,
  },
  geoFencingDistance: {
    bcKey: 'GeoFencing_Distance',
    type: 'number',
    required: false,
  },
} as const satisfies ODataMap;

type TAGSetup = ODataObjectMap<typeof TagSetupMap>;

const TAGSetupSchema = generateGraphQlSchema('TagSetup', TagSetupMap);

const TAGSetupQuery = generateGraphQlQuery('TagSetup', TagSetupMap);

export { TAGSetupSchema, type TAGSetup, TAGSetupQuery, TagSetupMap };
