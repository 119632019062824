import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ExpenseLedgerEntryMap = {
  batchName: {
    bcKey: 'Batch_Name',
    type: 'string',
    required: true,
  },
  sourceLineNo: {
    bcKey: 'Source_Line_No_',
    type: 'number',
    required: false,
  },
  entryNo: {
    bcKey: 'Entry_No_',
    type: 'number',
    required: true,
  },
  no: {
    bcKey: 'No_',
    type: 'string',
    required: true,
  },
  postingDate: {
    bcKey: 'Posting_Date',
    type: 'string',
    required: false,
  },
  entryType: {
    bcKey: 'Entry_Type',
    type: 'string',
    required: false,
  },
  documentNo: {
    bcKey: 'Document_No_',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  quantity: {
    bcKey: 'Quantity',
    type: 'number',
    required: false,
  },
  currencyCode: {
    bcKey: 'Currency_Code',
    type: 'string',
    required: false,
  },
  unitAmount: {
    bcKey: 'Unit_Amount',
    type: 'number',
    required: false,
  },
  amount: {
    bcKey: 'Amount',
    type: 'number',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  workOrderNo: {
    bcKey: 'Work_Order_No_',
    type: 'string',
    required: false,
  },
  workOrderLineNo: {
    bcKey: 'Work_Order_Line_No_',
    type: 'number',
    required: false,
  },
  vendorNo: {
    bcKey: 'Vendor_No_',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  supervisorCode: {
    bcKey: 'Supervisor_Code',
    type: 'string',
    required: false,
  },
  documentDate: {
    bcKey: 'Document_Date',
    type: 'string',
    required: false,
  },
  orderDate: {
    bcKey: 'Order_Date',
    type: 'string',
    required: false,
  },
  taxAreaCode: {
    bcKey: 'Tax_Area_Code',
    type: 'string',
    required: false,
  },
  taxLiable: {
    bcKey: 'Tax_Liable',
    type: 'boolean',
    required: false,
  },
  taxGroupCode: {
    bcKey: 'Tax_Group_Code',
    type: 'string',
    required: false,
  },
  dimensionSetId: {
    bcKey: 'Dimension_Set_ID',
    type: 'number',
    required: false,
  },
  unitOfMeasureCode: {
    bcKey: 'Unit_of_Measure_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ExpenseLedgerEntry = ODataObjectMap<typeof ExpenseLedgerEntryMap>;

const ExpenseLedgerEntrySchema = generateGraphQlSchema(
  'ExpenseLedgerEntry',
  ExpenseLedgerEntryMap
);

const ExpenseLedgerEntryQuery = generateGraphQlQuery(
  'ExpenseLedgerEntrie',
  ExpenseLedgerEntryMap
);

export {
  ExpenseLedgerEntrySchema,
  ExpenseLedgerEntryQuery,
  type ExpenseLedgerEntry,
  ExpenseLedgerEntryMap,
};
