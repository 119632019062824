import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const RequirementMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  sourceNo: {
    bcKey: 'Source_No',
    type: 'string',
    required: true,
  },
  sourceLineNo: {
    bcKey: 'Source_Line_No',
    type: 'number',
    required: true,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: true,
  },
  lookupType: {
    bcKey: 'Lookup_Type',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: true,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  unitOfMeasure: {
    bcKey: 'Unit_of_Measure',
    type: 'string',
    required: false,
  },
  locationCode: {
    bcKey: 'Location_Code',
    type: 'string',
    required: false,
  },
  projAvailBalance: {
    bcKey: 'ProjAvailBalance',
    type: 'string',
    required: false,
  },
  expectedQuantity: {
    bcKey: 'Expected_Quantity',
    type: 'number',
    required: false,
  },
  expectedUnitCost: {
    bcKey: 'Expected_Unit_Cost',
    type: 'number',
    required: false,
  },
  actualQuantity: {
    bcKey: 'Actual_Quantity',
    type: 'number',
    required: false,
  },
  actualUnitCost: {
    bcKey: 'Actual_Unit_Cost',
    type: 'number',
    required: false,
  },
  substitutionAllowed: {
    bcKey: 'Substitution_Allowed',
    type: 'boolean',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  vendorNo: {
    bcKey: 'Vendor_No',
    type: 'string',
    required: false,
  },
  post: {
    bcKey: 'Post',
    type: 'boolean',
    required: false,
  },
  postedQuantity: {
    bcKey: 'Posted_Quantity',
    type: 'number',
    required: false,
  },
  pendingQuantity: {
    bcKey: 'Pending_Quantity',
    type: 'number',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  estimatedBillingPrice: {
    bcKey: 'Estimated_Billing_Price',
    type: 'number',
    required: false,
  },
  actualBillingPrice: {
    bcKey: 'Actual_Billing_Price',
    type: 'number',
    required: false,
  },
  billingUnitPrice: {
    bcKey: 'Billing_Unit_Price',
    type: 'number',
    required: false,
  },
  originalWorkOrderNo: {
    bcKey: 'Original_Work_Order_No',
    type: 'string',
    required: false,
  },
  requirementDetails: {
    bcKey: 'Requirement_Details',
    type: 'string',
    required: false,
  },
  entryType: {
    bcKey: 'Entry_Type',
    type: 'string',
    required: false,
  },
  variantCode: {
    bcKey: 'Variant_Code',
    type: 'string',
    required: false,
  },
  binCode: {
    bcKey: 'Bin_Code',
    type: 'string',
    required: false,
  },
  purchaseOrderNo: {
    bcKey: 'Purchase_Order_No',
    type: 'string',
    required: false,
  },
  purchaseOrderLineNo: {
    bcKey: 'Purchase_Order_Line_No',
    type: 'number',
    required: false,
  },
  requisitionedQty: {
    bcKey: 'Requisitioned_Qty',
    type: 'number',
    required: false,
  },
  relatedQuantityPosted: {
    bcKey: 'Related_Quantity_Posted',
    type: 'number',
    required: false,
  },
  woReferenceInfo: {
    bcKey: 'WO_Reference_Info',
    type: 'string',
    required: false,
  },
  vendorName: {
    bcKey: 'Vendor_Name',
    type: 'string',
    required: false,
  },
  itemQuantityAllLocations: {
    bcKey: 'Item_Quantity_All_Locations',
    type: 'number',
    required: false,
  },
} as const satisfies ODataMap;

type RelatedRequirement = ODataObjectMap<typeof RequirementMap>;

const RelatedRequirementSchema = generateGraphQlSchema(
  'Requirement',
  RequirementMap
);

const RelatedRequirementQuery = generateGraphQlQuery(
  'Requirement',
  RequirementMap
);

export {
  RelatedRequirementSchema,
  type RelatedRequirement,
  RelatedRequirementQuery,
  RequirementMap,
};
