import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const JobDependencyMap = {
  jobNo: {
    bcKey: 'JobNo',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'JobTaskNo',
    type: 'string',
    required: false,
  },
  lineNo: {
    bcKey: 'LineNo',
    type: 'number',
    required: false,
  },
  taskSuccessor: {
    bcKey: 'TaskSuccessor',
    type: 'string',
    required: false,
  },
  succesorDescription: {
    bcKey: 'SuccesorDescription',
    type: 'string',
    required: false,
  },
  taskPredecessor: {
    bcKey: 'TaskPredecessor',
    type: 'string',
    required: false,
  },
  predecessorDescription: {
    bcKey: 'PredecessorDescription',
    type: 'string',
    required: false,
  },
  lag: {
    bcKey: 'Lag',
    type: 'number',
    required: false,
  },
  lagUnit: {
    bcKey: 'LagUnit',
    type: 'string',
    required: false,
  },
  connection: {
    bcKey: 'Connection',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type JobDependency = ODataObjectMap<typeof JobDependencyMap>;

const JobDependencySchema = generateGraphQlSchema(
  'JobDependency',
  JobDependencyMap
);

const JobDependencyQuery = generateGraphQlQuery(
  'JobDependency',
  JobDependencyMap
);

export {
  JobDependencySchema,
  JobDependencyQuery,
  type JobDependency,
  JobDependencyMap,
};
