import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const EquipmentGroupMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
} as const satisfies ODataMap;

type EquipmentGroup = ODataObjectMap<typeof EquipmentGroupMap>;

const EquipmentGroupSchema = generateGraphQlSchema(
  'EquipmentGroup',
  EquipmentGroupMap
);

const EquipmentGroupQuery = generateGraphQlQuery(
  'EquipmentGroup',
  EquipmentGroupMap
);

export {
  EquipmentGroupSchema,
  EquipmentGroupQuery,
  type EquipmentGroup,
  EquipmentGroupMap,
};
