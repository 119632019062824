import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const WorkOrderFeedbackHeaderMap = {
  tableName: {
    bcKey: 'Table_Name',
    type: 'string',
    required: true,
  },
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  documentNo: {
    bcKey: 'Document_No',
    type: 'string',
    required: true,
  },
  documentLineNo: {
    bcKey: 'Document_Line_No',
    type: 'number',
    required: true,
  },
  feedbackType: {
    bcKey: 'Feedback_Type',
    type: 'string',
    required: false,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  date: {
    bcKey: 'Date',
    type: 'date',
    required: false,
  },
  feedback: {
    bcKey: 'Feedback',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  createdDateTime: {
    bcKey: 'Created_Date_Time',
    type: 'date',
    required: false,
  },
  webServiceUser: {
    bcKey: 'Web_Service_User',
    type: 'string',
    required: false,
  },
  failureCode: {
    bcKey: 'Failure_Code',
    type: 'string',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  extendedFeedback: {
    bcKey: 'Extended_Feedback',
    type: 'boolean',
    required: false,
  },
  reasonCode: {
    bcKey: 'Reason_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;
type WorkOrderFeedbackHeader = ODataObjectMap<
  typeof WorkOrderFeedbackHeaderMap
>;

const WorkOrderFeedbackHeaderSchema = generateGraphQlSchema(
  'feedback',
  WorkOrderFeedbackHeaderMap
);

const WorkOrderFeedbackHeaderQuery = generateGraphQlQuery(
  'feedback',
  WorkOrderFeedbackHeaderMap
);

export {
  WorkOrderFeedbackHeaderSchema,
  type WorkOrderFeedbackHeader,
  WorkOrderFeedbackHeaderQuery,
  WorkOrderFeedbackHeaderMap,
};
