import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PartMap = {
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  details: {
    bcKey: 'Details',
    type: 'string',
    required: false,
  },
  variantCode: {
    bcKey: 'Variant_Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  expectedQuantity: {
    bcKey: 'Expected_Quantity',
    type: 'number',
    required: false,
  },
} as const satisfies ODataMap;

type Part = ODataObjectMap<typeof PartMap>;

const PartSchema = generateGraphQlSchema('part', PartMap);

const PartQuery = generateGraphQlQuery('part', PartMap);

export { PartSchema, type Part, PartQuery, PartMap };
