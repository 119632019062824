import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlSchema, generateGraphQlQuery } from '../utils';

const ToolMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  toolCost: {
    bcKey: 'Tool_Cost',
    type: 'number',
    required: false,
  },
} as const satisfies ODataMap;

type Tool = ODataObjectMap<typeof ToolMap>;

const ToolSchema = generateGraphQlSchema('Tool', ToolMap);

const ToolQuery = generateGraphQlQuery('Tool', ToolMap);

export { ToolSchema, ToolQuery, type Tool, ToolMap };
