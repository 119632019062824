import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ContactMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  searchName: {
    bcKey: 'Search_Name',
    type: 'string',
    required: false,
  },
  name2: {
    bcKey: 'Name_2',
    type: 'string',
    required: false,
  },
  address: {
    bcKey: 'Address',
    type: 'string',
    required: false,
  },
  address2: {
    bcKey: 'Address_2',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  phoneNo: {
    bcKey: 'Phone_No',
    type: 'string',
    required: false,
  },
  telexNo: {
    bcKey: 'Telex_No',
    type: 'string',
    required: false,
  },
  territoryCode: {
    bcKey: 'Territory_Code',
    type: 'string',
    required: false,
  },
  currencyCode: {
    bcKey: 'Currency_Code',
    type: 'string',
    required: false,
  },
  languageCode: {
    bcKey: 'Language_Code',
    type: 'string',
    required: false,
  },
  salespersonCode: {
    bcKey: 'Salesperson_Code',
    type: 'string',
    required: false,
  },
  countryRegionCode: {
    bcKey: 'Country_Region_Code',
    type: 'string',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
  lastDateModified: {
    bcKey: 'Last_Date_Modified',
    type: 'string',
    required: false,
  },
  faxNo: {
    bcKey: 'Fax_No',
    type: 'string',
    required: false,
  },
  telexAnswerBack: {
    bcKey: 'Telex_Answer_Back',
    type: 'string',
    required: false,
  },
  vatRegistrationNo: {
    bcKey: 'VAT_Registration_No',
    type: 'string',
    required: false,
  },
  postCode: {
    bcKey: 'Post_Code',
    type: 'string',
    required: false,
  },
  county: {
    bcKey: 'County',
    type: 'string',
    required: false,
  },
  eMail: {
    bcKey: 'E_Mail',
    type: 'string',
    required: false,
  },
  homePage: {
    bcKey: 'Home_Page',
    type: 'string',
    required: false,
  },
  noSeries: {
    bcKey: 'No_Series',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  companyNo: {
    bcKey: 'Company_No',
    type: 'string',
    required: false,
  },
  companyName: {
    bcKey: 'Company_Name',
    type: 'string',
    required: false,
  },
  businessRelCustNo: {
    bcKey: 'Business_Rel_Cust_No',
    type: 'string',
    required: false,
  },
  enterpriseRecCode: {
    bcKey: 'enterpriseRec_Code',
    type: 'string',
    required: false,
  },
  firstName: {
    bcKey: 'First_Name',
    type: 'string',
    required: false,
  },
  middleName: {
    bcKey: 'Middle_Name',
    type: 'string',
    required: false,
  },
  surname: {
    bcKey: 'Surname',
    type: 'string',
    required: false,
  },
  jobTitle: {
    bcKey: 'Job_Title',
    type: 'string',
    required: false,
  },
  initials: {
    bcKey: 'Initials',
    type: 'string',
    required: false,
  },
  extensionNo: {
    bcKey: 'Extension_No',
    type: 'string',
    required: false,
  },
  mobilePhoneNo: {
    bcKey: 'Mobile_Phone_No',
    type: 'string',
    required: false,
  },
  pager: {
    bcKey: 'Pager',
    type: 'string',
    required: false,
  },
  organizationalLevelCode: {
    bcKey: 'Organizational_Level_Code',
    type: 'string',
    required: false,
  },
  excludeFromSegment: {
    bcKey: 'Exclude_from_Segment',
    type: 'boolean',
    required: false,
  },
  dateFilter: {
    bcKey: 'Date_Filter',
    type: 'string',
    required: false,
  },
  tagPortalProfile: {
    bcKey: 'TAG_Portal_Profile',
    type: 'string',
    required: false,
  },
  tagUserSetup: {
    bcKey: 'TAG_User_Setup',
    type: 'string',
    required: false,
  },
  createdFrom: {
    bcKey: 'Created_From',
    type: 'string',
    required: false,
  },
  tpProfile: {
    bcKey: 'TP_Profile',
    type: 'string',
    required: false,
  },
  enterpriseDescription: {
    bcKey: 'Enterprise_Description',
    type: 'string',
    required: false,
  },
  createRequest: {
    bcKey: 'Create_Request',
    type: 'boolean',
    required: false,
  },
  createOrder: {
    bcKey: 'Create_Order',
    type: 'boolean',
    required: false,
  },
  createPurchase: {
    bcKey: 'Create_Purchase',
    type: 'boolean',
    required: false,
  },
  safetyContactType: {
    bcKey: 'Safety_Contact_Type',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Contact = ODataObjectMap<typeof ContactMap>;

const ContactSchema = generateGraphQlSchema('Contact', ContactMap);

const ContactQuery = generateGraphQlQuery('Contact', ContactMap);

export { ContactSchema, type Contact, ContactQuery, ContactMap };
