import { Injectable } from '@angular/core';
import {
  AuthenticatePayload,
  AuthenticationResponse,
  UserConfig,
} from '@api/types';
import { Observable, map, of } from 'rxjs';

import { ApiService } from '@services/api.service';
import { TagSetupGQL } from '@shared/apollo/queries/tag-setup';
import { TAGSetup } from '@tag/graphql';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { CustomTranslations } from '@transloco/transloco-root.module';
import { environment } from '@environments/environment';
import { LoginOption } from '@models/login-option';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationStoreService {
  constructor(
    private readonly api: ApiService,
    private tagSetupGQL: TagSetupGQL,
    private translate: TranslocoService
  ) {}

  login(body: AuthenticatePayload) {
    return this.api.post<AuthenticationResponse>('/auth/default', body);
  }

  customerLogin(body: AuthenticatePayload): Observable<AuthenticationResponse> {
    return this.api.post<AuthenticationResponse>('/auth/customer', body);
  }

  vendorLogin(body: AuthenticatePayload): Observable<AuthenticationResponse> {
    return this.api.post<AuthenticationResponse>('/auth/vendor', body);
  }

  requesterLogin(
    body: AuthenticatePayload,
    company: string
  ): Observable<AuthenticationResponse | undefined> {
    return this.api.post<AuthenticationResponse>('/auth/requester', body);
  }

  azureLogin(
    token: string,
    company: string,
    environmentId: string,
    impersonate?: string | null
  ): Observable<AuthenticationResponse> {
    try {
      const url = environment.skipLogin
        ? '/auth/azure/skip-auth'
        : '/auth/azure';
      return this.api.post<AuthenticationResponse>(url, {
        token,
        company,
        environmentId,
        impersonate,
      });
    } catch (error) {
      console.log(error);
      return of();
    }
  }

  changeCompany(company: string): Observable<AuthenticationResponse> {
    return this.api.post<AuthenticationResponse>(
      '/auth/change-company',
      company
    );
  }

  getTagSetup(): Observable<TAGSetup> {
    return this.tagSetupGQL
      .watch()
      .valueChanges.pipe(map((res) => res.data.tagSetups.items?.[0]));
  }

  saveSession(session: UserConfig): Observable<UserConfig> {
    return this.api.post('/user-config', session);
  }

  refreshToken(refreshToken: string): Observable<AuthenticationResponse> {
    return this.api.post('/auth/refresh-token', { refreshToken });
  }

  parseJwt(token: string): any {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    const data = JSON.parse(jsonPayload);

    //Convert boolean strings to boolean
    for (const key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        if (data[key].toLowerCase() === 'true') {
          data[key] = true;
        } else if (data[key].toLowerCase() === 'false') {
          data[key] = false;
        }
      }
    }

    return data;
  }

  setCustomTranslations(userInfo: AuthenticationResponse, reload = false) {
    const ct: any = CustomTranslations;

    const currentLang = this.translate.getActiveLang().substring(0, 2);
    const captionOverrides = userInfo.captions?.[currentLang];

    const setCustomKeys = (userInfo: AuthenticationResponse) => {
      Object.keys(ct).forEach((key) => {
        if ((userInfo.tagSetup as any)[ct[key]]) {
          this.translate.setTranslationKey(
            key,
            (userInfo.tagSetup as any)[ct[key]]
          );
        }
      });
    };

    if (captionOverrides) {
      this.api
        .get<Translation>(captionOverrides, {
          headers: {
            'skip-error-handler': '1',
          },
        })
        .subscribe((res) => {
          this.translate.setTranslation(res);
          setCustomKeys(userInfo);

          if (reload) {
            location.reload();
          }
        });
    } else {
      setCustomKeys(userInfo);
    }
  }

  logoutUrl(userType: LoginOption, environmentId: string) {
    switch (userType) {
      case LoginOption.customer:
      case LoginOption.vendor:
      case LoginOption.requester:
        return `/login/${environmentId}/${userType}`;

      default:
        return '/login';
    }
  }
}
