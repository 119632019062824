//ET-DONE date parameter
type PurchaseInvoiceLine = {
  allowInvoiceDisc: boolean;
  allowItemChargeAssignment: boolean;
  amountBeforeDiscount: number;
  applToItemEntry: boolean;
  binCode: string;
  blanketOrderLineNo: number;
  blanketOrderNo: string;
  budgetedFaNo: string;
  deferralCode: string;
  deprAcquisitionCost: number;
  deprUntilFaPostingDate: Date;
  depreciationBookCode: string;
  description: string;
  description2: string;
  directUnitCost: number;
  documentNo: string;
  documentType: string;
  duplicateInDepreciationBook: boolean;
  faPostingDate: Date;
  faPostingType: string;
  filteredTypeField: string;
  genBusPostingGroup: string;
  genProdPostingGroup: string;
  grossWeight: number;
  gstHst: boolean;
  icPartnerCode: string;
  icPartnerRefType: string;
  icPartnerReference: string;
  indirectCostPercent: number;
  insuranceNo: string;
  invDiscountAmount: number;
  invoiceDiscPct: number;
  invoiceDiscountAmount: number;
  irs1099Liable: boolean;
  itemReferenceNo: string;
  jobLineAmountLcy: number;
  jobLineAmount: number;
  jobLineDiscAmountLcy: number;
  jobLineDiscountAmount: number;
  jobLineDiscountPercent: number;
  jobLineType: string;
  jobNo: string;
  jobPlanningLineNo: number;
  jobTaskNo: number;
  jobTotalPriceLcy: number;
  jobTotalPrice: number;
  jobUnitPriceLcy: number;
  jobUnitPrice: number;
  lineAmount: number;
  lineDiscountAmount: number;
  lineDiscountPercent: number;
  lineNo: number;
  locationCode: string;
  netWeight: number;
  no: string;
  nonstock: boolean;
  prodOrderNo: string;
  provincialTaxAreaCode: string;
  qtyAssigned: number;
  qtyToAssign: number;
  quantity: number;
  returnReasonCode: string;
  shortcutDimension1Code: string;
  shortcutDimension2Code: string;
  shortcutDimCode3: string;
  shortcutDimCode4: string;
  shortcutDimCode5: string;
  shortcutDimCode6: string;
  shortcutDimCode7: string;
  shortcutDimCode8: string;
  taxAreaCode: string;
  taxGroupCode: string;
  taxLiable: boolean;
  totalAmountExclVat: number;
  totalAmountInclVat: number;
  totalVatAmount: number;
  type: string;
  unitCostLcy: number;
  unitOfMeasureCode: string;
  unitOfMeasure: string;
  unitPriceLcy: number;
  unitVolume: number;
  unitsPerParcel: number;
  useDuplicationList: boolean;
  useTax: boolean;
  variantCode: string;
  vatBusPostingGroup: string;
  vatProdPostingGroup: string;
};

const PurchaseInvoiceLineSchema = `#graphql
  type PurchaseInvoiceLine {
    allowInvoiceDisc: Boolean
    allowItemChargeAssignment: Boolean
    amountBeforeDiscount: Float
    applToItemEntry: Boolean
    binCode: String
    blanketOrderLineNo: Float
    blanketOrderNo: String
    budgetedFaNo: String
    deferralCode: String
    deprAcquisitionCost: Float
    deprUntilFaPostingDate: Date
    depreciationBookCode: String
    description: String
    description2: String
    directUnitCost: Float
    documentNo: String
    documentType: String
    duplicateInDepreciationBook: Boolean
    faPostingDate: Date
    faPostingType: String
    filteredTypeField: String
    genBusPostingGroup: String
    genProdPostingGroup: String
    grossWeight: Float
    gstHst: Boolean
    icPartnerCode: String
    icPartnerRefType: String
    icPartnerReference: String
    indirectCostPercent: Float
    insuranceNo: String
    invDiscountAmount: Float
    invoiceDiscPct: Float
    invoiceDiscountAmount: Float
    irs1099Liable: Boolean
    itemReferenceNo: String
    jobLineAmountLcy: Float
    jobLineAmount: Float
    jobLineDiscAmountLcy: Float
    jobLineDiscountAmount: Float
    jobLineDiscountPercent: Float
    jobLineType: String
    jobNo: String
    jobPlanningLineNo: Float
    jobTaskNo: Float
    jobTotalPriceLcy: Float
    jobTotalPrice: Float
    jobUnitPriceLcy: Float
    jobUnitPrice: Float
    lineAmount: Float
    lineDiscountAmount: Float
    lineDiscountPercent: Float
    lineNo: Float
    locationCode: String
    netWeight: Float
    no: String
    nonstock: Boolean
    prodOrderNo: String
    provincialTaxAreaCode: String
    qtyAssigned: Float
    qtyToAssign: Float
    quantity: Float
    returnReasonCode: String
    shortcutDimension1Code: String
    shortcutDimension2Code: String
    shortcutDimCode3: String
    shortcutDimCode4: String
    shortcutDimCode5: String
    shortcutDimCode6: String
    shortcutDimCode7: String
    shortcutDimCode8: String
    taxAreaCode: String
    taxGroupCode: String
    taxLiable: Boolean
    totalAmountExclVat: Float
    totalAmountInclVat: Float
    totalVatAmount: Float
    type: String
    unitCostLcy: Float
    unitOfMeasureCode: String
    unitOfMeasure: String
    unitPriceLcy: Float
    unitVolume: Float
    unitsPerParcel: Float
    useDuplicationList: Boolean
    useTax: Boolean
    variantCode: String
    vatBusPostingGroup: String
    vatProdPostingGroup: String
  }
`;

const PurchaseInvoiceLineQuery = `#graphql
query allPurchaseInvoiceLines(
  
  $filter: String
  $top: Int
  $skip: Int
  $search: String
  $orderBy: String
  $desc: Boolean
) {
  purchaseInvoiceLines(
    
    filter: $filter
    top: $top
    skip: $skip
    search: $search
    orderBy: $orderBy
    desc: $desc
  ) {
    items {
      allowInvoiceDisc
      allowItemChargeAssignment
      amountBeforeDiscount
      applToItemEntry
      binCode
      blanketOrderLineNo
      blanketOrderNo
      budgetedFaNo
      deferralCode
      deprAcquisitionCost
      deprUntilFaPostingDate
      depreciationBookCode
      description
      description2
      directUnitCost
      documentNo
      documentType
      duplicateInDepreciationBook
      faPostingDate
      faPostingType
      filteredTypeField
      genBusPostingGroup
      genProdPostingGroup
      grossWeight
      gstHst
      icPartnerCode
      icPartnerRefType
      icPartnerReference
      indirectCostPercent
      insuranceNo
      invDiscountAmount
      invoiceDiscPct
      invoiceDiscountAmount
      irs1099Liable
      itemReferenceNo
      jobLineAmount
      jobLineAmountLcy
      jobLineDiscAmountLcy
      jobLineDiscountAmount
      jobLineDiscountPercent
      jobLineType
      jobNo
      jobPlanningLineNo
      jobTaskNo
      jobTotalPrice
      jobTotalPriceLcy
      jobUnitPrice
      jobUnitPriceLcy
      lineAmount
      lineDiscountAmount
      lineDiscountPercent
      lineNo
      locationCode
      netWeight
      no
      nonstock
      prodOrderNo
      provincialTaxAreaCode
      qtyAssigned
      qtyToAssign
      quantity
      returnReasonCode
      shortcutDimension1Code
      shortcutDimension2Code
      shortcutDimCode3
      shortcutDimCode4
      shortcutDimCode5
      shortcutDimCode6
      shortcutDimCode7
      shortcutDimCode8
      taxAreaCode
      taxGroupCode
      taxLiable
      totalAmountExclVat
      totalAmountInclVat
      totalVatAmount
      type
      unitCostLcy
      unitOfMeasure
      unitOfMeasureCode
      unitPriceLcy
      unitVolume
      unitsPerParcel
      useDuplicationList
      useTax
      variantCode
      vatBusPostingGroup
      vatProdPostingGroup
    }
    totalCount
  }
}
`;

export {
  PurchaseInvoiceLineSchema,
  type PurchaseInvoiceLine,
  PurchaseInvoiceLineQuery,
};
