import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ExpenseBatchMap = {
  batchType: {
    bcKey: 'Batch_Type',
    type: 'string',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  defaultWorkProcedure: {
    bcKey: 'Default_Work_Procedure',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ExpenseBatch = ODataObjectMap<typeof ExpenseBatchMap>;

const ExpenseBatchSchema = generateGraphQlSchema(
  'ExpenseBatch',
  ExpenseBatchMap
);

const ExpenseBatchQuery = generateGraphQlQuery('ExpenseBatch', ExpenseBatchMap);

export {
  ExpenseBatchSchema,
  ExpenseBatchQuery,
  type ExpenseBatch,
  ExpenseBatchMap,
};
