import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const DocumentTrackingMap = {
  udn: {
    bcKey: 'udn',
    type: 'string',
    required: false,
  },
  documentNo: {
    bcKey: 'Document_No',
    type: 'string',
    required: false,
  },
  actionType: {
    bcKey: 'Action_Type',
    type: 'string',
    required: false,
  },
  fromDocumentNo: {
    bcKey: 'From_Document_No',
    type: 'string',
    required: false,
  },
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: false,
  },
  dateOfAction: {
    bcKey: 'Date_of_Action',
    type: 'string',
    required: false,
  },
  timeOfAction: {
    bcKey: 'Time_of_Action',
    type: 'string',
    required: false,
  },
  userId: {
    bcKey: 'User_ID',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type DocumentTracking = ODataObjectMap<typeof DocumentTrackingMap>;

const DocumentTrackingSchema = generateGraphQlSchema(
  'DocumentTracking',
  DocumentTrackingMap
);
const DocumentTrackingQuery = generateGraphQlQuery(
  'DocumentTracking',
  DocumentTrackingMap
);

export {
  DocumentTrackingSchema,
  DocumentTrackingQuery,
  type DocumentTracking,
  DocumentTrackingMap,
};
