import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const AreaMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: true,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Area = ODataObjectMap<typeof AreaMap>;

const AreaSchema = generateGraphQlSchema('Area', AreaMap);

const AreaQuery = generateGraphQlQuery('Area', AreaMap);

export { AreaSchema, AreaQuery, type Area, AreaMap };
