import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const ItemIdentifierMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  itemNo: {
    bcKey: 'Item_No',
    type: 'string',
    required: false,
  },
  variantCode: {
    bcKey: 'Variant_Code',
    type: 'string',
    required: false,
  },
  unitOfMeasureCode: {
    bcKey: 'Unit_of_Measure_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type ItemIdentifier = ODataObjectMap<typeof ItemIdentifierMap>;

const ItemIdentifierSchema = generateGraphQlSchema(
  'ItemIdentifier',
  ItemIdentifierMap
);

const ItemIdentifierQuery = generateGraphQlQuery(
  'ItemIdentifier',
  ItemIdentifierMap
);

export {
  ItemIdentifierSchema,
  ItemIdentifierQuery,
  type ItemIdentifier,
  ItemIdentifierMap,
};
