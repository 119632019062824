import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const PurchaseOrderLineMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  documentNo: {
    bcKey: 'Document_No',
    type: 'string',
    required: true,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: true,
  },
  buyFromVendorNo: {
    bcKey: 'Buy_from_Vendor_No',
    type: 'string',
    required: false,
  },
  type: {
    bcKey: 'Type',
    type: 'string',
    required: false,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  variantCode: {
    bcKey: 'Variant_Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  description2: {
    bcKey: 'Description_2',
    type: 'string',
    required: false,
  },
  locationCode: {
    bcKey: 'Location_Code',
    type: 'string',
    required: false,
  },
  quantity: {
    bcKey: 'Quantity',
    type: 'number',
    required: false,
  },
  reservedQtyBase: {
    bcKey: 'Reserved_Qty_Base',
    type: 'number',
    required: false,
  },
  unitOfMeasureCode: {
    bcKey: 'Unit_of_Measure_Code',
    type: 'string',
    required: false,
  },
  directUnitCost: {
    bcKey: 'Direct_Unit_Cost',
    type: 'number',
    required: false,
  },
  indirectCostPercent: {
    bcKey: 'Indirect_Cost_Percent',
    type: 'number',
    required: false,
  },
  unitCostLcy: {
    bcKey: 'Unit_Cost_LCY',
    type: 'number',
    required: false,
  },
  unitPriceLcy: {
    bcKey: 'Unit_Price_LCY',
    type: 'number',
    required: false,
  },
  lineAmount: {
    bcKey: 'Line_Amount',
    type: 'number',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'Job_Task_No',
    type: 'string',
    required: false,
  },
  jobLineType: {
    bcKey: 'Job_Line_Type',
    type: 'string',
    required: false,
  },
  jobDescription: {
    bcKey: 'Job_Description',
    type: 'string',
    required: false,
  },
  jobTaskDescription: {
    bcKey: 'Job_Task_Description',
    type: 'string',
    required: false,
  },
  expectedReceiptDate: {
    bcKey: 'Expected_Receipt_Date',
    type: 'date',
    required: false,
  },
  outstandingQuantity: {
    bcKey: 'Outstanding_Quantity',
    type: 'number',
    required: false,
  },
  outstandingAmountLcy: {
    bcKey: 'Outstanding_Amount_LCY',
    type: 'number',
    required: false,
  },
  amtRcdNotInvoicedLcy: {
    bcKey: 'Amt_Rcd_Not_Invoiced_LCY',
    type: 'number',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  tagWoLineNo: {
    bcKey: 'TAG_WO_Line_No',
    type: 'number',
    required: false,
  },
  tagWoReferenceInfo: {
    bcKey: 'TAG_WO_Reference_Info',
    type: 'string',
    required: false,
  },
  tagWoRelReqLineNo: {
    bcKey: 'TAG_WO_Rel_Req_Line_No',
    type: 'number',
    required: false,
  },
  tagWoSourceNo: {
    bcKey: 'TAG_WO_Source_No',
    type: 'string',
    required: false,
  },
  quantityReceived: {
    bcKey: 'Quantity_Received',
    type: 'number',
    required: false,
  },
  qtyToReceive: {
    bcKey: 'Qty_to_Receive',
    type: 'number',
    required: true,
  },
} as const satisfies ODataMap;

type PurchaseOrderLine = ODataObjectMap<typeof PurchaseOrderLineMap>;

const PurchaseOrderLineSchema = generateGraphQlSchema(
  'PurchaseOrderLine',
  PurchaseOrderLineMap
);

const PurchaseOrderLineQuery = generateGraphQlQuery(
  'purchaseOrderLine',
  PurchaseOrderLineMap
);

export {
  PurchaseOrderLineSchema,
  type PurchaseOrderLine,
  PurchaseOrderLineQuery,
  PurchaseOrderLineMap,
};
