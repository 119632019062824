import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const LineMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
} as const satisfies ODataMap;

type Line = ODataObjectMap<typeof LineMap>;

const LineSchema = generateGraphQlSchema('Line', LineMap);

const LineQuery = generateGraphQlQuery('Line', LineMap);

export { LineSchema, LineQuery, type Line, LineMap };
