import { AuthenticatePayload, UserConfig } from '@api/types';

import { LoginOption } from '@models/login-option';

/**
 * Login to the Tag API.
 *
 * @param payload
 * @param company
 */
export class Login {
  static readonly type = '[Auth] Login';
  constructor(
    public payload: AuthenticatePayload,
    public type: LoginOption,
    public company?: string | null
  ) {}
}

/**
 * Login to the Tag API from Azure AD.
 *
 * @param payload
 * @param company
 */
export class AzureLogin {
  static readonly type = '[Auth] Azure Login';
  constructor(
    public token: string,
    public type: LoginOption,
    public environmentId: string,
    public company?: string | null,
    public impersonate?: string | null
  ) {}
}

/**
 * Login to the Tag API.
 *
 * @param payload
 * @param company
 */
export class CustomerLogin {
  static readonly type = '[Auth] Customer Login';
  constructor(
    public payload: AuthenticatePayload,
    public type: LoginOption,
    public company?: string | null
  ) {}
}

/**
 * Login to the Tag API.
 *
 * @param payload
 * @param company
 */
export class VendorLogin {
  static readonly type = '[Auth] Vendor Login';
  constructor(
    public payload: AuthenticatePayload,
    public type: LoginOption,
    public company?: string | null
  ) {}
}

/**
 * Login to the Tag API.
 *
 * @param payload
 * @param company
 */
export class RequesterLogin {
  static readonly type = '[Auth] Requester Login';
  constructor(
    public payload: AuthenticatePayload,
    public type: LoginOption,
    public company?: string | null
  ) {}
}

export class GetTagSetup {
  static readonly type = '[Auth] Get Tag Setup';

  constructor() {}
}

/**
 * Get current user session.
 *
 * @param payload
 */
export class GetSession {
  static readonly type = '[Auth] Session';
  constructor() {}
}

/**
 * Save current user session.
 *
 * @param session
 */
export class SaveSession {
  static readonly type = '[Auth] Save Session';
  constructor(public session: UserConfig) {}
}

/**
 * Get current user session.
 *
 * @param type
 */
export class SetPortalType {
  static readonly type = '[Auth] Set Portal Type';
  constructor(public type: LoginOption) {}
}

/**
 * Refresh API Token.
 */
export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
}

/**
 * Change Company.
 */
export class ChangeCompany {
  static readonly type = '[Auth] Change Company';
  constructor(public company: string, public noRefresh?: boolean) {}
}

/**
 * Logout to the Tag API.
 */
export class Logout {
  static readonly type = '[Auth] Logout';
}

/**
 * Set display data format.
 */
export class SetDisplayDateFormat {
  static readonly type = '[Auth] Set Display Date Format';
  constructor(public format: string, public force?: boolean) {}
}

/**
 * Set display data format.
 */
export class SetPipeDisplayDateFormat {
  static readonly type = '[Auth] Set Pipe Display Date Format';
  constructor(public format: string, public force?: boolean) {}
}
