import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const FinishedWorkOrderLineMap = {
  workOrderNo: {
    bcKey: 'Work_Order_No',
    type: 'string',
    required: false,
  },
  lineNo: {
    bcKey: 'Line_No',
    type: 'number',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  estimatedTime: {
    bcKey: 'Estimated_Time',
    type: 'number',
    required: false,
  },
  actualTime: {
    bcKey: 'Actual_Time',
    type: 'number',
    required: false,
  },
  workProcedureStep: {
    bcKey: 'Work_Procedure_Step',
    type: 'string',
    required: false,
  },
  requestedServiceDate: {
    bcKey: 'Requested_Service_Date',
    type: 'string',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  expirationDate: {
    bcKey: 'Expiration_Date',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  startingDatetime: {
    bcKey: 'Starting_Datetime',
    type: 'date',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  finished: {
    bcKey: 'Finished',
    type: 'boolean',
    required: false,
  },
  resultDatetime: {
    bcKey: 'Result_Datetime',
    type: 'date',
    required: false,
  },
  resultsValue: {
    bcKey: 'Results_Value',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  equipMain: {
    bcKey: 'Equip_Main',
    type: 'string',
    required: false,
  },
  equipPrimary: {
    bcKey: 'Equip_Primary',
    type: 'string',
    required: false,
  },
  equipSecondary: {
    bcKey: 'Equip_Secondary',
    type: 'string',
    required: false,
  },
  endingDatetime: {
    bcKey: 'Ending_Datetime',
    type: 'date',
    required: false,
  },
  creationDate: {
    bcKey: 'Creation_Date',
    type: 'string',
    required: false,
  },
  originalRoNo: {
    bcKey: 'Original_RO_No',
    type: 'string',
    required: false,
  },
  lastModified: {
    bcKey: 'Last_Modified',
    type: 'date',
    required: false,
  },
  approvalStatus: {
    bcKey: 'Approval_Status',
    type: 'string',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'Job_Task_No',
    type: 'string',
    required: false,
  },
  technicianName: {
    bcKey: 'Technician_Name',
    type: 'string',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type FinishedWorkOrderLine = ODataObjectMap<typeof FinishedWorkOrderLineMap>;

const FinishedWorkOrderLineSchema = generateGraphQlSchema(
  'FinishedWorkOrderLine',
  FinishedWorkOrderLineMap
);

const FinishedWorkOrderLineQuery = generateGraphQlQuery(
  'FinishedWorkOrderLine',
  FinishedWorkOrderLineMap
);

export {
  FinishedWorkOrderLineSchema,
  type FinishedWorkOrderLine,
  FinishedWorkOrderLineQuery,
  FinishedWorkOrderLineMap,
};
