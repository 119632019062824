import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const CriticalityMap = {
  code: {
    bcKey: 'Code',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  priorityRank: {
    bcKey: 'Priority_Rank',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Criticality = ODataObjectMap<typeof CriticalityMap>;

const CriticalitySchema = generateGraphQlSchema('Criticality', CriticalityMap);

const CriticalityQuery = generateGraphQlQuery('Criticality', CriticalityMap);

export {
  CriticalitySchema,
  CriticalityQuery,
  type Criticality,
  CriticalityMap,
};
