import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Buffer } from 'buffer';
import * as Sentry from '@sentry/browser';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Observable, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { LoginOption } from '@models/login-option';
import {
  AzureLogin,
  ChangeCompany,
  CustomerLogin,
  GetTagSetup,
  Login,
  Logout,
  RefreshToken,
  RequesterLogin,
  SaveSession,
  SetDisplayDateFormat,
  SetPipeDisplayDateFormat,
  SetPortalType,
  VendorLogin,
} from '@stores-actions/authentication.action';
import { AddNotification } from '@stores-actions/notification.action';
import { AuthenticationStoreService } from '@stores-services/authentication-store.service';
import {
  AuthenticationResponse,
  UserAndMobileConfig,
  UserConfig,
  UserInfo,
} from '@api/types';
import { TAGSetup } from '@tag/graphql';
import { TranslocoService } from '@ngneat/transloco';

export interface AuthStateModel {
  token: string | null;
  permissions: UserInfo | null;
  refreshToken: string | null;
  username: string;
  userInfo: UserInfo | null;
  session: UserConfig | null;
  profileSession: UserConfig | null;
  portalType: LoginOption;
  company: string;
  technician: string | null;
  isAzure: boolean;
  displayDateFormat: string;
  pipeDisplayDateFormat: string;
  displayDateFormatCustomized: boolean;
  union: string;
  setup: TAGSetup | null;
  versionMismatch: boolean;
  captions: Record<string, string>;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    permissions: null,
    refreshToken: null,
    username: '',
    userInfo: null,
    session: null,
    profileSession: null,
    portalType: LoginOption.default,
    company: '',
    technician: null,
    isAzure: false,
    displayDateFormat: '',
    pipeDisplayDateFormat: 'short',
    displayDateFormatCustomized: false,
    union: '',
    setup: null,
    versionMismatch: false,
    captions: {},
  },
})
@Injectable()
export class AuthState {
  constructor(
    private readonly authService: AuthenticationStoreService,
    private readonly store: Store,
    private router: Router,
    private translate: TranslocoService
  ) {}

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  static username(state: AuthStateModel): string {
    return state.username;
  }

  @Selector()
  static isTa(state: AuthStateModel): boolean {
    return !!state.userInfo?.useTa;
  }

  @Selector()
  static isDocStore(state: AuthStateModel): boolean {
    return !!state.userInfo?.useVsdDocStor;
  }

  @Selector()
  static userInfo(state: AuthStateModel): UserInfo | null {
    return state.userInfo;
  }

  @Selector()
  static isTimeSheet(state: AuthStateModel): boolean {
    return !!state.userInfo?.useConsumeTimesheet;
  }

  @Selector()
  static allowJobModule(state: AuthStateModel): boolean {
    return !!state.userInfo?.allowJobInvoicing;
  }

  @Selector()
  static session(state: AuthStateModel): UserConfig | null {
    return state.session;
  }

  @Selector()
  static permissions(state: AuthStateModel): UserInfo | null {
    return state.permissions;
  }

  @Selector()
  static profileSession(state: AuthStateModel): UserConfig | null {
    return state.profileSession;
  }

  @Selector()
  static portalType(state: AuthStateModel): LoginOption {
    return state.portalType;
  }

  @Selector()
  static company(state: AuthStateModel): string {
    return state.company;
  }

  @Selector()
  static union(state: AuthStateModel): string {
    return state.union;
  }

  @Selector()
  static setup(state: AuthStateModel): TAGSetup | null {
    return state.setup;
  }

  @Selector()
  static versionMismatch(state: AuthStateModel): boolean {
    return state.versionMismatch;
  }

  @Selector()
  static technician(state: AuthStateModel): string | null {
    return state.technician;
  }

  @Selector()
  static captions(state: AuthStateModel) {
    return state.captions;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    const token = state.refreshToken;
    if (!token) return false;

    // Parse the token to get the expiration date
    const payload = JSON.parse(
      Buffer.from(token.split('.')[1], 'base64').toString()
    );
    const expirationDate = new Date(payload.exp * 1000);

    // Check if the token is expired
    if (expirationDate <= new Date()) return false;
    return true;
  }

  @Selector()
  static displayDataFormat(state: AuthStateModel): string {
    return state.displayDateFormat;
  }

  @Selector()
  static pipeDisplayDataFormat(state: AuthStateModel): string {
    return state.pipeDisplayDateFormat;
  }

  @Selector()
  static getEmployeeNo(state: AuthStateModel): string {
    // TODO - Wait for the new API models to be ready
    const userInfo = state.userInfo as any;
    return userInfo.employee_No;
  }

  @Action(Login)
  login(
    ctx: StateContext<AuthStateModel>,
    action: Login
  ): Observable<AuthenticationResponse> {
    const company = action.company;
    return this.authService.login(action.payload).pipe(
      tap((result: AuthenticationResponse) => {
        const obj = result.config?.userConfig as any;
        Object.keys(obj)
          .filter((k) => obj[k] === null)
          .forEach((k) => delete obj[k]);
        const mergedSession = {
          ...result.config?.mobileUserConfig,
          ...(obj as UserConfig),
        };

        ctx.patchState({
          token: result.token,
          permissions: this.authService.parseJwt(result.token),
          refreshToken: result.refreshToken,
          username: result.userId.toUpperCase(),
          userInfo: result.userInfo,
          setup: result.tagSetup,
          portalType: (action.type === LoginOption.manager
            ? result.userInfo.type.toLowerCase()
            : action.type) as LoginOption,
          company:
            result.userInfo.selectedCompany || result.userInfo.companies[0],
          technician: result.userInfo.technicianId,
          union: result.userInfo.unionNo,
          session: mergedSession,
          profileSession: result.config?.mobileUserConfig,
        });

        // H.identify(result.userId.toUpperCase(), {
        //   username: result.userId.toUpperCase(),
        //   portalType: action.type,
        //   company:
        //     result.userInfo.selectedCompany || result.userInfo.companies[0],
        //   technician: result.userInfo.technicianId,
        // });
      })
    );
  }

  @Action(CustomerLogin)
  customerLogin(
    ctx: StateContext<AuthStateModel>,
    action: CustomerLogin
  ): Observable<AuthenticationResponse> {
    return this.authService.customerLogin(action.payload).pipe(
      tap((result: AuthenticationResponse) => {
        ctx.patchState({
          token: result.token,
          refreshToken: result.refreshToken,
          permissions: this.authService.parseJwt(result.token),
          username: result.userId.toUpperCase(),
          userInfo: result.userInfo,
          setup: result.tagSetup,
          portalType: action.type,
          company:
            result.userInfo.selectedCompany || result.userInfo.companies[0],
          technician: result.userInfo.technicianId,
          session: result.config?.mobileUserConfig,
        });
      })
    );
  }

  @Action(VendorLogin)
  vendorLogin(
    ctx: StateContext<AuthStateModel>,
    action: VendorLogin
  ): Observable<AuthenticationResponse> {
    return this.authService.vendorLogin(action.payload).pipe(
      tap((result: AuthenticationResponse) => {
        ctx.patchState({
          token: result.token,
          refreshToken: result.refreshToken,
          permissions: this.authService.parseJwt(result.token),
          username: result.userId.toUpperCase(),
          userInfo: result.userInfo,
          setup: result.tagSetup,
          portalType: action.type,
          company:
            result.userInfo.selectedCompany || result.userInfo.companies[0],
          technician: result.userInfo.technicianId,
          session: result.config?.mobileUserConfig,
        });

        // H.identify(result.userId.toUpperCase(), {
        //   username: result.userId.toUpperCase(),
        //   portalType: action.type,
        //   company:
        //     result.userInfo.selectedCompany || result.userInfo.companies[0],
        //   technician: result.userInfo.technicianId,
        // });
      })
    );
  }

  @Action(RequesterLogin)
  requesterLogin(ctx: StateContext<AuthStateModel>, action: RequesterLogin) {
    let company =
      action.company || this.store.selectSnapshot(AuthState.company);

    return this.authService.requesterLogin(action.payload, company).pipe(
      tap((result) => {
        if (!result) return;

        ctx.patchState({
          token: result.token,
          refreshToken: result.refreshToken,
          permissions: this.authService.parseJwt(result.token),
          username: result.userId.toUpperCase(),
          userInfo: result.userInfo,
          portalType: action.type,
          setup: result.tagSetup,
          company:
            result.userInfo.selectedCompany || result.userInfo.companies[0],
          technician: result.userInfo.technicianId,
          session: result.config?.mobileUserConfig,
        });

        // H.identify(result.userId.toUpperCase(), {
        //   username: result.userId.toUpperCase(),
        //   portalType: action.type,
        //   company:
        //     result.userInfo.selectedCompany || result.userInfo.companies[0],
        //   technician: result.userInfo.technicianId,
        // });
      })
    );
  }

  @Action(AzureLogin)
  azureLogin(
    ctx: StateContext<AuthStateModel>,
    action: AzureLogin
  ): Observable<AuthenticationResponse> {
    // Issues with the azure login. TODO - Fix on the API side se we can add this back.
    // let company = this.store.selectSnapshot(AuthState.company);
    // if (action.company) company = action.company;
    return this.authService
      .azureLogin(
        action.token,
        action.company || '',
        action.environmentId,
        action.impersonate
      )
      .pipe(
        tap((result: AuthenticationResponse) => {
          try {
            ctx.patchState({
              token: result.token,
              refreshToken: result.refreshToken,
              permissions: this.authService.parseJwt(result.token),
              username: result.userId.toUpperCase(),
              userInfo: result.userInfo,
              portalType: (action.type === LoginOption.technician &&
              result.userInfo.type.toLowerCase() === LoginOption.manager
                ? LoginOption.technician
                : result.userInfo.type.toLowerCase()) as LoginOption,
              company:
                result.userInfo.selectedCompany || result.userInfo.companies[0],
              technician: result.userInfo.technicianId,
              isAzure: true,
              session: result.config?.userConfig,
              profileSession: result.config?.mobileUserConfig,
              setup: result.tagSetup,
              versionMismatch: this.isVersionMismatch(result.userInfo),
              captions: result.captions,
            });

            Sentry.setUser({
              name: result.userInfo.technicianName,
              email: result.userInfo.eMail,

              // Add your own custom user variables here, ie:
              environment: result.userInfo.environmentId,
              company: result.userInfo.selectedCompany,
              mobileProfile: result.userInfo.mobileProfile,
            });

            this.authService.setCustomTranslations(result);
          } catch (error) {
            console.error('Error parsing personalization', error);
          }
        })
      );
  }

  @Action(GetTagSetup)
  getTagSetup(ctx: StateContext<AuthStateModel>): Observable<TAGSetup> {
    return this.authService.getTagSetup().pipe(
      tap((result) => {
        ctx.patchState({
          setup: result,
        });
      })
    );
  }

  @Action(ChangeCompany)
  changeCompany(
    ctx: StateContext<AuthStateModel>,
    { company, noRefresh }: ChangeCompany
  ): Observable<AuthenticationResponse> {
    return this.authService.changeCompany(company).pipe(
      tap((result: AuthenticationResponse) => {
        if (result.userInfo) {
          ctx.patchState({
            permissions: this.authService.parseJwt(result.token),
            userInfo: result.userInfo,
            company: result.userInfo.selectedCompany,
            token: result.token,
            refreshToken: result.refreshToken,
            technician: result.userInfo.technicianId,
            union: result.userInfo.unionNo,
            setup: result.tagSetup,
            versionMismatch: this.isVersionMismatch(result.userInfo),
            captions: result.captions,
          });

          Sentry.setUser({
            name: result.userInfo.technicianName,
            email: result.userInfo.eMail,

            // Add your own custom user variables here, ie:
            environment: result.userInfo.environmentId,
            company: result.userInfo.selectedCompany,
            mobileProfile: result.userInfo.mobileProfile,
          });

          this.authService.setCustomTranslations(result, !noRefresh);

          this.store.dispatch(new StateResetAll(AuthState));
        }
      })
    );
  }

  @Action(SaveSession, { cancelUncompleted: true })
  saveSession(ctx: StateContext<AuthStateModel>, { session }: SaveSession) {
    // New promise with a timeout to prevent spamming the network with cancelled calls.
    return from(
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 500);
      })
    ).pipe(
      switchMap(() =>
        this.authService.saveSession(session).pipe(
          tap((result: UserConfig) => {
            ctx.patchState({
              session: result,
            });
          })
        )
      )
    );
  }

  @Action(SetPortalType)
  setPortalType(
    { getState, setState }: StateContext<AuthStateModel>,
    { type }: SetPortalType
  ): void {
    const state = getState();
    setState({
      ...state,
      portalType: type,
    });
  }

  @Action(RefreshToken)
  refreshToken(
    ctx: StateContext<AuthStateModel>
  ): Observable<AuthenticationResponse> {
    const state = ctx.getState();
    return this.authService.refreshToken(state.refreshToken || '').pipe(
      tap((result) => {
        ctx.patchState({
          token: result.token,
          refreshToken: result.refreshToken,
          permissions: this.authService.parseJwt(result.token),
          userInfo: result.userInfo,
          session: result.config?.userConfig,
          setup: result.tagSetup,
          versionMismatch: this.isVersionMismatch(result.userInfo),
          captions: result.captions,
        });

        this.authService.setCustomTranslations(result);
      })
    );
  }

  @Action(Logout)
  async logout({
    getState,
    setState,
    patchState,
  }: StateContext<AuthStateModel>): Promise<void> {
    const state = getState();
    const company = state.company;
    const token = state.token;

    // Remove token so the login won't redirect to dashboard
    patchState({
      token: '',
    });

    // Validating if anything is preventing the redirect to login (e.g. Form Validation)
    const isLoggedOut = await this.router.navigateByUrl(
      this.authService.logoutUrl(
        state.portalType,
        state.userInfo?.environmentId || ''
      )
    );

    // If the redirect is successful, delete all cached data except for the company ( the rest is done in the AppComponent subscriber )
    if (isLoggedOut) {
      setState({
        token: null,
        permissions: null,
        refreshToken: null,
        username: '',
        userInfo: null,
        session: null,
        profileSession: null,
        portalType: LoginOption.default,
        technician: null,
        isAzure: false,
        displayDateFormat: '',
        pipeDisplayDateFormat: 'short',
        displayDateFormatCustomized: false,
        union: '',
        setup: null,
        company,
        versionMismatch: false,
        captions: {},
      });
    } else {
      setState({
        ...state,
        token,
        company,
      });
      throw new Error('User blocked the logout action from Portal.');
    }
  }

  @Action(SetDisplayDateFormat)
  setDisplayDateFormat(
    { getState, setState }: StateContext<AuthStateModel>,
    { format, force }: SetDisplayDateFormat
  ): void {
    const state = getState();
    setState({
      ...state,
      displayDateFormat: format,
    });
  }

  @Action(SetPipeDisplayDateFormat)
  setPipeDisplayDateFormat(
    { getState, setState }: StateContext<AuthStateModel>,
    { format, force }: SetDisplayDateFormat
  ): void {
    const state = getState();
    if (state.displayDateFormatCustomized && !force) return;
    setState({
      ...state,
      pipeDisplayDateFormat: format,
      displayDateFormatCustomized: !!force,
    });
  }

  isVersionMismatch(userInfo: UserInfo) {
    if (!userInfo?.version || !userInfo?.recommendedVersion) return true;

    const parsedVersion = parseInt(userInfo?.version?.replaceAll('.', ''));
    const parsedRecommendedVersion = parseInt(
      userInfo?.recommendedVersion?.replaceAll('.', '')
    );

    return parsedVersion < parsedRecommendedVersion;
  }
}
