import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const VendorMap = {
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  locationCode: {
    bcKey: 'Location_Code',
    type: 'string',
    required: false,
  },
  phoneNo: {
    bcKey: 'Phone_No',
    type: 'string',
    required: false,
  },
  contact: {
    bcKey: 'Contact',
    type: 'string',
    required: false,
  },
  searchName: {
    bcKey: 'Search_Name',
    type: 'string',
    required: false,
  },
  address: {
    bcKey: 'Address',
    type: 'string',
    required: false,
  },
  address2: {
    bcKey: 'Address_2',
    type: 'string',
    required: false,
  },
  countryRegionCode: {
    bcKey: 'Country_Region_Code',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  postCode: {
    bcKey: 'Post_Code',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type Vendor = ODataObjectMap<typeof VendorMap>;

const VendorSchema = generateGraphQlSchema('Vendor', VendorMap);

const VendorQuery = generateGraphQlQuery('Vendor', VendorMap);

export { VendorSchema, type Vendor, VendorQuery, VendorMap };
