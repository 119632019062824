//ET-DONE date totalcount
type EmployeeGroupSchedule = {
  auxiliaryIndex1: string;
  auxiliaryIndex2: string;
  auxiliaryIndex3: number;
  calendarCode: string;
  calendarStartDate: Date;
  dayNumber: number;
  dayType: string;
  employeeGroupCode: string;
  endTime: string;
  startTime: string;
  totalDurationMinutes: number;
  weekNo: number;
  weeklyCalendarCode: string;
  weeklyCandarStartDate: Date;
};

const EmployeeGroupScheduleSchema = `#graphql
  type EmployeeGroupSchedule {
    auxiliaryIndex1: String
    auxiliaryIndex2: String
    auxiliaryIndex3: String
    calendarCode: String
    calendarStartDate: Date
    dayNumber: Float
    dayType: String
    employeeGroupCode: String
    endTime: String
    startTime: String
    totalDurationMinutes: Float
    weekNo: String
    weeklyCalendarCode: String
    weeklyCandarStartDate: Date
  }
`;

const EmployeeGroupScheduleQuery = `#graphql
query allEmployeeGroupSchedules($filter: String) {
  employeeGroupSchedules(filter: $filter) {
    items {
      auxiliaryIndex1
      auxiliaryIndex2
      auxiliaryIndex3
      calendarCode
      calendarStartDate
      dayNumber
      dayType
      employeeGroupCode
      endTime
      startTime
      totalDurationMinutes
      weekNo
      weeklyCalendarCode
      weeklyCandarStartDate
    }
    totalCount
  }
}
`;

export {
  EmployeeGroupScheduleSchema,
  EmployeeGroupScheduleQuery,
  type EmployeeGroupSchedule,
};
