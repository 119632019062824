//ET-DONE date parameter
type PurchaseInvoice = {
  amount: number;
  assignedUserId: string;
  buyFromContact: string;
  buyFromCountryRegionCode: string;
  buyFromPostCode: string;
  buyFromVendorName: string;
  buyFromVendorNo: string;
  currencyCode: string;
  documentDate: Date;
  documentType: string;
  dueDate: Date;
  jobQueueStatus: string;
  locationCode: string;
  no: string;
  orderAddressCode: string;
  payToContact: string;
  payToCountryRegionCode: string;
  payToName: string;
  payToPostCode: string;
  payToVendorNo: string;
  paymentDiscountPercent: number;
  paymentMethodCode: string;
  paymentTermsCode: string;
  postingDate: Date;
  purchaserCode: string;
  requestedReceiptDate: Date;
  shipToCode: string;
  shipToContact: string;
  shipToCountryRegionCode: string;
  shipToName: string;
  shipToPostCode: string;
  shipmentMethodCode: string;
  shortcutDimension1Code: string;
  shortcutDimension2Code: string;
  status: string;
  vendorAuthorizationNo: string;
  vendorInvoiceNo: string;
  yourReference: string;
};

const PurchaseInvoiceSchema = `#graphql
  type PurchaseInvoice {
    amount: Float
    assignedUserId: String
    buyFromContact: String
    buyFromCountryRegionCode: String
    buyFromPostCode: String
    buyFromVendorName: String
    buyFromVendorNo: String
    currencyCode: String
    documentDate: Date
    documentType: String
    dueDate: Date
    jobQueueStatus: String
    locationCode: String
    no: String
    orderAddressCode: String
    payToContact: String
    payToCountryRegionCode: String
    payToName: String
    payToPostCode: String
    payToVendorNo: String
    paymentDiscountPercent: Float
    paymentMethodCode: String
    paymentTermsCode: String
    postingDate: Date
    purchaserCode: String
    requestedReceiptDate: Date
    shipToCode: String
    shipToContact: String
    shipToCountryRegionCode: String
    shipToName: String
    shipToPostCode: String
    shipmentMethodCode: String
    shortcutDimension1Code: String
    shortcutDimension2Code: String
    status: String
    vendorAuthorizationNo: String
    vendorInvoiceNo: String
    yourReference: String
  }
`;

const PurchaseInvoiceQuery = `#graphql
query allPurchaseInvoices(
  $filter: String
  $top: Int
  $skip: Int
  $search: String
  $orderBy: String
  $desc: Boolean
) {
  purchaseInvoices(
    
    filter: $filter
    top: $top
    skip: $skip
    search: $search
    orderBy: $orderBy
    desc: $desc
  ) {
    items {
      amount
      assignedUserId
      buyFromContact
      buyFromCountryRegionCode
      buyFromPostCode
      buyFromVendorName
      buyFromVendorNo
      currencyCode
      documentDate
      documentType
      dueDate
      jobQueueStatus
      locationCode
      no
      orderAddressCode
      payToContact
      payToCountryRegionCode
      payToName
      payToPostCode
      payToVendorNo
      paymentDiscountPercent
      paymentMethodCode
      paymentTermsCode
      postingDate
      purchaserCode
      requestedReceiptDate
      shipToCode
      shipToContact
      shipToCountryRegionCode
      shipToName
      shipToPostCode
      shipmentMethodCode
      shortcutDimension1Code
      shortcutDimension2Code
      status
      vendorAuthorizationNo
      vendorInvoiceNo
      yourReference
    }
    totalCount
  }
}
`;

export { PurchaseInvoiceSchema, type PurchaseInvoice, PurchaseInvoiceQuery };
