//ET-DONE date totalcount
type ItemByBarcode = {
  items: [
    {
      barcode: string;
      baseUnitOfMeasure: string;
      blocked: boolean;
      description: string;
      genProdPostingGroup: string;
      inventory: number;
      inventoryPostingGroup: string;
      itemCategoryCode: string;
      itemNo: string;
      shelfNo: string;
      taxGroupCode: string;
      type: string;
      unitCost: number;
      unitOfMeasure: string;
      variantCode: string;
      vendorNo: string;
    }
  ];
};

const ItemByBarcodeSchema = `#graphql
  type ItemByBarcode {
    barcode: String
    baseUnitOfMeasure: String
    blocked: Boolean
    description: String
    genProdPostingGroup: String
    inventory: Float
    inventoryPostingGroup: String
    itemCategoryCode: String
    itemNo: String
    shelfNo: String
    taxGroupCode: String
    type: String
    unitCost: Float
    unitOfMeasure: String
    variantCode: String
    vendorNo: String
  }
`;

const ItemByBarcodeQuery = `#graphql
query allItemByBarcode(
  $no: String
) {
  itemByBarcode(
    no: $no
  ) {
    items {
        barcode
        baseUnitOfMeasure
        blocked
        description
        genProdPostingGroup
        inventory
        inventoryPostingGroup
        itemCategoryCode
        itemNo
        shelfNo
        taxGroupCode
        type
        unitCost
        unitOfMeasure
        variantCode
        vendorNo
    }
  }
}
`;

export { ItemByBarcodeSchema, ItemByBarcodeQuery, type ItemByBarcode };
